import type { IOption } from '~/utils/options'

type TargetContext = '_self' | '_parent' | '_blank' | '_top'
export function openWindow(url: string, opts?: { target?: TargetContext; [key: string]: any }) {
  const { target = '_blank', ...others } = opts || {}
  window.open(
    url,
    target,
    Object.entries(others)
      .reduce((preValue: string[], curValue) => {
        const [key, value] = curValue
        return [...preValue, `${key}=${value}`]
      }, [])
      .join(','),
  )
}

export function cloneDeep(obj: any) {
  if (!obj)
    return

  const newObj = Array.isArray(obj) ? [] : {} as any
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null)
      newObj[key] = cloneDeep(value)

    else
      newObj[key] = value
  }
  return newObj
}

/**
 * 日期范围格式化
 * @param val
 * @returns
 */
export function dateRangeFormat(val: string[], mode?: string) {
  if (!val)
    return val

  const start = val[0]
  let end = val[1]

  if (mode === 'year')
    end = end.replace(/-01-01/, '-12-31')

  end = end.replace(/00:00:00/, '23:59:59')
  return [start, end]
}

/**
 * 万元转化为元
 */
export function wanyuanToYuan(num: number) {
  if (num === '' as any || num === null as any || num === undefined as any)
    return ''
  return +num * 10000
}

/**
 * 元转化为万元
 */
export function yuanToWanyuan(num: number) {
  if (num === '' as any || num === null as any)
    return ''
  return +num / 10000
}

/** 数字输入框初始值的展示 */
export function numberInputFormatter(val: number | string) {
  if (val === '0')
    return 0
  if (val === '')
    return ''
  return +val ? +val : ''
}
/** 数字输入框的入参处理 */
export function numberInputSave(val: number | undefined) {
  if (val === undefined)
    return ''
  return val
}

/**
 * 文本拼接
 * @param connector 拼接符
 * @param text 文本
 */
export function textJoin(connector: string, ...text: string[]) {
  return text.filter(item => !!item).join(connector)
}
/**
 * 日期保留年月日
 * @param date 日期
 */
export function subDate(date: string) {
  const dayTime = date && date.length > 10 ? date.substring(0, 10) : ''
  return dayTime
}
/**
 * 进度转化成百分比
 * @param val
 * @returns
 */
export function percentAge(val1: any, val2: any, unit = true) {
  let data = 0
  if (Number(val1) && Number(val2))
    data = (Number(val1) / Number(val2)) * 100

  const percent = data ? data.toFixed(2) : 0
  let result = percent.toString()
  result = result.replace('.00', '')
  return result + (unit ? '%' : '')
}

/**
 * 将数组转化为 label value children 的格式
 * 如将 [{name:'中国',value:'China',child:[]}] 的数据转化为 [{label:'中国',value:'China',children:[]}]
 * @sourceFiled 原数组的各字段 key
 */
export function fieldTransfer(list: any[], sourceFiled?: {
  labelKey?: string
  valueKey?: string
  childrenKey?: string
}): IOption[] {
  return (list || []).map((item) => {
    const label = item[sourceFiled?.labelKey || 'label']
    const value = item[sourceFiled?.valueKey || 'value']
    const children = item[sourceFiled?.childrenKey || 'children'] || []
    const obj: IOption = { label, value }
    if (children?.length)
      obj.children = fieldTransfer(children, sourceFiled)
    return obj
  })
}

/** 将字符串转化为范围
 * 如将 '1,2' 转化为 {min:1,max:2}
 * '1,' 转化为 {min:1,max:undefined}
 * ',2' 转化为 {min:undefined,max:2}
 */
export function handleFilterRange(val: string) {
  const arr = val === '' ? ['', ''] : val.split(',')
  const min = arr[0] === '' ? undefined : +arr[0]
  const max = arr[1] === '' ? undefined : +arr[1]
  return { min, max }
}

/**
 * 复制到粘贴板
*/
export function doCopy(text: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).catch(() => {
      copyCompatible(text)
    })
  }
  else {
    copyCompatible(text)
  }
  AMessage.success('复制成功')
}

function copyCompatible(text: string) {
  const input = document.createElement('input')
  input.setAttribute('value', text)
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}

/**
 * 导出 excel
*/
export function exportExcel(data: Blob, fileName: string) {
  if ('download' in document.createElement('a')) {
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(data)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    document.body.removeChild(elink)
  }
}

/** 处理文件大小 */
export function getFileSize(fileSize: number) {
  const KB = fileSize / 1024
  const MB = KB / 1024
  const GB = MB / 1024
  const TB = GB / 1024
  if (TB > 1)
    return `${TB.toFixed(1)}T`
  else if (GB > 1)
    return `${GB.toFixed(1)}G`
  else if (MB > 1)
    return `${MB.toFixed(1)}M`
  else
    return `${KB.toFixed(1)}K`
}
export function guid() {
  let d = Date.now()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function')
    d += performance.now() // use high-precision timer if available

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
