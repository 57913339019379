<!-- 重复的企业商机 -->
<script setup lang="ts">
import dayjs from 'dayjs'
import { businessAddSignBusinessEnterprise, businessReceiveBusiness, businessVerifyRepeatBusiness } from '~/api/business'
import { customerEnterprisePageList } from '~/api/customer'
import { yuanToWanyuan } from '~/utils/index'

const props = defineProps({
  // 创建来源,必传  我的:myself   公海:open_sea
  createSource: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [] as any[],
  },
  /** 1点击“企业名称”下面的‘去查看’触发的本弹窗  2点击“信用代码”下面的‘去查看’触发的本弹窗  3点击保存按钮触发的本弹窗 */
  triggerType: {
    type: Number,
    default: 1,
  },
  /** 企业名称输入内容 */
  enterpriseNameSearchStr: {
    type: String,
    default: '',
  },
  /** 信用代码输入内容 */
  enterpriseCodeSearchStr: {
    type: String,
    default: '',
  },
  /** business 商机  customer 客户 */
  type: {
    type: String,
    default: 'business',
  },
  /** 是否显示操作列 */
  showActionColumn: {
    type: Boolean,
    default: false,
  },
  /** 当前是否是在签约 */
  isSign: {
    type: Boolean,
    default: false,
  },
  // 签约方式
  signType: {
    type: String,
    default: '',
  },
  signParamObj: {
    type: Object,
    default: () => {},
  },
  // 客户id
  customerId: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['success', 'pageJump', 'associationCustomer'])
const columns: any[] = [
  {
    title: '企业名称',
    dataIndex: 'enterpriseName',
    slotName: 'enterpriseName',
    fixed: 'left',
    ellipsis: true,
    tooltip: true,
    width: 300,
  },
  {
    title: '所在地址',
    dataIndex: 'addr',
    ellipsis: true,
    tooltip: true,
    width: 180,
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
    width: 180,
  },
  { title: '行业', dataIndex: 'industryType', width: 160 },
  { title: '企业类型', dataIndex: 'enterpriseType', width: 180 },
  { title: '分公司', dataIndex: 'isCompanyFiliale', width: 180, render: ({ record }) => record.isCompanyFiliale === 1 ? '是分公司' : (record.isCompanyFiliale === 0 ? '不是分公司' : '') },
  {
    title: '分公司数量',
    dataIndex: 'filialeCompanyCnt',
    width: 140,
    sortable: {
      sortDirections: ['ascend', 'descend'],
    },
  },
  { title: '分公司列表', dataIndex: 'filialeCompanyNameArray', width: 300, ellipsis: true, tooltip: true },
  { title: '企业经营阶段', dataIndex: 'companyOperationScopeMapList', width: 120 },
  { title: '企业资质', dataIndex: 'corpCertArray', width: 300, ellipsis: true, tooltip: true },
  { title: '人员证书', dataIndex: 'qualicaficationArray', width: 300, ellipsis: true, tooltip: true },
  { title: '资质缺口', dataIndex: 'lackQualicaficationList', width: 120 },
  {
    title: '企业资质数量',
    dataIndex: 'corpCertCnt',
    width: 140,
    sortable: {
      sortDirections: ['ascend', 'descend'],
    },
  },
  {
    title: '注册人员数量',
    dataIndex: 'regPersonCnt',
    width: 140,
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.regPersonCnt - +b?.regPersonCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '四库业绩数量',
    dataIndex: 'skAchievementCnt',
    width: 140,
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.skAchievementCnt - +b?.skAchievementCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  { title: '所获奖项', dataIndex: 'awardList', width: 140 },
  { title: '项目业绩规模', dataIndex: 'projectAchievementScaleList', width: 140 },
  {
    title: '企业状态',
    dataIndex: 'enterpriseStatus',
    slotName: 'enterpriseStatus',
    width: 140,
  },
  {
    title: '成立时间',
    dataIndex: 'establishmentTime',
    slotName: 'establishmentTime',
    width: 140,
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = new Date(a?.establishmentTime).getTime() - new Date(b?.establishmentTime).getTime()
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '注册资本（万元）',
    dataIndex: 'registeredCapital',
    slotName: 'registeredCapital',
    width: 150,
  },
  {
    title: '企业评分',
    dataIndex: 'score',
    width: 120,
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.score - +b?.score
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  // {
  //   title: '最近获取时间',
  //   dataIndex: 'created',
  //   sortable: {
  //     sortDirections: ['ascend', 'descend'],
  //   },
  // },
]
const listData = ref([]) as any
const enterpriseNameQuery = ref('')
const enterpriseCodeQuery = ref('')
const columnsData = ref([]) as any

onMounted(() => {
  listData.value = props.list
  if (props.triggerType === 1) {
    enterpriseNameQuery.value = props.enterpriseNameSearchStr
  }
  else if (props.triggerType === 2) {
    enterpriseCodeQuery.value = props.enterpriseCodeSearchStr
  }
  else {
    enterpriseNameQuery.value = props.enterpriseNameSearchStr
    enterpriseCodeQuery.value = props.enterpriseCodeSearchStr
  }
  // 如果不展示操作列
  if (!props.showActionColumn) {
    columnsData.value = columns
  }
  // 展示操作列
  else {
    columnsData.value = columns.concat({
      title: '操作',
      dataIndex: 'opration',
      slotName: 'opration',
      width: 140,
      fixed: 'right',
    })
  }
})

async function getSimilarBiz() {
  // 获取重复的商机列表
  if (props.type === 'business') {
    const { data } = await businessVerifyRepeatBusiness({
      businessType: 'ENTERPRISE',
      name: enterpriseNameQuery.value,
      code: enterpriseCodeQuery.value,
    })
    if (data.value)
      listData.value = data.value?.list || []
  }
  // 获取重复的客户列表
  else {
    const { data } = await customerEnterprisePageList({
      page: 1,
      size: 20,
      enterpriseName: enterpriseNameQuery.value,
      enterpriseCode: enterpriseCodeQuery.value,
    })
    if (data.value)
      listData.value = data.value?.records || [] // 匹配到的重复客户列表
  }
}

/** 领取并创建 */
async function claimAndCreate(record: any) {
  // 客户签约商机，走新增逻辑的时候的重复列表
  if (props.isSign) {
    const { data } = await businessAddSignBusinessEnterprise({
      ...record,
      step: 2, // 1需要验证 2直接新增
      createSource: props.createSource,
      businessReceiveSource: 'bigdata_search',
      businessType: 'ENTERPRISE',
      customerId: props.customerId,
      customerType: props.signParamObj.customerType,
      signType: props.signType,
    })
    if (data.value) {
      AMessage.success('领取并创建成功')
      getSimilarBiz()
      emit('success')
      emit('pageJump', { businessId: data.value.businessId, signId: data.value.signId })
    }
  }
  // 新增企业商机的重复列表
  else {
    const { data } = await businessReceiveBusiness({
      businessList: [record],
      businessReceiveSource: 'bigdata_search',
      businessType: 'ENTERPRISE',
    })
    if (data.value) {
      const { successCnt } = data.value
      // 领取并创建成功
      if (+successCnt === 1) {
        AMessage.success('领取并创建成功')
        getSimilarBiz()
        emit('success')
      }
      else {
        AMessage.error('领取并创建失败')
      }
    }
  }
}

/** 关联客户 */
function associationCustomer(record: any) {
  const customerId = record.id
  emit('associationCustomer', customerId)
}
</script>

<template>
  <div class="mb-24px flex">
    <div class="flex items-center">
      <div class="whitespace-nowrap">
        企业名称：
      </div>
      <AInput v-model="enterpriseNameQuery" placeholder="请输入" class="w-240px" allow-clear />
    </div>
    <div class="m-l-16px flex items-center">
      <div class="whitespace-nowrap">
        信用代码：
      </div>
      <AInput v-model="enterpriseCodeQuery" placeholder="请输入" class="w-240px" allow-clear />
    </div>
    <AButton class="ml-12px" type="primary" @click="getSimilarBiz">
      搜索
    </AButton>
  </div>
  <AAlert class="mb-16px border-none">
    <div class="color-#33363A">
      根据输入信息匹配到以下{{ props.type === 'customer' ? '客户' : '商机' }}，可直接领取；仅展示前20条匹配结果，需要更精准匹配请输入完整企业名称或信用代码
    </div>
  </AAlert>
  <ATable
    :columns="columnsData"
    :data="listData"
    :pagination="false"
    :scroll="{
      x: 2000,
    }"
  >
    <template #registeredCapital="{ record }">
      {{ yuanToWanyuan(record.registeredCapital) }}
    </template>
    <template #enterpriseStatus="{ record }">
      <TagEnterpriseStatus :status="record.enterpriseStatus" />
    </template>
    <template #opration="{ record }">
      <!-- 商机 -->
      <template v-if="props.type === 'business'">
        <AButton v-if="!+record.followerId" type="text" class="px-0 text-sm" @click="claimAndCreate(record)">
          领取并创建
        </AButton>
        <span v-else class="text-sm color-#adafb0">
          {{ `${record.followerName}已领取` }}
        </span>
      </template>
      <!-- 客户 -->
      <template v-else>
        <AButton type="text" class="px-0 text-sm" @click="associationCustomer(record)">
          关联客户
        </AButton>
      </template>
    </template>
    <template #establishmentTime="{ record }">
      {{ record.establishmentTime ? dayjs(record.establishmentTime).format('YYYY-MM-DD') : "" }}
    </template>
  </ATable>
</template>
