<!-- 新增/编辑人才商机弹窗，签约弹窗 -->
<script setup lang='ts'>
import RepeatProjectBiz from './RepeatProjectBiz.vue'
import type { IOption } from '~/utils/options'
import { businessAddBusinessProject, businessAddSignBusinessProject, businessEditBusinessProject, businessProjectInfo, businessVerifyRepeatBusiness } from '~/api/business'
import { signingSave } from '~/api/signing'
import type { PositionModal } from '~/components/gaodeMap/GaodeMap.vue'
import type { AddBusinessProjectReq } from '~/api/_interfaces'
import { wanyuanToYuan, yuanToWanyuan } from '~/utils/index'
import { validatePhone } from '~/utils/validate'
import { jumpToProject } from '~/utils/pageJump'
import { getIndustryOptions, getProjectTypeOptions, getTenderWayOptions } from '~/utils/options2'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  // true 编辑，false 新建
  isEdit: {
    type: Boolean,
    default: () => false,
  },
  // 页面来源   1:企业商机 2:人才商机 3:项目商机 4:企业客户 5:个人客户
  pageFrom: {
    type: Number,
    default: 0,
  },
  // 表单类型    business:新增/编辑项目商机  sign:签约或关联客户
  formType: {
    type: String,
    default: '',
  },
  // 创建来源（商机必传）  myself:我的   open_sea:公海  customer:客户
  createSource: {
    type: String,
    default: '',
  },
  // 签约方式（签约必传，关联客户不传）  JOIN_OFFLINE：线下签约，JOIN_ONLINE：线上签约， ONBOARDED:入职， ONBOARDED_OUT:劳务外包，APPROVAL:立项
  signType: {
    type: String,
    default: '',
  },
  // 商机id (编辑商机必传)
  businessId: {
    type: String,
    default: '',
  },
  // 客户id (客户签约商机必传)
  customerId: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:visible', 'success'])

const existedListRef = ref(null) as any

const _visible = ref(false)
const tabType = ref(1) as any// tab类型 1已有  2新增
const selectedExistId = ref('') // 选中的已经存在的数据id
const showProjectExtraLine = ref(false)
const showCodeExtraLine = ref(false)
const aMapModalVisible = ref(false) // 地图选点弹窗

const locationType = ref(0) // 1 招标单位地图选址 2 代理机构地图选址
const tenderingLocation = ref({} as PositionModal) // 招标单位位置信息
const agencyLocation = ref({} as PositionModal) // 代理机构位置信息
const onSaving = ref(false)

let repeatBizList_project = [] as any[]// 重复商机列表(通过项目名称查出来的)
let repeatBizList_code = [] as any[]// 重复商机列表(通过项目编号查出来的)

// 重复商机列表弹窗
const repeatProjectBizModal = ref({
  visible: false,
  showConfirmBtn: false, // 是否展示底部的‘直接创建按钮’
  list: [] as any[],
  triggerType: 0, // 1点击“项目名称”下面的‘去查看’触发弹窗  2点击“项目编号”下面的‘去查看’触发弹窗  3点击保存按钮触发弹窗
})

const formRef = ref()
const formData = ref({
  projectName: '', // 项目名称
  projectNo: '', // 项目编号
  projectType: '', // 项目阶段
  indicatorType: '', // 招标方式
  budget: '' as any, // 项目预算
  projectContactWay: '', // 项目联系方式
  tendering: '', // 招标单位
  tenderingContactWay: '', // 招标单位联系方式
  tenderingAddress: '', // 招标单位地址
  agency: '', // 代理机构
  agencyContactWay: '', // 代理机构联系方式
  agencyAddress: '', // 代理机构单位地址
  industry: '', // 行业类型
  fileDownloadTime: [], // 文件下载时间
  bidTime: [], // 投标时间
  biddingOpeningTime: '', // 开标时间
})

/** 是否来源于商机页面 */
const isFromBusinessPage = computed(() => {
  const pageFrom = +props.pageFrom
  return pageFrom === 1 || pageFrom === 2 || pageFrom === 3
})
/** 是否来源于客户页面 */
const isFromCustomerPage = computed(() => {
  const pageFrom = +props.pageFrom
  return pageFrom === 4 || pageFrom === 5
})
/** 当前是否是企业商机表单 */
const isBusinessForm = computed(() => {
  const formType = props.formType
  return formType === 'business' || (isFromCustomerPage.value && formType === 'sign' && +tabType.value === 2)
})
/** 当前是否是企业客户表单 */
// const isCustomerForm = computed(() => {
//   // 新增/编辑企业客户商机 或 商机签约客户的时候新增客户
//   return props.formType === 'customer' || (isFromBusinessPage.value && props.formType === 'sign' && +tabType.value === 2)
// })
const title = computed(() => {
  let str = ''
  const editText = props.isEdit ? '编辑' : '新增'
  if (props.formType === 'business')
    str = `${editText}项目商机`
  else
    str = '签约'
  return str
})

// 是否是签约
const isSign = computed(() => {
  return props.formType === 'sign'
})

const signParamObj = computed(() => {
  const pageFrom = +props.pageFrom
  // 企业客户签约项目商机（商机类型是 PROJECT，客户类型取决于页面来源)
  if (pageFrom === 4 || pageFrom === 5) {
    return {
      businessType: 'PROJECT',
      customerType: pageFrom === 4 ? 'ENTERPRISE' : 'PERSON',
    }
  }
})

const tenderWayOptions = ref<IOption[]>([])
const industryOptions = ref<IOption[]>([])
const projectTypeOptions = ref<IOption[]>([])
onMounted(async () => {
  const formType = props.formType
  if (formType === 'business' && props.isEdit)
    getBusinessProjectInfo()

  tenderWayOptions.value = await getTenderWayOptions()
  industryOptions.value = await getIndustryOptions()
  projectTypeOptions.value = await getProjectTypeOptions()
})
/** 获取项目商机详情 */
async function getBusinessProjectInfo() {
  const { data } = await businessProjectInfo({ id: props.businessId })
  if (data.value) {
    const d = data.value as AddBusinessProjectReq
    formData.value = {
      projectName: d.projectName, // 项目名称
      projectNo: d.projectNum, // 项目编号
      projectType: d.projectType, // 项目阶段
      indicatorType: d.biddingType, // 招标方式
      budget: yuanToWanyuan(d.projectBudget), // 项目预算
      projectContactWay: d.phone, // 项目联系方式
      tendering: d.biddingCorpName, // 招标单位
      tenderingContactWay: d.biddingCorpPhone, //  招标单位电话
      tenderingAddress: d.biddingCorpAddr, // 招标单位地址
      agency: d.agencies, // 代理机构
      agencyContactWay: d.agenciesPhone, // 代理机构联系方式
      agencyAddress: d.agenciesAddr, // 代理机构单位地址
      industry: d.industryType, // 行业类型
      fileDownloadTime: [d.fileDownloadStartTime, d.fileDownloadEndTime] as any, // 文件下载时间
      bidTime: [d.biddingStartTime, d.biddingEndTime] as any, // 投标时间
      biddingOpeningTime: d.biddingOpeningTime, // 开标时间
    }
    // const biddingCorpAddrCoordinate = d.biddingCorpAddrCoordinate || [] // 招标单位坐标
    // const agenciesAddrCoordinate = d.agenciesAddrCoordinate || [] // 代理机构坐标
    tenderingLocation.value = {
      lng: d.biddingCorpAddrLng || '',
      lat: d.biddingCorpAddrLat || '',
      address: d.biddingCorpAddr,
      country: d.biddingCorpCountry,
      province: d.biddingCorpProvince,
      city: d.biddingCorpCity,
      area: d.biddingCorpArea,
    }
    agencyLocation.value = {
      lng: d.agenciesAddrLng || '',
      lat: d.agenciesAddrLat || '',
      address: d.biddingCorpAddr,
      country: d.biddingCorpCountry,
      province: d.biddingCorpProvince,
      city: d.biddingCorpCity,
      area: d.biddingCorpArea,
    }
  }
}
watch(tabType, () => {
  selectedExistId.value = ''
})

watchEffect(() => {
  _visible.value = props.visible
})

function mapOk(position: PositionModal) {
  if (locationType.value === 1) {
    tenderingLocation.value = position
    formData.value.tenderingAddress = position.address as string
  }

  else {
    agencyLocation.value = position
    formData.value.agencyAddress = position.address as string
  }
}

/** 1 招标单位地图选址  2 代理机构地图选址 */
function showMapModal(type: number) {
  locationType.value = type
  aMapModalVisible.value = true
}

/** 获取匹配商机 */
async function getSimilarBiz(type: number) {
  const not1 = type === 1 && !formData.value.projectName
  const not2 = type === 2 && !formData.value.projectNo
  if (not1 || not2)
    return

  const { data } = await businessVerifyRepeatBusiness({
    businessType: 'PROJECT',
    name: type === 1 ? formData.value.projectName : '',
    code: type === 2 ? formData.value.projectNo : '',
  })
  if (data.value) {
    const list = data.value?.list || [] // 匹配到的商机列表
    if (type === 1) {
      repeatBizList_project = list
      showProjectExtraLine.value = !!list.length
    }
    else {
      repeatBizList_code = list
      showCodeExtraLine.value = !!list.length
    }
  }
}

/** 展示匹配商机弹窗 */
function checkSimilarBiz(type: number) {
  repeatProjectBizModal.value.visible = true
  repeatProjectBizModal.value.triggerType = type
  // 点击项目名称下的去查看
  if (type === 1) {
    // repeatProjectBizModal.value.triggerType = 1
    repeatProjectBizModal.value.list = repeatBizList_project
  }

  // 点击项目编号下的去查看
  else {
    // repeatProjectBizModal.value.triggerType = 2
    repeatProjectBizModal.value.list = repeatBizList_code
  }
}

/**
 * @param needRepeatValidate 是否需要重复校验
 */
function handleOk(needRepeatValidate = true) {
  onSaving.value = true
  try {
    // 选择已有商机进行签约
    if (isSign.value && +tabType.value === 1)
      signFn()
    // 企业商机
    if (isBusinessForm.value)
      businessFormSave(needRepeatValidate)
  }
  catch (error) {
    onSaving.value = false
  }
}
/** 签约 */
async function signFn() {
  const _tabType = +tabType.value
  if (_tabType === 1 && !selectedExistId.value) {
    AMessage.error('请选择')
    return
  }
  let businessId = ''
  let customerId = ''
  // 客户签约项目商机  则商机 id 使用用户自己选的值, 客户 id 使用 props 值
  if (isFromCustomerPage.value) {
    businessId = selectedExistId.value
    customerId = props.customerId
  }
  const { data } = await signingSave({
    businessId,
    businessType: signParamObj.value?.businessType, // 商机类型
    customerType: signParamObj.value?.customerType, // 客户类型
    customerId,
    signType: props.signType, // 签约类型
  })
  onSaving.value = false
  if (data.value) {
    AMessage.success('操作成功')
    emit('success')
    handleCancel()
    pageJump({ businessId: data.value.businessId, signId: data.value.signId })
  }
}

/** 三方跳转 */
function pageJump({ businessId = '', signId = '' }) {
  // 不存在签约方式，说明是关联客户，不需要跳转
  if (!props.signType)
    return
  if (isSign.value)
    jumpToProject({ businessId, signId })
}

/**
 * 表单数据保存
 * @param needRepeatValidate 是否需要重复校验
 */
function businessFormSave(needRepeatValidate: boolean) {
  formRef.value.validate().then(async (err: any) => {
    if (err)
      onSaving.value = false
    if (!err) {
      let res = null as any
      const param = handleSaveData()
      // 项目商机创建并领取并签约
      if (isSign.value) {
        res = await businessAddSignBusinessProject({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
          createSource: props.createSource,
          businessType: signParamObj.value?.businessType, // 商机类型
          signType: props.signType, //  签约类型
          customerType: signParamObj.value?.customerType, // 客户类型
          customerId: props.customerId,
        })
      }
      else if (props.isEdit) {
        res = await businessEditBusinessProject({
          ...param,
          id: props.businessId,
          createSource: props.createSource,
        })
      }
      else {
        res = await businessAddBusinessProject({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
          createSource: props.createSource,
        })
      }
      onSaving.value = false
      if (res?.data?.value) {
        const list = res?.data?.value?.list || []
        if (props.isEdit || !list.length || !needRepeatValidate) {
          AMessage.success(props.isEdit ? '保存成功' : '创建成功')
          emit('success')
          handleCancel()
          if (isSign.value)
            pageJump({ businessId: res?.data.value.businessId, signId: res?.data.value.signId })
        }
        else {
          repeatProjectBizModal.value = {
            visible: true,
            triggerType: 3,
            list,
            showConfirmBtn: true,
          }
        }
      }
    }
  })
}

function handleSaveData() {
  // const hasTenderingLocation = !!tenderingLocation.value.lng && !!tenderingLocation.value.lat
  // const hasAgencyLocation = !!agencyLocation.value.lng && !!agencyLocation.value.lat
  const fv = formData.value
  const param = {
    agencies: fv.agency, // 代理机构

    agenciesAddr: agencyLocation.value.address || '', // 代理机构地址
    agenciesCountry: agencyLocation.value.country || '', // 国家
    agenciesProvince: agencyLocation.value.province || '', // 省份
    agenciesCity: agencyLocation.value.city || '', // 市
    agenciesArea: agencyLocation.value.area || '', // 区县
    agenciesPhone: fv.agencyContactWay, // 代理机构电话
    biddingCorpAddr: tenderingLocation.value.address || '', // 招标单位地址
    biddingCorpCountry: tenderingLocation.value.country || '', // 国家
    biddingCorpProvince: tenderingLocation.value.province || '', // 省份
    biddingCorpCity: tenderingLocation.value.city || '', // 市
    biddingCorpArea: tenderingLocation.value.area || '', // 区县
    biddingCorpName: fv.tendering, // 招标单位名称
    biddingCorpPhone: fv.tenderingContactWay, // 招标单位电话
    biddingType: fv.indicatorType, // 招标方式
    businessType: 'PROJECT', // 商机类型 PROJECT PERSON PROJECT
    // biddingCorpAddrCoordinate: hasTenderingLocation ? `${tenderingLocation.value.lng},${tenderingLocation.value.lat}` : '', // 经度，纬度
    biddingCorpAddrLat: tenderingLocation.value.lat || '',
    biddingCorpAddrLng: tenderingLocation.value.lng || '',
    // agenciesAddrCoordinate: hasAgencyLocation ? `${agencyLocation.value.lng},${agencyLocation.value.lat}` : '', // 经度，纬度
    agenciesAddrLat: agencyLocation.value.lat || '',
    agenciesAddrLng: agencyLocation.value.lng || '',
    // createId: 0,
    // createName: '',
    // created: '',
    fileDownloadStartTime: fv.fileDownloadTime && fv.fileDownloadTime[0] ? fv.fileDownloadTime[0] : '', // 文件下载结束时间
    fileDownloadEndTime: fv.fileDownloadTime && fv.fileDownloadTime[1] ? fv.fileDownloadTime[1] : '', // 文件下载开始时间
    biddingStartTime: fv.bidTime && fv.bidTime[0] ? fv.bidTime[0] : '', // 投标开始时间
    biddingEndTime: fv.bidTime && fv.bidTime[0] ? fv.bidTime[0] : '', // 投标结束时间
    // id: 0,
    industryType: fv.industry, // 行业类型
    // isDelete: 0,
    // licenseId: 0, // 工商信息id
    // modified: '',
    phone: fv.projectContactWay, // 项目联系方式
    projectBudget: wanyuanToYuan(fv.budget), // 招标预算
    projectName: fv.projectName, // 项目名称
    projectNum: fv.projectNo, // 项目编码
    biddingOpeningTime: fv.biddingOpeningTime || '', // 开标时间
    projectType: fv.projectType, // 项目阶段
    // step: 0, // 步骤 1需要验证 2直接新增
    // thirdId: 0, // 三方id
    // updateId: 0,
    // updateName: '',
  }
  return param
}
/** 创建并领取成功，两个弹窗全部关闭 */
function receiveSuccess() {
  repeatProjectBizModal.value.visible = false
  emit('success')
  handleCancel()
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    :visible="_visible"
    :mask-closable="false"
    title-align="start"
    width="735px"
    :title="title"
    :ok-loading="onSaving"
    @ok="handleOk(!props.isEdit || isSign)"
    @cancel="handleCancel"
  >
    <div>
      <!-- PROJECT
      <div>pageFrom: {{ props.pageFrom }}</div>
      <div>formType: {{ props.formType }}</div>
      <div>signType: {{ props.signType }}</div> -->
      <!-- 签约才需要展示内容切换 -->
      <ARadioGroup v-if="isSign" v-model="tabType" type="button" class="mb-16px" @change="val => tabType = val">
        <ARadio :value="1">
          {{ isFromBusinessPage ? "已有客户" : "已有商机" }}
        </ARadio>
        <ARadio :value="2">
          {{ isFromBusinessPage ? "新增客户" : "新增商机" }}
        </ARadio>
      </ARadioGroup>
      <!-- 选择已有客户 -->
      <template v-if="isSign && tabType === 1">
        <ExistedList
          ref="existedListRef"
          type="business"
          business-type="PROJECT"
          @change="val => selectedExistId = val"
        />
      </template>

      <AForm v-if="(isSign && +tabType === 2) || !isSign" ref="formRef" :model="formData" auto-label-width layout="vertical">
        <ARow :gutter="24">
          <ACol :span="12">
            <AFormItem field="projectName" label="项目名称" :rules="[{ required: true, message: '请输入项目名称' }]">
              <AInput
                v-model="formData.projectName"
                placeholder="请输入"
                :max-length="100"
                @input="showProjectExtraLine = false"
                @blur="getSimilarBiz(1)"
                @change="formData.projectName = formData.projectName.trim()"
              />
              <template v-if="showProjectExtraLine && !props.isEdit && formData.projectName" #extra>
                <div>
                  已匹配到商机，请勿重复新增，<AButton type="text" class="px-0px" @click="checkSimilarBiz(1)">
                    去查看
                  </AButton>
                </div>
              </template>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="projectNo" label="项目编号">
              <AInput
                v-model="formData.projectNo"
                placeholder="请输入"
                :max-length="100"
                @input="showCodeExtraLine = false"
                @change="formData.projectNo = formData.projectNo.trim()"
                @blur="getSimilarBiz(2)"
              />
              <template v-if="showCodeExtraLine && !props.isEdit && formData.projectNo" #extra>
                <div>
                  已匹配到商机，请勿重复新增，<AButton type="text" class="px-0px" @click="checkSimilarBiz(2)">
                    去查看
                  </AButton>
                </div>
              </template>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="budget" label="项目预算">
              <AInputNumber v-model="formData.budget" :min="0" :max="99999999" placeholder="请输入" :precision="4" hide-button>
                <template #append>
                  万元
                </template>
              </AInputNumber>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="indicatorType" label="招标方式">
              <ASelect v-model="formData.indicatorType" placeholder="请选择" allow-clear>
                <AOption v-for="item in tenderWayOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </AOption>
              </ASelect>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="industry" label="行业类型">
              <ASelect v-model="formData.industry" placeholder="请选择" allow-clear>
                <AOption v-for="item in industryOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </AOption>
              </ASelect>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="industry" label="项目阶段">
              <ASelect v-model="formData.projectType" placeholder="请选择" allow-clear>
                <AOption v-for="item in projectTypeOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </AOption>
              </ASelect>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="projectContactWay" label="项目联系方式" :rules="[{ validator: validatePhone }]">
              <AInput v-model="formData.projectContactWay" placeholder="请输入" @change="formData.projectContactWay = formData.projectContactWay.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="tendering" label="招标单位">
              <AInput v-model="formData.tendering" placeholder="请输入" :max-length="100" @change="formData.tendering = formData.tendering.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="tenderingContactWay" label="招标单位联系方式" :rules="[{ validator: validatePhone }]">
              <AInput v-model="formData.tenderingContactWay" placeholder="请输入" @change="formData.tenderingContactWay = formData.tenderingContactWay.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="tenderingAddress" label="招标单位地址">
              <AInput v-model.trim="formData.tenderingAddress" placeholder="请地图上选择地点" allow-clear @click="showMapModal(1)" @clear.stop="formData.tenderingAddress = '';tenderingLocation = {} as any" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="agency" label="代理机构">
              <AInput v-model="formData.agency" placeholder="请输入" :max-length="100" @change="formData.agency = formData.agency.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="agencyContactWay" label="代理机构联系方式" :rules="[{ validator: validatePhone }]">
              <AInput v-model="formData.agencyContactWay" placeholder="请输入" @change="formData.agencyContactWay = formData.agencyContactWay.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="agencyAddress" label="代理机构单位地址">
              <AInput v-model.trim="formData.agencyAddress" placeholder="请地图上选择地点" allow-clear @click="showMapModal(2)" @clear.stop="formData.agencyAddress = '';agencyLocation = {} as any" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="fileDownloadTime" label="文件下载时间">
              <ARangePicker
                v-model="formData.fileDownloadTime"
                show-time
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm:00"
              />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="bidTime" label="投标时间">
              <ARangePicker
                v-model="formData.bidTime"
                show-time
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm:00"
              />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="biddingOpeningTime" label="开标时间">
              <ADatePicker
                v-model="formData.biddingOpeningTime"
                class="w-full"
                show-time
                format="YYYY-MM-DD HH:mm"
                value-format="YYYY-MM-DD HH:mm:00"
              />
            </AFormItem>
          </ACol>
        </ARow>
      </AForm>
    </div>
  </AModal>
  <!-- 重复商机弹窗 -->
  <AModal
    v-if="repeatProjectBizModal.visible"
    v-model:visible="repeatProjectBizModal.visible"
    :mask-closable="false"
    title="选择商机"
    title-align="start"
    fullscreen
    :footer="repeatProjectBizModal.triggerType === 3"
  >
    <RepeatProjectBiz
      :create-source="props.createSource"
      :trigger-type="repeatProjectBizModal.triggerType"
      :list="repeatProjectBizModal.list"
      :project-name-search-str="formData.projectName"
      :project-code-search-str="formData.projectNo"
      :is-sign="isSign"
      :sign-param-obj="signParamObj"
      :sign-type="props.signType"
      :customer-id="props.customerId"
      @success="receiveSuccess"
      @page-jump="pageJump"
    />
    <template #footer>
      <div>
        <AButton @click="repeatProjectBizModal.visible = false">
          取消
        </AButton>
        <AButton type="primary" class="ml-12px" @click="handleOk(false)">
          直接创建
        </AButton>
      </div>
    </template>
  </AModal>
  <!-- 地图选点组件 -->
  <GaodeMapModal
    v-if="aMapModalVisible"
    v-model:visible="aMapModalVisible"
    :default-position="locationType === 1 ? tenderingLocation : agencyLocation"
    @ok="mapOk"
  />
</template>
