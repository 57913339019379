<!-- 行业类型 -->
<script setup lang="ts">
import type { IOption } from '~/utils/options'
import { getEnterpriseIndustryOptions } from '~/utils/options2'

const props = defineProps({
  modelValue: {
    type: Array<String>,
    default: () => [],
  },
  allowClear: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: '请选择',
  },
  // 是否多选
  multiple: {
    type: Boolean,
    default: false,
  },
  // 是否开启严格选择模式
  checkStrictly: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue'])

const industryType = ref([] as String[])
const industryTypeOptions = ref<IOption[]>([]) // 行业类型

onMounted(async () => {
  industryTypeOptions.value = await getEnterpriseIndustryOptions()
})

// 设置默认值
watch(() => props.modelValue, () => {
  if (props.modelValue)
    industryType.value = props.modelValue
}, { immediate: true, deep: true })

function handleChange(val: any) {
  emit('update:modelValue', val)
}
</script>

<template>
  <ACascader
    v-model="industryType"
    :allow-clear="props.allowClear"
    :options="industryTypeOptions"
    :placeholder="placeholder"
    path-mode
    :trigger-props="{ updateAtScroll: true }"
    :multiple="multiple"
    :check-strictly="checkStrictly"
    @change="handleChange"
  />
</template>
