<!-- 新增/编辑人才商机弹窗， 新增/编辑个人客户弹窗 ，签约弹窗 -->
<script setup lang='ts'>
import RepeatTalentBiz from './RepeatTalentBiz.vue'
import { customerLabelOptions, educationOptions } from '~/utils/options'
import { businessAddBusinessPerson, businessAddSignBusinessPerson, businessEditBusinessPerson, businessPersonInfo, businessVerifyRepeatBusiness } from '~/api/business'
import type { PositionModal } from '~/components/gaodeMap/GaodeMap.vue'
import type { IOption } from '~/utils/options'
import { signingSave } from '~/api/signing'
import { customerPersonAdd, customerPersonDetail, customerPersonPageList, customerPersonUpdate } from '~/api/customer'
import { configGetPersonCertificateLabel, configGetPersonTitleLabel } from '~/api/config'
import type { BusinessPersonInfoResp, CustomerPersonDetailResp } from '~/api/_interfaces'
import { numberInputFormatter, numberInputSave } from '~/utils/index'
import { phoneLandlineReg } from '~/utils/reg'
import { jumpToOA } from '~/utils/pageJump'
import { getLevelOptions, getPersonCertlabel } from '~/utils/options2'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  // true 编辑，false 新建
  isEdit: {
    type: Boolean,
    default: false,
  },
  // 页面来源  1:企业商机 2:人才商机 3:项目商机 4:企业客户 5:个人客户
  pageFrom: {
    type: Number,
    default: 0,
  },
  //  表单类型   'business' 新增/编辑人才商机  'customer' 新增/编辑个人客户  sign:签约或关联客户
  formType: {
    type: String,
    default: '',
  },
  // 创建来源（商机必传）  myself:我的   open_sea:公海  customer:客户
  createSource: {
    type: String,
    default: '',
  },
  // 签约方式（签约必传，关联客户不传）  JOIN_OFFLINE：线下签约，JOIN_ONLINE：线上签约， ONBOARDED:入职， ONBOARDED_OUT:劳务外包，APPROVAL:立项
  signType: {
    type: String,
    default: '',
  },
  // 商机id (编辑商机必传，商机签约个人客户必传)
  businessId: {
    type: String,
    default: '',
  },
  // 客户id (编辑客户必传，客户签约商机必传)
  customerId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:visible', 'success'])

const existedListRef = ref(null) as any

const _visible = ref(false)
const tabType = ref(1) as any// tab类型 1已有  2新增
const selectedExistId = ref('') // 选中的已经存在的数据id
const showNameExtraLine = ref(false)
const showCodeExtraLine = ref(false)
const aMapModalVisible = ref(false) // 地图选点弹窗
const location = ref({} as PositionModal)
const onSaving = ref(false)

// 企业客户标签列表
const labelArr = ref([])

let dataId = '' // 签约走新增的时候，表单新增成功的数据id
let repeatBizList_name = [] as any[]// 重复商机列表(通过企业名称查出来的)
let repeatBizList_telephone = [] as any[]// 重复商机列表(通过信用代码查出来的)

// 重复商机列表弹窗
const repeatTalentBizModal = ref({
  visible: false,
  showConfirmBtn: false, // 是否展示底部的‘直接创建按钮’
  list: [] as any[],
  triggerType: 0, // 1点击“企业名称”下面的‘去查看’触发弹窗  2点击“信用代码”下面的‘去查看’触发弹窗  3点击保存按钮触发弹窗
  showActionColumn: true, // 是否展示操作列
})

const qualificationCertificateOptions = ref([] as IOption[]) // 执业资格证选项
const technicalTitleOptions = ref([] as IOption[]) // 职称选项
const formRef = ref()
const formData = ref({
  name: '', // 姓名
  telephone: '', // 手机号
  gender: '', // 性别
  education: '', // 学历
  address: '', // 联系地址
  technicalTitle: '1', // 职称
  qualificationCertificate: '1', // 执业资格证
  achievementCnt: '' as any, // 个人业绩
  labelList: [], // 客户标签
})
interface ITitle {
  level?: string
  major?: string
  name?: string
  id?: string
}
const titleList = ref([{
  level: '',
  major: '',
}] as ITitle[])

const certificateList = ref([{
  name: '',
  id: '',
}] as ITitle[])

/** 是否来源于商机页面 */
const isFromBusinessPage = computed(() => {
  const pageFrom = +props.pageFrom
  return pageFrom === 1 || pageFrom === 2 || pageFrom === 3
})
/** 是否来源于客户页面 */
const isFromCustomerPage = computed(() => {
  const pageFrom = +props.pageFrom
  return pageFrom === 4 || pageFrom === 5
})
/** 当前是否是人才商机表单 */
const isBusinessForm = computed(() => {
  const formType = props.formType
  return formType === 'business' || (isFromCustomerPage.value && formType === 'sign' && +tabType.value === 2)
})
/** 当前是否是个人客户表单 */
const isCustomerForm = computed(() => {
  // 新增/编辑客户商机 或 商机签约客户的时候新增客户
  return props.formType === 'customer' || (isFromBusinessPage.value && props.formType === 'sign' && +tabType.value === 2)
})

const title = computed(() => {
  let str = ''
  const editText = props.isEdit ? '编辑' : '新增'
  if (props.formType === 'business') {
    str = `${editText}人才商机`
  }
  else if (props.formType === 'customer') {
    str = `${editText}个人客户`
  }
  else if (props.formType === 'sign') {
    if (isFromBusinessPage.value)
      return '关联客户'

    else if (isFromCustomerPage.value)
      return '签约'
  }
  return str
})
// 是否是签约
const isSign = computed(() => {
  return props.formType === 'sign'
})

const signParamObj = computed(() => {
  const pageFrom = +props.pageFrom
  const _tabType = +tabType.value
  // 人才商机签约客户（商机类型是 PERSON，客户类型是选框中选的值 或 PERSON(新增的情况))
  if (isFromBusinessPage.value) {
    return {
      businessType: 'PERSON',
      customerType: _tabType === 1 ? existedListRef?.value?.customerType : 'PERSON',
    }
  }
  // 客户签约人才商机（商机类型是 PERSON，客户类型取决于页面来源)
  else if (pageFrom === 4 || pageFrom === 5) {
    return {
      businessType: 'PERSON',
      customerType: pageFrom === 4 ? 'ENTERPRISE' : 'PERSON',
    }
  }
})

/** 获取执级下拉选项 */
async function getPersonTitleLabel() {
  const { data } = await configGetPersonTitleLabel()
  technicalTitleOptions.value = data?.value || []
}
/** 获取执业资格证下拉选项 */
async function getPersonCertificateLabel() {
  // const { data } = await getPersonCertlabel()
  // qualificationCertificateOptions.value = data?.value || []
  const pageFrom = +props.pageFrom
  if (pageFrom === 2) {
    qualificationCertificateOptions.value = await getPersonCertlabel()
  }
  else {
    const { data } = await configGetPersonCertificateLabel()
    qualificationCertificateOptions.value = data?.value || []
  }
}
function changeTitle() {
  titleList.value = [{
    level: '',
    major: '',
  }]
}
function addTitle() {
  const params = {
    level: '',
    major: '',
  }
  titleList.value.push(params)
}
function delTitle(idx: number) {
  titleList.value.splice(idx, 1)
}
function addCertificate() {
  const params = {
    name: '',
  }
  certificateList.value.push(params)
}
function delCertificate(idx: number) {
  certificateList.value.splice(idx, 1)
}
function changeCertificate() {
  certificateList.value = [{
    name: '',
  }]
}
function validatePhone(value: string, callback: Function) {
  // 新增编辑个人客户 或 签约时新增客户 手机号必填
  if (!value) {
    if (isCustomerForm.value)
      callback('请输入手机号')

    else
      callback()
  }
  else {
    if (!phoneLandlineReg.test(value))
      callback('请按正确的电话格式输入')
    else
      callback()
  }
}
onMounted(async () => {
  getOption()
  const formType = props.formType
  // 获取人才商机详情
  if (formType === 'business' && props.isEdit)
    getBusinessPersonInfo()
  // 获取个人客户详情
  else if (formType === 'customer' && props.isEdit)
    getPersonalCustomerDetail()
})

watch(tabType, () => {
  selectedExistId.value = ''
  getOption()
})

const levelOptions = ref<IOption[]>([])
/** 获取下拉选项 */
async function getOption() {
  const formType = props.formType
  if (formType === 'business' || formType === 'customer' || +tabType.value === 2) {
    !technicalTitleOptions.value.length && getPersonTitleLabel()
    !qualificationCertificateOptions.value.length && getPersonCertificateLabel()
  }
  levelOptions.value = await getLevelOptions()
}
/** 将后端返回的职称数据转换成级联组件需要的格式 */
function getTitleListData(titleList: any[]) {
  return (titleList || []).map((item) => {
    return {
      level: item.level,
      major: item.major.length ? item.major.split(',') : '',
      id: item.id,
    }
  })
}
/** 将后端返回的执业资格证数据转换成级联组件需要的格式 */
function getQualificationCertificateData(certificateList: any[]) {
  return (certificateList || []).map((item: ITitle) => {
    return { name: [item.name, item.major], id: item.id }
  }) as any
}

/** 获取人才商机详情 */
async function getBusinessPersonInfo() {
  const { data } = await businessPersonInfo({ id: props.businessId })
  if (data.value) {
    const d = data.value as BusinessPersonInfoResp
    formData.value = {
      name: d.personName, // 姓名
      telephone: d.phone, // 手机号
      gender: d.gender, //
      education: d.education, // 学历
      address: d.addr, // 联系地址
      technicalTitle: d.titleList?.length ? '1' : '',
      qualificationCertificate: d.certificateList?.length ? '1' : '',
      achievementCnt: numberInputFormatter(d.achievementCnt),
    } as any
    titleList.value = getTitleListData(d.titleList)
    if (formData.value.qualificationCertificate === '1' && d.certificateList?.length)
      certificateList.value = getQualificationCertificateData(d.certificateList)

    // const coordinate = d.coordinate ? d.coordinate.split(',') : []
    location.value = {
      lng: d.lng || '',
      lat: d.lat || '',
      address: d.addr,
      country: d.country,
      province: d.province,
      city: d.city,
      area: d.area,
    }
  }
}

// 获取个人客户详情
async function getPersonalCustomerDetail() {
  const { data } = await customerPersonDetail({ id: props.customerId })
  if (data.value) {
    const d = data.value as CustomerPersonDetailResp
    formData.value = {
      name: d.personName, // 姓名
      telephone: d.phone, // 手机号
      gender: d.gender, //
      education: d.education, // 学历
      address: d.addr, // 联系地址
      technicalTitle: d.titleList?.length ? '1' : '',
      qualificationCertificate: d.certificateList?.length ? '1' : '',
      achievementCnt: numberInputFormatter(d.achievementCnt),
      labelList: (d.labels || []).map(item => item.labelName) as any,
    }
    labelArr.value = d.labels ? d.labels : [] as any
    titleList.value = getTitleListData(d.titleList)
    if (formData.value.qualificationCertificate === '1' && d.certificateList?.length)
      certificateList.value = getQualificationCertificateData(d.certificateList)

    location.value = {
      lng: d.lng,
      lat: d.lat,
      address: d.addr,
      country: d.country,
      province: d.province,
      city: d.city,
      area: d.area,
      location: d.location,
    }
  }
}
watchEffect(() => {
  _visible.value = props.visible
})

function mapOk(position: PositionModal) {
  location.value = position
  formData.value.address = position.address as string
}

/** 获取匹配商机 */
async function getSimilarBiz(type: number) {
  const not1 = type === 1 && !formData.value.name
  const not2 = type === 2 && !formData.value.telephone
  if (not1 || not2)
    return
  if (type === 2 && !phoneLandlineReg.test(formData.value.telephone))
    return

  const { data } = await businessVerifyRepeatBusiness({
    businessType: 'PERSON',
    name: type === 1 ? formData.value.name : '',
    code: type === 2 ? formData.value.telephone : '',
  })
  if (data.value) {
    const list = data.value?.list || [] // 匹配到的商机列表
    if (type === 1) {
      repeatBizList_name = list
      showNameExtraLine.value = !!list.length
    }
    else {
      repeatBizList_telephone = list
      showCodeExtraLine.value = !!list.length
    }
  }
}

/** 获取重复的客户 */
async function getSimilarCustomer(type: number) {
  const not1 = type === 1 && !formData.value.name
  const not2 = type === 2 && !formData.value.telephone
  if (not1 || not2)
    return

  const { data } = await customerPersonPageList({
    page: 1,
    size: 20,
    personName: type === 1 ? formData.value.name : '',
    phone: type === 2 ? formData.value.telephone : '',
  })
  if (data.value) {
    const list = data.value?.records || [] // 匹配到的重复客户列表

    if (type === 1) {
      repeatBizList_name = list
      showNameExtraLine.value = !!list.length
    }
    else {
      repeatBizList_telephone = list
      showCodeExtraLine.value = !!list.length
    }
  }
}

/** 展示匹配商机弹窗 */
function checkSimilarBiz(type: number, showActionColumn = true) {
  repeatTalentBizModal.value.visible = true
  repeatTalentBizModal.value.triggerType = type
  repeatTalentBizModal.value.showActionColumn = showActionColumn
  // 点击企业名称下的去查看
  if (type === 1) {
    // repeatTalentBizModal.value.triggerType = 1
    repeatTalentBizModal.value.list = repeatBizList_name
  }

  // 点击信用代码下的去查看
  else {
    // repeatTalentBizModal.value.triggerType = 2
    repeatTalentBizModal.value.list = repeatBizList_telephone
  }
}
/**
 * @param needRepeatValidate 是否需要重复校验
 */
function handleOk(needRepeatValidate = true) {
  onSaving.value = true
  try {
  // 选择已有商机进行签约
    if (isSign.value && +tabType.value === 1) {
      signFn()
    }
    // 人才商机
    else if (isBusinessForm.value) {
      businessFormSave(needRepeatValidate)
    }
    // 个人客户
    else if (isCustomerForm.value) {
    // 商机签约客户的时候，如果新建成功，但是绑定失败（存在dataId）, 则直接拿着 dataId 走签约逻辑
      if (+tabType.value === 2 && !needRepeatValidate && dataId)
        signFn()
      else
        customerFormSave(needRepeatValidate)
    }
  }
  catch (error) {
    onSaving.value = false
  }
}
/** 签约 */
async function signFn() {
  const _tabType = +tabType.value
  if (_tabType === 1 && !selectedExistId.value) {
    AMessage.error('请选择')
    return
  }
  let businessId = ''
  let customerId = ''
  // 商机签约客户， 则客户 id 使用用户自己选的值 或新增的值, 商机 id 使用 props 值
  if (isFromBusinessPage.value) {
    customerId = _tabType === 1 ? selectedExistId.value : dataId
    businessId = props.businessId
  }
  // 客户签约商机， 则商机 id 使用用户自己选的值, 客户 id 使用 props 值
  else {
    businessId = selectedExistId.value
    customerId = props.customerId
  }
  const { data } = await signingSave({
    businessId,
    businessType: signParamObj.value?.businessType, // 商机类型
    customerType: signParamObj.value?.customerType, // 客户类型
    customerId,
    signType: props.signType, // 签约类型
  })
  onSaving.value = false
  if (data.value) {
    dataId = ''
    AMessage.success('操作成功')
    emit('success')
    handleCancel()
    pageJump({ businessId: data.value.businessId, signId: data.value.signId })
  }
}
/** 三方跳转 */
function pageJump({ businessId = '', signId = '' }) {
  // 不存在签约方式，说明是关联客户，不需要跳转
  if (!props.signType)
    return

  if (isSign.value)
    jumpToOA({ signId })
}
/**
 * 新建/编辑商机表单数据保存
 * @param needRepeatValidate 是否需要重复校验
 */
function businessFormSave(needRepeatValidate: boolean) {
  formRef.value.validate().then(async (err: any) => {
    if (err)
      onSaving.value = false
    if (!err) {
      if (!validateTitle() || !validateCertificate())
        return

      let res = null as any
      const param = handleBusinessSaveData()
      // 人才商机创建并领取并签约
      if (isSign.value) {
        res = await businessAddSignBusinessPerson({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
          createSource: props.createSource,
          businessType: signParamObj.value?.businessType, // 商机类型
          signType: props.signType, //  签约类型
          customerType: signParamObj.value?.customerType, // 客户类型
          customerId: props.customerId,
        })
      }
      else if (props.isEdit) {
        res = await businessEditBusinessPerson({
          ...param,
          id: props.businessId,
          createSource: props.createSource,
        })
      }
      else {
        res = await businessAddBusinessPerson({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
          createSource: props.createSource,
        })
      }
      onSaving.value = false
      if (res?.data?.value) {
        const list = res?.data?.value?.list || []
        if (props.isEdit || !list.length || !needRepeatValidate) {
          AMessage.success(props.isEdit ? '保存成功' : '创建成功')
          emit('success')
          handleCancel()
          if (isSign.value)
            pageJump({ businessId: res?.data.value.businessId, signId: res?.data.value.signId })
        }
        else {
          repeatTalentBizModal.value = {
            visible: true,
            triggerType: 3,
            list,
            showConfirmBtn: true,
            showActionColumn: props.formType !== 'customer',
          }
        }
      }
    }
  })
}

/**
 * 新建/编辑个人客户表单数据保存
 * @param needRepeatValidate 是否需要重复校验
 */
function customerFormSave(needRepeatValidate: boolean) {
  formRef.value.validate().then(async (err: any) => {
    if (err)
      onSaving.value = false
    if (!err) {
      if (!validateTitle() || !validateCertificate())
        return

      let res = null as any
      const param = handleCustomerSaveData()
      if (props.isEdit) {
        res = await customerPersonUpdate({
          ...param,
          id: props.customerId,
        })
      }
      else {
        res = await customerPersonAdd({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
        })
      }
      onSaving.value = false
      if (res?.data?.value) {
        const list = res?.data?.value?.list || []
        if (props.isEdit || !list.length || !needRepeatValidate) {
          if (isSign.value) {
            dataId = res?.data?.value?.id // 新增成功的id
            signFn()
          }
          else {
            AMessage.success('操作成功')
            emit('success')
            handleCancel()
          }
        }
        else {
          repeatTalentBizModal.value = {
            visible: true,
            triggerType: 3,
            list,
            showConfirmBtn: true,
            showActionColumn: props.formType !== 'customer',
          }
        }
      }
    }
  })
}

/** 处理职称保存数据 */
function handleTitleListSaveData() {
  return titleList.value.filter((item) => {
    return item.level && item.major
  }).map((item) => {
    const major = item.major as any
    return {
      level: item.level,
      major: major.length ? major.join(',') : '',
      id: item.id,
    }
  })
}
/** 处理执业资格证保存数据 */
function handleCertificateListData() {
  const pageFrom = +props.pageFrom
  if (pageFrom === 2) {
    const arr = certificateList.value.filter((item) => {
      return item?.name
    })
    return arr.map((item: ITitle) => {
      let obj = {}
      if (item?.name?.length > 2) {
        obj = {
          name: item?.name?.[2] || '',
          major: item?.name?.[3] || '',
          id: item.id,
        }
      }
      else {
        obj = {
          name: item?.name?.[0] || '',
          major: item?.name?.[1] || '',
          id: item.id,
        }
      }
      return obj
    })
  }
  else {
    const arr = certificateList.value.filter((item) => {
      return item?.name?.[0] && item?.name?.[1]
    })
    return arr.map((item: ITitle) => {
      const obj = {
        name: item?.name?.[0],
        major: item?.name?.[1],
        id: item.id,
      }
      return obj
    })
  }
}

/** 处理人才商机保存数据 */
function handleBusinessSaveData() {
  // const hasCoordinate = !!location.value.lng && !!location.value.lat
  const fv = formData.value
  const param = {
    achievementCnt: numberInputSave(fv.achievementCnt), // 个人业绩
    addr: location.value.address || '', // 企业地址
    country: location.value.country || '', // 国家
    province: location.value.province || '', // 省份
    city: location.value.city || '', // 市
    area: location.value.area || '', // 区县
    businessType: 'PERSON', // 商机类型 name PERSON PROJECT
    certificateList: fv.qualificationCertificate === '1' ? handleCertificateListData() : [],
    // coordinate: hasCoordinate ? `${location.value.lng},${location.value.lat}` : '', // 经度，纬度
    lat: location.value.lat || '',
    lng: location.value.lng || '',
    education: fv.education, // 学历
    gender: fv.gender,
    personName: fv.name, // 人才名称
    phone: fv.telephone,
    titleList: fv.technicalTitle === '1' ? handleTitleListSaveData() : [],
  }
  return param
}

/** 处理个人客户保存数据 */
function handleCustomerSaveData() {
  const hasCoordinate = !!location.value.lng && !!location.value.lat
  const labels = formData.value.labelList.map((labelName: string) => {
    let id = ''
    if (labelArr.value?.length) {
      labelArr.value.forEach((x: any) => {
        if (labelName === x.labelName)
          id = x.id
      })
    }
    const params = {
      id,
      labelName,
    }
    return params
  })
  const fv = formData.value
  const param = {
    achievementCnt: numberInputSave(fv.achievementCnt), // 个人业绩
    addr: location.value.address || '', // 企业地址
    country: location.value.country || '', // 国家
    province: location.value.province || '', // 省份
    city: location.value.city || '', // 市
    area: location.value.area || '', // 区县
    location: location.value.location || '',
    businessType: 'PERSON', // 商机类型 name PERSON PROJECT
    certificateList: fv.qualificationCertificate === '1' ? handleCertificateListData() : [],
    coordinate: hasCoordinate ? `${location.value.lng},${location.value.lat}` : '', // 经度，纬度
    education: fv.education, // 学历
    gender: fv.gender,
    personName: fv.name, // 人才名称
    phone: fv.telephone,
    titleList: fv.technicalTitle === '1' ? handleTitleListSaveData() : [],
    labels: props.isEdit ? labels : formData.value.labelList, // 客户标签
  }
  return param
}

/** 商机创建并领取成功，两个弹窗全部关闭 */
function receiveSuccess() {
  repeatTalentBizModal.value.visible = false
  emit('success')
  handleCancel()
}
/** 在重复列表全屏弹窗里点关联客户 */
async function associationCustomer(customerId: string) {
  const { data } = await signingSave({
    businessId: props.businessId,
    businessType: signParamObj.value?.businessType, // 商机类型
    customerType: signParamObj.value?.customerType, // 客户类型
    customerId,
    signType: props.signType, // 签约类型
  })
  if (data.value) {
    dataId = ''
    AMessage.success('关联成功')
    emit('success')
    handleCancel()
    pageJump({ businessId: data.value.businessId, signId: data.value.signId })
  }
}

function handleCancel() {
  emit('update:visible', false)
}

/** 职称校验 */
function validateTitle() {
  if (!formData.value.technicalTitle)
    return true
  // 不能填一半
  const hasEmpty = titleList.value.find(item => (!item.level && item.major) || (item.level && !item.major))
  if (hasEmpty) {
    AMessage.error('职称不能为空')
    return false
  }
  // 判重
  let validate = true
  for (let i = 0; i < titleList.value.length; i++) {
    const item = titleList.value[i]
    const level = item.level
    const major = item.major
    if (level && major) {
      const hasRepeat = titleList.value.find((it, index) => {
        return i !== index && item.level === it.level && item.major === it.major
      })
      if (hasRepeat) {
        AMessage.error('职称重复')
        onSaving.value = false
        validate = false
        break
      }
    }
  }
  return validate
}

/** 执业资格证校验 */
function validateCertificate() {
  if (!formData.value.qualificationCertificate)
    return true
  // 判重
  let validate = true
  for (let i = 0; i < certificateList.value.length; i++) {
    let name0 = ''
    let name1 = ''
    const certificateArr = certificateList.value[i].name || []
    if (certificateArr.length > 2) {
      name0 = certificateList.value[i].name![2] as string
      name1 = certificateList.value[i].name![3] as string
    }
    else {
      name0 = certificateList.value[i].name![0] as string
      name1 = certificateList.value[i].name![1] as string
    }
    if (name0 && name1) {
      const hasRepeat = certificateList.value.find((it: any, index) => {
        let it0 = ''
        let it1 = ''
        if (it?.name?.length > 2) {
          it0 = it.name[2] as string
          it1 = it.name[3] as string
        }
        else {
          it0 = it.name[0] as string
          it1 = it.name[1] as string
        }
        return i !== index && name0 === it0 && name1 === it1
      })
      if (hasRepeat) {
        AMessage.error('执业资格证重复')
        onSaving.value = false
        validate = false
        break
      }
    }
  }
  return validate
}
</script>

<template>
  <AModal
    :visible="_visible"
    :mask-closable="false"
    width="735px"
    title-align="start"
    :title="title"
    :ok-loading="onSaving"
    :ok-text="isSign && isFromCustomerPage ? '下一步' : '确定'"
    @ok="handleOk(!props.isEdit || isSign)"
    @cancel="handleCancel"
  >
    <div>
      <!-- TALENT
      <div>pageFrom: {{ props.pageFrom }}</div>
      <div>formType: {{ props.formType }}</div>
      <div>signType: {{ props.signType }}</div> -->
      <div>
        <!-- 签约才需要展示内容切换 -->
        <ARadioGroup v-if="isSign" v-model="tabType" type="button" class="mb-16px" @change="val => tabType = val">
          <ARadio :value="1">
            {{ isFromBusinessPage ? "已有客户" : "已有商机" }}
          </ARadio>
          <ARadio :value="2">
            {{ isFromBusinessPage ? "新增客户" : "新增商机" }}
          </ARadio>
        </ARadioGroup>
        <!-- 选择已有客户 -->
        <template v-if="isSign && tabType === 1">
          <ExistedList
            ref="existedListRef"
            :type="isFromBusinessPage ? 'customer' : 'business'"
            business-type="PERSON"
            @change="val => selectedExistId = val"
          />
        </template>

        <!-- 新建 -->
        <AForm v-if="(isSign && +tabType === 2) || !isSign" ref="formRef" :model="formData" auto-label-width layout="vertical">
          <ARow :gutter="24">
            <ACol :span="12">
              <AFormItem field="name" label="姓名" :rules="[{ required: true, message: '请输入姓名' }]">
                <AInput
                  v-model="formData.name"
                  placeholder="请输入"
                  :max-length="20"
                  @input="showNameExtraLine = false"
                  @change="formData.name = formData.name.trim()"
                  @blur="isBusinessForm ? getSimilarBiz(1) : getSimilarCustomer(1)"
                />
                <template v-if="showNameExtraLine && !props.isEdit && formData.name" #extra>
                  <div>
                    <template v-if="props.formType === 'customer'">
                      可能已存在，
                      <AButton type="text" class="px-0px" @click="checkSimilarBiz(1, false)">
                        去查看
                      </AButton>
                    </template>
                    <template v-else>
                      已匹配到{{ isBusinessForm ? '商机' : '客户' }}，请勿重复新增，
                      <AButton type="text" class="px-0px" @click="checkSimilarBiz(1, true)">
                        去查看
                      </AButton>
                    </template>
                  </div>
                </template>
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="telephone" label="手机号" :rules="[{ required: isCustomerForm, validator: validatePhone }]">
                <AInput
                  v-model="formData.telephone"
                  placeholder="请输入"
                  @input="showCodeExtraLine = false"
                  @blur="isBusinessForm ? getSimilarBiz(2) : getSimilarCustomer(2)"
                  @change="formData.telephone = formData.telephone.trim()"
                />
                <template v-if="showCodeExtraLine && !props.isEdit && formData.telephone" #extra>
                  <div>
                    <template v-if="props.formType === 'customer'">
                      可能已存在，
                      <AButton type="text" class="px-0px" @click="checkSimilarBiz(2, false)">
                        去查看
                      </AButton>
                    </template>
                    <template v-else>
                      已匹配到{{ isBusinessForm ? '商机' : '客户' }}，请勿重复新增，
                      <AButton type="text" class="px-0px" @click="checkSimilarBiz(2, true)">
                        去查看
                      </AButton>
                    </template>
                  </div>
                </template>
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="gender" label="性别">
                <ARadioGroup v-model="formData.gender">
                  <ARadio value="男">
                    男
                  </ARadio>
                  <ARadio value="女">
                    女
                  </ARadio>
                </ARadioGroup>
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="education" label="学历">
                <ASelect v-model="formData.education" :options="educationOptions()" placeholder="请选择" allow-clear />
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="technicalTitle" label="职称">
                <div class="w100">
                  <div>
                    <ARadioGroup v-model="formData.technicalTitle" @change="changeTitle">
                      <ARadio value="1">
                        有
                      </ARadio>
                      <ARadio value="">
                        没有
                      </ARadio>
                    </ARadioGroup>
                  </div>
                  <template v-if="formData.technicalTitle === '1'">
                    <div v-for="(item, idx) in titleList" :key="idx" class="mb8px flex items-center">
                      <ASelect v-model="item.level" allow-clear class="w-90px" placeholder="职级">
                        <AOption v-for="i in levelOptions" :key="i.value" :value="i.value">
                          {{ i.label }}
                        </AOption>
                      </ASelect>
                      <ACascader
                        v-model="item.major"

                        allow-clear path-mode class="ml5px mr8px w-215px truncate"
                        :options="technicalTitleOptions" placeholder="请选择"
                      />
                      <icon-plus-circle v-if="idx < 1" class="cursor-pointer text-25px c-[#165DFF]" @click="addTitle" />
                      <icon-close-circle v-if="idx >= 1" class="cursor-pointer text-25px c-[#FA4753]" @click="delTitle(idx)" />
                    </div>
                  </template>
                </div>
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="qualificationCertificate" label="执业资格证">
                <div class="w100">
                  <div>
                    <ARadioGroup v-model="formData.qualificationCertificate" @change="changeCertificate">
                      <ARadio value="1">
                        有
                      </ARadio>
                      <ARadio value="">
                        没有
                      </ARadio>
                    </ARadioGroup>
                  </div>
                  <template v-if="formData.qualificationCertificate === '1'">
                    <div v-for="(item, idx) in certificateList" :key="idx" class="mb8px flex items-center">
                      <ACascader
                        v-model="item.name" allow-clear class="ml5px mr8px w-290px truncate" path-mode
                        :options="qualificationCertificateOptions" placeholder="请选择"
                      />
                      <icon-plus-circle v-if="idx < 1" class="cursor-pointer text-25px c-[#165DFF]" @click="addCertificate" />
                      <icon-close-circle v-if="idx >= 1" class="cursor-pointer text-25px c-[#FA4753]" @click="delCertificate(idx)" />
                    </div>
                  </template>
                </div>
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="address" label="联系地址">
                <AInput v-model.trim="formData.address" placeholder="请地图上选择地点" allow-clear @click="aMapModalVisible = true" @clear.stop="formData.address = '';location = {} as any" />
              </AFormItem>
            </ACol>
            <ACol :span="12">
              <AFormItem field="achievementCnt" label="个人业绩">
                <AInputNumber v-model="formData.achievementCnt" :min="0" :max="99999999" :precision="0" placeholder="请输入" hide-button />
              </AFormItem>
            </ACol>
            <ACol v-if="isCustomerForm" :span="12">
              <AFormItem field="registeredPerson" label="客户标签">
                <ASelect v-model="formData.labelList" class="w-100" placeholder="请选择" allow-clear multiple>
                  <AOption v-for="item in customerLabelOptions()" :key="item.value" :value="item.value">
                    {{ item.label }}
                  </AOption>
                </ASelect>
              </AFormItem>
            </ACol>
          </ARow>
        </AForm>
      </div>
    </div>
  </AModal>

  <!-- 重复商机弹窗 -->
  <AModal
    v-if="repeatTalentBizModal.visible"
    v-model:visible="repeatTalentBizModal.visible"
    :mask-closable="false"
    :title="isBusinessForm ? '选择商机' : '选择客户'"
    title-align="start"
    fullscreen
    :footer="repeatTalentBizModal.triggerType === 3"
  >
    <RepeatTalentBiz
      :type="isBusinessForm ? 'business' : 'customer'"
      :create-source="props.createSource"
      :trigger-type="repeatTalentBizModal.triggerType"
      :list="repeatTalentBizModal.list"
      :show-action-column="repeatTalentBizModal.showActionColumn"
      :name-search-str="formData.name"
      :telephone-search-str="formData.telephone"
      :is-sign="isSign"
      :sign-param-obj="signParamObj"
      :sign-type="props.signType"
      :customer-id="props.customerId"
      @success="receiveSuccess"
      @association-customer="associationCustomer"
      @page-jump="pageJump"
    />
    <template #footer>
      <div>
        <AButton @click="repeatTalentBizModal.visible = false">
          取消
        </AButton>
        <AButton type="primary" class="ml-12px" @click="handleOk(false)">
          直接创建
        </AButton>
      </div>
    </template>
  </AModal>
  <!-- 地图选点组件 -->
  <GaodeMapModal v-if="aMapModalVisible" v-model:visible="aMapModalVisible" :default-position="location" @ok="mapOk" />
</template>
