import type {
  DelReq,
  LianXiRenLieBiaoQingQiu,
  TianJiaLianXiRenQingQiu,
} from './_interfaces.ts'

/** 添加联系人 */
export function businessContactAdd(data: TianJiaLianXiRenQingQiu) {
  return usePost<any>('/api/businessContact/add', data)
}

/** 删除联系人 */
export function businessContactDeleteById(data: DelReq) {
  return usePost<any>('/api/businessContact/deleteById', data)
}

/** 联系人列表 */
export function businessContactList(data: LianXiRenLieBiaoQingQiu) {
  return usePost<any>('/api/businessContact/list', data)
}
