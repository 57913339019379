import type { App } from 'vue'
import type { Router } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import routes from 'virtual:generated-pages'
import { useMenuStore } from '~/stores/menu'
import { login } from '~/api/_user'

function setupRouterGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const userInfoStore = useUserInfoStore()
    const urlParams = useUrlSearchParams('history')
    const token = urlParams.satoken?.toString()
    if (token) {
      localStorage.setItem('token', token)
      userInfoStore.getUserInfo()
    }
    else if (!localStorage.getItem('token')) {
      const status = await login()
      if (!status)
        return next('403')
      userInfoStore.getUserInfo()
    }

    if (/refresh|403/.test(to.name as string))
      return next()

    const menuStore = useMenuStore()
    await menuStore.fetchConfig()
    let exist = false
    for (const menu of menuStore.all) {
      if (to.name === menu.name) {
        exist = true
        break
      }
    }
    const firstMenuName = menuStore.all[0]?.name
    if (!exist && firstMenuName)
      next({ name: firstMenuName })
    else if (!exist && !firstMenuName)
      next({ name: '403' })
    else
      next()
  })
}

export async function setupRouter(app: App) {
  const router = createRouter({
    history: createWebHashHistory(),
    routes,
  })
  app.use(router)
  setupRouterGuard(router)
  await router.isReady()
}
