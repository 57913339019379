<!-- 批量转让弹窗 -->
<script setup lang='ts'>
import { businessFollowTransfer } from '~/api/businessFollow'
import { deptDeptWithEmployees } from '~/api/dept'
import { salesManagementGetSalesAreaPersonTree } from '~/api/salesManagement'

interface FormData {
  followUpPerson: {
    value?: string
    label?: string
  }
  type: string
}

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 商机 id */
  businessIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  /** 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机 */
  businessType: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:visible', 'success'])

const _visible = ref(false)
const areaPersonList = ref<any[]>([]) // 区域人员列表
const deptPersonList = ref<any[]>([]) // 部门人员列表

const formRef = ref()
const formData = reactive<FormData>({
  followUpPerson: {}, // 选中的跟进人
  type: 'area',
})

/** 递归处理部门员工树 */
function handleTree(arr: any) {
  for (let i = 0; i < arr.length; i++) {
    const node = arr[i]
    if (node.children?.length > 0) {
      if (node.employees?.length)
        node.children = [...node.children, ...node.employees]

      // 如果当前节点有子节点，则递归查找子节点
      handleTree(node.children)
    }
    else {
      node.children = node.employees
      node.disabled = !node.children?.length && !node.mainDept
    }
  }
  // 没有找到目标节点，返回 null
  return null
}

/** 递归处理区域员工树 */
function handleTreeX(arr: any) {
  const result: any[] = []
  arr.forEach((item: any) => {
    // 判断是区域item时
    if (item.parentId) {
      let children = []
      if (item.children?.length > 0)
        children.push(...item.children)

      if (item.person?.length > 0)
        children.push(...item.person)

      children = handleTreeX(children)
      if (children.length > 0) {
        result.push({
          ...item,
          children,
        })
      }
    }
    else {
      result.push(item)
    }
  })
  return result
}

/** 获取区域人员列表 */
async function getAreaPersonList() {
  const { data } = await salesManagementGetSalesAreaPersonTree()
  areaPersonList.value = handleTreeX(data.value)
}

/** 获取部门人员列表 */
async function getDeptPersonList() {
  const { data } = await deptDeptWithEmployees()
  if (data.value.length)
    handleTree(data.value)
  deptPersonList.value = data.value
}

const areaPersons = ref<any[]>([])
/** 递归获取区域下所有员工 */
function getPersonsByDeep(data: any[]): any[] {
  return data.flatMap((item) => {
    const persons = item.person || []
    const children = item.children || []
    return [...persons, ...getPersonsByDeep(children)]
  })
}

function filterTreeNode(searchValue: string, nodeData: any) {
  return nodeData.title.toLowerCase().includes(searchValue.toLowerCase())
}

watch(() => formData.type, async () => {
  await nextTick()
  formData.followUpPerson = {}
  formRef.value.clearValidate()
}, { immediate: true })

onMounted(async () => {
  await getAreaPersonList()
  await getDeptPersonList()
  areaPersons.value = getPersonsByDeep(areaPersonList.value)
})

watchEffect(() => {
  _visible.value = props.visible
})
function handleOk() {
  formRef.value.validate().then(async (err: any) => {
    if (!err) {
      const { data } = await businessFollowTransfer({
        businessId: props.businessIds,
        businessType: props.businessType,
        followerId: formData.type === 'area' ? areaPersons.value.find(item => item.key === formData.followUpPerson.value)?.employeeId : formData.followUpPerson.value,
        followerName: formData.followUpPerson.label,
      })
      if (data.value) {
        AMessage.success('操作成功')
        emit('success')
        handleCancel()
      }
    }
  })
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    title="转让"
    width="520px"
    :visible="_visible"
    :mask-closable="false"
    title-align="start"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <div>
        <AAlert class="mb-16px h-32px border-none">
          <div class="color-#33363A">
            已选中 {{ props.businessIds.length }} 条商机
          </div>
        </AAlert>
      </div>
      <AForm ref="formRef" :model="formData" auto-label-width>
        <AFormItem field="followUpPerson.value" label="转让给跟进人" :rules="[{ required: true, message: '请选择跟进人' }]">
          <div>
            <ARadioGroup v-model="formData.type" class="lh-32px">
              <ARadio value="area">
                按区域选择
              </ARadio>
              <ARadio value="dept">
                按部门选择
              </ARadio>
            </ARadioGroup>
            <ATreeSelect
              v-if="formData.type === 'area'"
              v-model="formData.followUpPerson"
              class="mt-10px w-280px"
              :data="areaPersonList"
              placeholder="请选择"
              allow-clear
              allow-search
              label-in-value
              selectable="leaf"
              :filter-tree-node="filterTreeNode"
            />
            <ATreeSelect
              v-else
              v-model="formData.followUpPerson"
              class="mt-10px w-280px"
              :data="deptPersonList"
              placeholder="请选择"
              allow-clear
              allow-search
              label-in-value
              selectable="leaf"
              :filter-tree-node="filterTreeNode"
            />
          </div>
        </AFormItem>
      </AForm>
    </div>
  </AModal>
</template>
