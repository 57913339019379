import { userGetEmployeeInfo } from '~/api/user'

export const useUserInfoStore = defineStore('userInfo', () => {
  /** 当前登录者信息 */
  const userInfo = ref({
    userId: '', // 当前登录者id
    userName: '', // 名字
    mobile: '', // 手机号
  })

  async function getUserInfo() {
    const { data: curUser } = await userGetEmployeeInfo()
    userInfo.value = {
      userId: curUser.value.id,
      userName: curUser.value.userName,
      mobile: curUser.value.mobile,
    }
  }

  return { userInfo, getUserInfo }
})
