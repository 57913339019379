<!-- 批量分派弹窗 -->
<script setup lang='ts'>
import dayjs from 'dayjs'
import { businessAssignBusiness } from '~/api/business'
import { deptDeptWithEmployees } from '~/api/dept'
import { salesManagementGetSalesAreaPersonTree } from '~/api/salesManagement'
import { userGetEmployeeInfo } from '~/api/user'

interface FormData {
  followUpPerson: {
    value?: string
    label?: string
  }
  date: string
  type: string
}

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 商机 id */
  businessIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  /** 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机 */
  businessType: {
    type: String,
    default: '',
  },
  /** 商机来源 雷达:radar 公海:open_sea 高级搜索:advanced_search 重复列表:bigdata_search 商机检索:search_platform */
  businessReceiveSource: {
    type: String,
    default: '',
  },
  /** 是否展示拜访计划 */
  showVist: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['update:visible', 'success'])
let defaultUser = { userId: '', userName: '', mobile: '' } // 默认选中值

const modelVisible = useVModel(props, 'visible', emit)
const areaPersonList = ref<any[]>([]) // 区域人员列表
const deptPersonList = ref<any[]>([]) // 部门人员列表

const formRef = ref()
const formData = reactive<FormData>({
  followUpPerson: {}, // 选中的跟进人
  date: '',
  type: 'area',
})

/** 递归处理部门员工树 */
function handleTree(arr: any) {
  for (let i = 0; i < arr.length; i++) {
    const node = arr[i]
    if (node.children?.length > 0) {
      if (node.employees?.length)
        node.children = [...node.children, ...node.employees]

      // 如果当前节点有子节点，则递归查找子节点
      handleTree(node.children)
    }
    else {
      node.children = node.employees
      node.disabled = !node.children?.length && !node.mainDept
    }
  }
  // 没有找到目标节点，返回 null
  return null
}

/** 递归处理区域员工树 */
function handleTreeX(arr: any) {
  const result: any[] = []
  arr.forEach((item: any) => {
    // 判断是区域item时
    if (item.parentId) {
      let children = []
      if (item.children?.length > 0)
        children.push(...item.children)

      if (item.person?.length > 0)
        children.push(...item.person)

      children = handleTreeX(children)
      if (children.length > 0) {
        result.push({
          ...item,
          children,
        })
      }
    }
    else {
      result.push(item)
    }
  })
  return result
}

/** 获取区域人员列表 */
async function getAreaPersonList() {
  const { data } = await salesManagementGetSalesAreaPersonTree()
  areaPersonList.value = handleTreeX(data.value)
}

/** 获取部门人员列表 */
async function getDeptPersonList() {
  const { data } = await deptDeptWithEmployees()
  if (data.value.length)
    handleTree(data.value)
  deptPersonList.value = data.value
}

const areaPersons = ref<any[]>([])
const deptPersons = ref<any[]>([])
/** 递归获取区域或部门下所有员工 */
function getPersonsByDeep(data: any[], type: 'area' | 'dept'): any[] {
  return data.flatMap((item) => {
    const persons = item.person || []
    const employees = item.employees || []
    const children = item.children || []
    return type === 'area' ? [...persons, ...getPersonsByDeep(children, type)] : [...employees, ...getPersonsByDeep(children, type)]
  })
}

function filterTreeNode(searchValue: string, nodeData: any) {
  return nodeData.title.toLowerCase().includes(searchValue.toLowerCase())
}

watch(() => formData.type, async (type) => {
  await nextTick()
  if (formData.followUpPerson.value) {
    let person = null
    if (type === 'area') {
      person = areaPersons.value.find(item => item.employeeId === formData.followUpPerson.value)
    }
    else {
      const id = areaPersons.value.find(item => item.key === formData.followUpPerson.value)?.employeeId
      person = deptPersons.value.find(item => item.employeeId === id)
    }

    if (person) {
      formData.followUpPerson.value = type === 'area' ? person.key : person.employeeId
      formData.followUpPerson.label = person.title
    }
    else {
      formData.followUpPerson = {}
    }
  }
  formRef.value.clearValidate()
}, { immediate: true })

watch(() => modelVisible.value, async (val) => {
  if (val) {
    const { data: curUser } = await userGetEmployeeInfo()
    defaultUser = {
      userId: curUser.value.id,
      userName: curUser.value.userName,
      mobile: curUser.value.mobile,
    }
    await getAreaPersonList()
    await getDeptPersonList()
    areaPersons.value = getPersonsByDeep(areaPersonList.value, 'area')
    deptPersons.value = getPersonsByDeep(deptPersonList.value, 'dept')
    formData.followUpPerson.value = formData.type === 'area' ? areaPersons.value.find(item => item.employeeId === curUser.value.id)?.key : curUser.value.id
    formData.followUpPerson.label = curUser.value.userName
  }
}, { immediate: true })

const resultVisible = ref(false)
const resultData = ref({})
const loading = ref(false)
async function handleOk() {
  const err = await formRef.value.validate()
  if (err)
    return
  loading.value = true
  const businessList = []
  for (const id of props.businessIds) {
    const business: any = {}
    business.businessType = props.businessType
    if (props.businessReceiveSource === 'search_platform')
      business.searchPlatformId = id
    else
      business.id = id
    businessList.push(business)
  }
  const { data } = await businessAssignBusiness({
    businessReceiveSource: props.businessReceiveSource,
    businessType: props.businessType,
    businessList,
    receiverId: formData.type === 'area' ? areaPersons.value.find(item => item.key === formData.followUpPerson.value)?.employeeId : formData.followUpPerson.value,
    receiverName: formData.followUpPerson.label,
    date: formData.date,
  })
  loading.value = false
  if (!data.value)
    return
  handleCancel()
  resultVisible.value = true
  resultData.value = data.value
}
function handleCancel() {
  modelVisible.value = false
  formData.type = 'area'
  formData.date = ''
}
</script>

<template>
  <AModal
    title="分派"
    width="520px"
    class="visitTimeModal"
    :visible="modelVisible"
    :mask-closable="false"
    title-align="start"
    :ok-loading="loading"
    @close="formRef.resetFields()"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <AAlert v-if="props.businessIds.length" class="mb-16px h-32px border-none">
        <div class="color-#33363A">
          已选中 {{ props.businessIds.length }} 条商机
        </div>
      </AAlert>
      <AForm ref="formRef" :model="formData" auto-label-width>
        <AFormItem field="followUpPerson.value" label="跟进人" :rules="[{ required: true, message: '请选择跟进人' }]">
          <div>
            <ARadioGroup v-model="formData.type" class="lh-32px">
              <ARadio value="area">
                按区域选择
              </ARadio>
              <ARadio value="dept">
                按部门选择
              </ARadio>
            </ARadioGroup>
            <ATreeSelect
              v-if="formData.type === 'area'"
              v-model="formData.followUpPerson"
              class="mt-10px w-280px"
              :data="areaPersonList"
              placeholder="请选择"
              allow-clear
              allow-search
              label-in-value
              selectable="leaf"
              :filter-tree-node="filterTreeNode"
            />
            <ATreeSelect
              v-else
              v-model="formData.followUpPerson"
              class="mt-10px w-280px"
              :data="deptPersonList"
              placeholder="请选择"
              allow-clear
              allow-search
              label-in-value
              selectable="leaf"
              :filter-tree-node="filterTreeNode"
            />
          </div>
        </AFormItem>
        <AFormItem field="date" label="计划拜访时间">
          <ADatePicker
            v-model="formData.date"
            class="w-130"
            value-format="YYYY-MM-DD HH:mm:ss"
            allow-clear
            :disabled-date="(current) => dayjs(current).isBefore(dayjs().subtract(1, 'day'))"
          />
        </AFormItem>
      </AForm>
    </div>
  </AModal>

  <ModalHandleResult
    v-model:visible="resultVisible"
    v-bind="resultData"
    :business-type="businessType"
    title="分派"
    @ok="emit('success')"
  />
</template>
