// import { getChildComm, getChildGlobalComm } from '@wyg/micro-utils';
// import type { ChildCommType, GlobalCommType } from '@wyg/micro-utils';

declare global {
  interface Window {
    crmEventCenter: any
    microApp: any
  }
}
export const CENTER_NAME = 'microApp'
export const APP_NAME = 'appname-crm'

const MICRO_APP = window.microApp

export function isMicro() {
  return !!window.microApp
}

/******************************************************
   * 子应用发送给主应用
   * ****************************************************/
/**
   * dispatch是异步执行的，多个dispatch会在下一帧合并为一次执行
   * 第二个参数为回调函数，它会在数据发送结束后执行
   *
   * 当数据监听函数有返回值时，会作为dispatch回调函数的入参
   */
export function childSendData(data: any, cb?: Function) {
  window.microApp.dispatch(data, (res: any[]) => {
    if (cb)
      cb(res)
  })
}

/******************************************************
   * 子应用获取来自主应用的数据
   * ****************************************************/

// 方式1：直接获取数据
export function childGetData() {
  const data = window.microApp.getData() // 返回主应用下发的data数据
  return data
}
// 方式2：绑定监听函数
/**
  * 绑定监听函数，监听函数只有在数据变化时才会触发
  * dataListener: 绑定函数
  * autoTrigger: 在初次绑定监听函数时如果有缓存数据，是否需要主动触发一次，默认为false
  * !!!重要说明: 因为子应用是异步渲染的，而主应用发送数据是同步的，
  * 如果在子应用渲染结束前主应用发送数据，则在绑定监听函数前数据已经发送，在初始化后不会触发绑定函数，
  * 但这个数据会放入缓存中，此时可以设置autoTrigger为true主动触发一次监听函数来获取数据。
  */
export function childAddListener(dataListener: (data: Object) => any, autoTrigger?: boolean) {
  window.microApp.addDataListener(dataListener, autoTrigger)
}
// 解绑监听函数
export function childRemoveListener(dataListener: (data: Object) => any) {
  window.microApp.removeDataListener(dataListener)
}
// 清空当前子应用的所有绑定函数(全局数据函数除外)
export function childClearListener() {
  window.microApp.clearDataListener()
}

/******************************************************
   * 全局数据通信
   * ****************************************************/

/** ****** 子应用发送全局数据 *******/
export function setGlobalData(data: any, cb?: Function) {
  window.microApp.setGlobalData(data, (res: any[]) => {
    if (cb)
      cb(res)
  })
}

/** **** 子应用获取全局数据 *******/
// 方式1：直接获取数据
export function getGlobalData() {
  const globalData = window.microApp.getGlobalData() // 返回全局数据
  return globalData
}

// 方式2：绑定监听函数
/**
   * 绑定监听函数
   * dataListener: 绑定函数
   * autoTrigger: 在初次绑定监听函数时如果有缓存数据，是否需要主动触发一次，默认为false
   */
export function childAddGlobalListener(dataListener: (data: Object) => any, autoTrigger?: boolean) {
  window.microApp.addGlobalDataListener(dataListener, autoTrigger)
}

// 解绑监听函数
export function childRemoveGlobalListener(dataListener: (data: Object) => any): void {
  window.microApp.removeGlobalDataListener(dataListener)
}

// 清空当前子应用绑定的所有全局数据监听函数
export function childClearGlobalListener(): void {
  window.microApp.clearGlobalDataListener()
}

// 清空全局数据
export function clearGlobalData(): void {
  window.microApp.clearGlobalData()
}
