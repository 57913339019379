import { SSOClient, SSOLogout } from '@wyg/wyg-sso/lib'
import { MicroEnv, sendMicroMessage } from '~/utils/micro'

/** 菜单权限 */
export interface IMenuRes {
  menuRoute: IMenuRoute[]
  permission: string[]
}

export interface IMenuRoute {
  children?: IMenuRoute[]
  id?: number
  meta: IMeta
  name?: string
  path?: string
  query?: string
  redirect?: string
}

export interface IMeta {
  hideInMenu?: boolean
  hideChildrenInMenu?: boolean
  icon?: string
  locale: string
  menuType?: number
  order?: number
  requiresAuth?: boolean
}

export interface ITenantInfo {
  /** 认证状态?: 未认证 UNCERTIFICATED, 已认证 CERTIFIED */
  authStatus?: string
  /** 是否绑定渠道 */
  channelBindStatus?: string
  /** 渠道corpId */
  channelCorpId?: string
  /** 渠道类型 */
  channelType?: string
  /** 是否选中 */
  checked?: boolean
  /** 当前租户下员工状态 NONACTIVATED未激活,NORMAL正常,FORBID禁用 */
  employeeStatus?: string
  /** 是否为创建人 ture-是 false-否 */
  init?: boolean
  /** 租户类型,ENTERPRISE 企业租户,PERSON 个人租户,PLATFORM 平台租户 */
  kind?: string
  /** 租户状态（NORMAL正常 STOP停用） */
  status?: string
  /** 租户id */
  tenantId?: number
  /** 租户名称 */
  tenantName?: string
  /** 租户创建者id */
  userId?: number
}

/** 当前登录者的用户菜单路由+权限信息 */
export function fetchMenuList() {
  return useGet<IMenuRes>(`${import.meta.env.VITE_API_URL_BASE_PLATFORM}/api/index/getCurrentAuth`)
}

/** 获取当前的租户信息 */
export function fetchTenantInfo() {
  return useGet<ITenantInfo>(`${import.meta.env.VITE_API_URL_BASE_PLATFORM}/api/tenant/acquirePresentTenantInfo`)
}

const ssoConfig = {
  clientType: 'PC',
  sourceSystem: import.meta.env.VITE_SOURCE_SYSTEM,
  apiPath: import.meta.env.VITE_BASE_API_URL,
}
export function login() {
  return new SSOClient(ssoConfig).init()
}
export function logout() {
  if (MicroEnv) {
    // microLogout()
    sendMicroMessage('logout')
  }
  else {
    localStorage.clear()
    return new SSOLogout(ssoConfig).logout()
  }
}
