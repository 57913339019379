import type {
  AddSalesAreaReq,
  AddSalesManagementDeptReq,
  AddSalesManagementPersonReq,
  DataSyncUpdateOrAddInfo,
  DeleteAreaPersonReq,
  IdReq,
  PageSalesAreaPersonReq,
  PageSalesManagementReq,
  ReturnAreaPersonReq,
  SelectAreaReq,
} from './_interfaces.ts'

/** 新增/编辑销售区域 */
export function salesManagementAddOrModifySalesArea(data: AddSalesAreaReq) {
  return usePost<any>('/api/salesManagement/addOrModifySalesArea', data)
}

/** 新增部门人员（部门） */
export function salesManagementAddSalesDept(data: AddSalesManagementDeptReq) {
  return usePost<any>('/api/salesManagement/addSalesDept', data)
}

/** 新增市场人员 */
export function salesManagementAddSalesPerson(
  data: AddSalesManagementPersonReq,
) {
  return usePost<any>('/api/salesManagement/addSalesPerson', data)
}

/** 移除/批量移除 */
export function salesManagementDeleteAreaPerson(data: DeleteAreaPersonReq) {
  return usePost<any>('/api/salesManagement/deleteAreaPerson', data)
}

/** 删除销售区域 */
export function salesManagementDeleteSalesArea(data: IdReq) {
  return usePost<any>('/api/salesManagement/deleteSalesArea', data)
}

/** 移除市场人员 */
export function salesManagementDeleteSalesPerson(data: ReturnAreaPersonReq) {
  return usePost<any>('/api/salesManagement/deleteSalesPerson', data)
}

/** 停用部门 */
export function salesManagementDisableSalesDept(data: ReturnAreaPersonReq) {
  return usePost<any>('/api/salesManagement/disableSalesDept', data)
}

/** 停用市场人员 */
export function salesManagementDisableSalesPerson(data: IdReq) {
  return usePost<any>('/api/salesManagement/disableSalesPerson', data)
}

/** 启用市场人员 */
export function salesManagementEnableSalesPerson(data: IdReq) {
  return usePost<any>('/api/salesManagement/enableSalesPerson', data)
}

/** 区域详情 */
export function salesManagementGetSalesAreaDetail(data: { id?: string }) {
  return useGet<any>('/api/salesManagement/getSalesAreaDetail', data)
}

/** 获取销售区域列表 */
export function salesManagementGetSalesAreaList() {
  return useGet<any>('/api/salesManagement/getSalesAreaList')
}

/** 获取销售区域人员树 */
export function salesManagementGetSalesAreaPersonTree() {
  return useGet<any>('/api/salesManagement/getSalesAreaPersonTree')
}

/** 获取销售区域树 */
export function salesManagementGetSalesAreaTree() {
  return useGet<any>('/api/salesManagement/getSalesAreaTree')
}

/** 查询市场人员部门列表 */
export function salesManagementGetSalesDeptList() {
  return useGet<any>('/api/salesManagement/getSalesDeptList')
}

/** 分页查询销售区域人员 */
export function salesManagementPageSalesAreaPerson(
  data: PageSalesAreaPersonReq,
) {
  return usePost<any>('/api/salesManagement/pageSalesAreaPerson', data)
}

/** 分页查询销售区域人员-排除指定区域 */
export function salesManagementPageSalesAreaPersonExcludeArea(
  data: PageSalesAreaPersonReq,
) {
  return usePost<any>(
    '/api/salesManagement/pageSalesAreaPersonExcludeArea',
    data,
  )
}

/** 分页查询销售区域人员-不展示无区域 */
export function salesManagementPageSalesAreaPersonWithoutNone(
  data: PageSalesAreaPersonReq,
) {
  return usePost<any>(
    '/api/salesManagement/pageSalesAreaPersonWithoutNone',
    data,
  )
}

/** 分页市场人员管理列表 */
export function salesManagementPageSalesPerson(data: PageSalesManagementReq) {
  return usePost<any>('/api/salesManagement/pageSalesPerson', data)
}

/** 回归/批量回归 */
export function salesManagementReturnAreaPerson(data: ReturnAreaPersonReq) {
  return usePost<any>('/api/salesManagement/returnAreaPerson', data)
}

/** 选区/批量选区 */
export function salesManagementSelectArea(data: SelectAreaReq) {
  return usePost<any>('/api/salesManagement/selectArea', data)
}

/** 根据地区id查询人员 */
export function salesManagementSelectPersonListByAreaId(data: {
  areaId?: string
}) {
  return useGet<any>('/api/salesManagement/selectPersonListByAreaId', data)
}

/** 部门数据同步 */
export function salesManagementSyncDept(data: DataSyncUpdateOrAddInfo) {
  return usePost<any>('/api/salesManagement/syncDept', data)
}
