import type {
  BuZaiGenJin,
  BusinessFollowReq,
  FollowAgainReq,
  PiLiangBuZaiGenJin,
  ShangJiGenJinJiLuQingQiu,
  YuanGongLieBiaoQingQiu,
  ZhuanRangQingQiu,
} from './_interfaces.ts'

/** 部门员工树 */
export function businessFollowDeptEmployeeTree(data: YuanGongLieBiaoQingQiu) {
  return useGet<any>('/api/businessFollow/deptEmployeeTree', data)
}

/** 员工列表 */
export function businessFollowEmployeeList(data: YuanGongLieBiaoQingQiu) {
  return usePost<any>('/api/businessFollow/employeeList', data)
}

/** 跟进 */
export function businessFollowFollow(data: BusinessFollowReq) {
  return usePost<any>('/api/businessFollow/follow', data)
}

/** 重新跟进 */
export function businessFollowFollowAgain(data: FollowAgainReq) {
  return usePost<any>('/api/businessFollow/followAgain', data)
}

/** 不再跟进 */
export function businessFollowGiveUp(data: BuZaiGenJin) {
  return usePost<any>('/api/businessFollow/giveUp', data)
}

/** 批量不再跟进 */
export function businessFollowGiveUpBatch(data: PiLiangBuZaiGenJin) {
  return usePost<any>('/api/businessFollow/giveUpBatch', data)
}

/** 跟进记录-详情 */
export function businessFollowInfo(data: { id?: string }) {
  return useGet<any>('/api/businessFollow/info', data)
}

/** 跟进记录 */
export function businessFollowList(data: ShangJiGenJinJiLuQingQiu) {
  return usePost<any>('/api/businessFollow/list', data)
}

/** 转让 */
export function businessFollowTransfer(data: ZhuanRangQingQiu) {
  return usePost<any>('/api/businessFollow/transfer', data)
}

/** 转派 */
export function businessFollowTransferOther(data: ZhuanRangQingQiu) {
  return usePost<any>('/api/businessFollow/transferOther', data)
}

/** 跟进V2 */
export function businessFollowV2Follow(data: BusinessFollowReq) {
  return usePost<any>('/api/businessFollow/v2/follow', data)
}
