<script setup lang="ts">
import dayjs from 'dayjs'
import { businessPlanAddBusiness } from '~/api/businessPlan'

const props = defineProps({
  visible: {
    type: Boolean,
    default: () => false,
  },
  failCnt: {
    type: Number,
    default: () => 0,
  },
  successCnt: {
    type: Number,
    default: () => 0,
  },
  receiveName: {
    type: String,
    default: () => '',
  },
  /** 领取，分派 */
  title: {
    type: String,
    default: () => '领取',
  },
  /** 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机 */
  businessType: {
    type: String,
    default: () => '',
  },
  /** 商机 id */
  businessIds: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  /** 是否展示拜访计划 */
  showVist: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['update:visible', 'ok'])
const modelVisible = useVModel(props, 'visible', emit)
function handleClose() {
  modelVisible.value = false
  emit('ok')
}
const formRef = ref()
const formData = reactive({
  date: '',
})
const router = useRouter()
function goto() {
  switch (props.businessType) {
    case 'ENTERPRISE':
      router.push({ name: 'enterprise-my-list' })
      break
    case 'PERSON':
      router.push({ name: 'talent-my-list' })
      break
    case 'PROJECT':
      router.push({ name: 'project-my-list' })
      break
  }
  modelVisible.value = false
  emit('ok')
}
function getBuinessList() {
  const businessList = []
  for (const id of props.businessIds) {
    const business: any = {}
    business.businessType = props.businessType
    business.id = id
    businessList.push(business)
  }
  return businessList
}
async function handleDateFn(value: string) {
  const err = await formRef.value.validate()
  if (err)
    return
  const businessList = getBuinessList()
  const { data } = await businessPlanAddBusiness({
    addBusinessList: businessList,
    date: value,
  })
  if (data.value) {
    AMessage.success('操作成功')
    emit('ok')
  }
}
</script>

<template>
  <AModal v-model:visible="modelVisible" :title="title + (failCnt ? '结果' : '成功')" title-align="start" @close="handleClose()">
    <ASpace direction="vertical" fill>
      <AAlert v-if="failCnt" type="error" class="mb-16px h-32px border-none">
        另外 {{ failCnt }} 条商机重复并有人在跟进，{{ title }}失败。
      </AAlert>
      <div>
        已有 {{ successCnt }} 条商机跟进人已变为「{{ receiveName }}」{{ (title === '领取' && !failCnt) ? '，后续请到【我的商机】中查询。' : '' }}
      </div>
      <AForm v-if="props.businessIds.length && !!successCnt" ref="formRef" :model="formData" auto-label-width>
        <AFormItem field="date" label="计划拜访时间">
          <ADatePicker
            v-model="formData.date"
            class="w-130"
            value-format="YYYY-MM-DD HH:mm:ss"
            allow-clear
            :disabled-date="(current) => dayjs(current).isBefore(dayjs().subtract(1, 'day'))"
            @change="(value) => handleDateFn(value)"
          />
        </AFormItem>
      </AForm>
    </ASpace>
    <template #footer>
      <AButton @click="handleClose()">
        {{ (failCnt || title === '分派') ? '关闭' : '稍后查看' }}
      </AButton>
      <AButton v-if="failCnt || title === '分派'" type="primary" @click="handleClose()">
        我知道了
      </AButton>
      <AButton v-else type="primary" @click="goto()">
        去查看
      </AButton>
    </template>
  </AModal>
</template>
