import { userGetTenantSession } from '~/api/user'

const custmization = import.meta.env.VITE_WYG_TENANT_ID.split(',')
export const tenantStore = defineStore('tenant', {
  /** 当前登录者信息 */
  state: () => ({
    tenant: {
      tenantId: '',
      tenantName: '',
      loading: true,
    },
    custmization,
  }),
  actions: {
    getTenant() {
      userGetTenantSession().then(
        (res) => {
          const data = { ...res.data.value, loading: false }
          this.setTenant(data)
        },
        (err) => {
          if (err)
            this.setTenant({ ...this.tenant, loading: false })
        },
      )
    },
    setTenant(value) {
      this.tenant = value
      return value
    },
  },
})
