import Vue3TreeOrg from 'vue3-tree-org'
import App from './App.vue'
import { setupRouter } from './router'
import { handleMicroData } from '~/utils/micro'

import '@wyg/business-components/dist/style.css'
import '@unocss/reset/tailwind.css'
import 'uno.css'
import './styles/main.css'
import './styles/coverArco.less'
import 'vue3-tree-org/lib/vue3-tree-org.css'

// import '~/mockDIr/index.js'

async function setupApp() {
  const app = createApp(App)
  app.use(createPinia())
  await setupRouter(app)

  app.use(Vue3TreeOrg)
  app.mount('#crm-app')
  handleMicroData()
  getUserInfo()
  getTenant()
}
/** 获取当前登录用户信息 */
function getUserInfo() {
  const userInfoStore = useUserInfoStore()
  const token = useLocalStorage('token', '')
  if (token.value)
    userInfoStore.getUserInfo()
}
function getTenant() {
  const tenantStoreIns = tenantStore()
  tenantStoreIns.getTenant()
}
setupApp()
