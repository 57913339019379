<!-- 重新跟进商机弹窗 -->
<script setup lang="ts">
import SceneModal from './SceneModal.vue'
import { businessFollowFollowAgain } from '~/api/businessFollow'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 商机id */
  businessId: {
    type: String,
    default: '',
  },
  /** 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机 */
  businessType: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:visible', 'success'])
const _visible = ref(false)
watchEffect(() => {
  _visible.value = props.visible
})

async function handleOk() {
  const { data } = await businessFollowFollowAgain({
    businessId: props.businessId, // 商机id
    businessType: props.businessType, // 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机
  })
  if (data.value) {
    AMessage.success('操作成功')
    emit('success')
    emit('update:visible', false)
  }
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <SceneModal
    :visible="visible"
    scene="warning"
    title="是否重新跟进商机？"
    content="点击重新跟进后，商机状态变为跟进中，后续请到【我的商机】中查询"
    ok-text="重新跟进"
    @ok="handleOk"
    @cancel="handleCancel"
  />
</template>
