import { sendMicroMessage } from '~/utils/micro'
import { useUserInfoStore } from '~/stores/userInfo'

const env = import.meta.env

interface IJumpToOA {
  /** 签约记录ID */
  signId: string
}
interface IJumpToProject {
  businessId: string
  signId: string
}
interface IJumpToEnterprises {
  /** 线上 ONLINE，  线下 OFFLINE */
  type: string
  businessId: string
  signId: string
}

/** 跳 OA */
export function jumpToOA({ signId = '' }: IJumpToOA) {
  const envUrl = env.VITE_OA_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const userInfoStore = useUserInfoStore()
  const userInfo = userInfoStore.userInfo
  const entrySource = 'wygtech-crm' // 邀请来源-系统名称-如商机 前端参数传入
  const invitorId = userInfo.userId // 邀请人ID,即当前登录者
  sendMicroMessage('change_route', {
    app: '/app-oa',
    appname: 'appname-oa',
    path: '/hrAdministration/entryMg',
    query: { entrySource, invitorId, signId },
  },
  () => window.open(`${envUrl}?entrySource=${entrySource}&signId=${signId}`),
  )
}

/** 跳立项 */
export function jumpToProject({ businessId = '', signId = '' }: IJumpToProject) {
  const envUrl = env.VITE_PROJECT_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const appName = 'CRM'
  sendMicroMessage('change_route', {
    app: '/app-project',
    appname: 'appname-project',
    path: '/project/management/create-add',
    query: { appName, businessId, signId },
  },
  () => window.open(`${envUrl}/#/project/management/create-add?appName=${appName}&businessId=${businessId}&signId=${signId}`),
  )
}

/** 跳加盟中心 */
export function jumpToJoinEnterprises({ type = '', businessId = '', signId = '' }: IJumpToEnterprises) {
  const envUrl = env.VITE_JOIN_ENTERPRISE_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  sendMicroMessage('change_route', {
    app: '/app-franchise',
    appname: 'appname-franchise',
    path: '/sign/sign-online',
    query: { type, page: 'add', businessId, signId },
  },
  () => window.open(`${envUrl}/#/sign/sign-online?type=${type}&page=add&businessId=${businessId}&signId=${signId}`),
  )
}

/** 跳检索平台-工商信息 */
export function jumpToBidDetailEnterprise({ id = '', companyName = '' }) {
  const envUrl = env.VITE_BID_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const token = localStorage.getItem('token')
  const externalData = encodeURIComponent(JSON.stringify({ token }))
  window.open(`${envUrl}/company/detail?id=${id}&externalData=${externalData}`)
}
/** 跳检索平台-人才库信息 */
export function jumpToBidDetailTalent({ id = '', personName = '' }) {
  const envUrl = env.VITE_BID_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const token = localStorage.getItem('token')
  const externalData = encodeURIComponent(JSON.stringify({ token }))
  window.open(`${envUrl}/employee/detail?id=${id}&name=${personName}&externalData=${externalData}`)
}
/** 跳检索平台-项目库信息 */
export function jumpToBidDetailProject({ id = '', projectName = '' }) {
  const envUrl = env.VITE_BID_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const token = localStorage.getItem('token')
  const externalData = encodeURIComponent(JSON.stringify({ token }))
  window.open(`${envUrl}/bidding/bidding-detail?id=${id}&externalData=${externalData}`)
}

/** 跳检索平台企业列表 */
export function jumpToBidEnterprise(query = '') {
  const envUrl = env.VITE_BID_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const token = localStorage.getItem('token')
  const externalData = encodeURIComponent(JSON.stringify({ token }))
  const filter = encodeURIComponent(JSON.stringify({ companyName: query }))
  window.open(`${envUrl}/company/list?filter=${filter}&externalData=${externalData}`)
}
/** 跳检索平台人才列表 */
export function jumpToBidTalent(query = '') {
  const envUrl = env.VITE_BID_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const token = localStorage.getItem('token')
  const externalData = encodeURIComponent(JSON.stringify({ token }))
  const filter = encodeURIComponent(JSON.stringify({ personName: query }))
  window.open(`${envUrl}/employee/list?filter=${filter}&externalData=${externalData}`)
}
/** 跳检索平台项目列表 */
export function jumpToBidProject(query = '') {
  const envUrl = env.VITE_BID_URL
  if (!envUrl) {
    AMessage.warning('暂不支持此功能')
    return
  }
  const token = localStorage.getItem('token')
  const externalData = encodeURIComponent(JSON.stringify({ token }))
  const filter = encodeURIComponent(JSON.stringify({ projectName: query }))
  window.open(`${envUrl}/project/list?filter=${filter}&externalData=${externalData}`)
}
