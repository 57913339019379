<!-- 场景弹窗组件 -->
<script setup lang="ts">
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 场景类型，warnig 警告  */
  scene: {
    type: String,
    default: 'warning',
  },
  title: {
    type: String,
    default: '提示',
  },
  content: {
    type: String,
    default: '',
  },
  // 取消按钮文案
  cancelText: {
    type: String,
    default: '取消',
  },
  // 确定按钮文案
  okText: {
    type: String,
    default: '确定',
  },
})
const emit = defineEmits(['ok', 'cancel'])
const _visible = ref(false)
watchEffect(() => {
  _visible.value = props.visible
})

function handleOk() {
  emit('ok')
}
function handleCancel() {
  emit('cancel')
}
</script>

<template>
  <AModal
    :visible="_visible"
    :mask-closable="false"
    title-align="start"
    simple
    modal-class="wyg-scene-modal"
    :ok-text="props.okText"
    :cancel-text="props.cancelText"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template #title>
      <IconExclamationCircleFill v-if="props.scene === 'warning'" size="24" class="mr-16px color-#FA9E18" />
      {{ props.title }}
    </template>
    <div class="ml-39px">
      {{ props.content }}
    </div>
  </AModal>
</template>

<style lang='less'>
.wyg-scene-modal{
  .arco-modal-header{
    margin-bottom: 4px;
  }
  .arco-modal-footer{
    margin-top: 16px;
    text-align: right;
  }
  .arco-modal-body {
    padding:0!important;
  }
}
</style>
