import type {
  BusinessEmployeeRulePageReq,
  BusinessEmployeeRuleReq,
  BusinessFollowRuleReq,
  BusinessPlanAddBusinessReq,
  BusinessPlanAddCustomerReq,
  BusinessPlanCountReq,
  BusinessPlanDateListByTypeReq,
  BusinessPlanDateListReq,
  BusinessPlanDeletePlanReq,
  BusinessPlanPageReq,
  BusinessPlanPunchReq,
  BusinessPlanRecommendedReq,
  GengGaiPaiXuDuiXiang,
  GetDayConfigReq,
  MyBusinessPlanReq,
  Recommended2PlanReq,
  SaveDayConfigReq,
} from './_interfaces.ts'

/** 日程-添加商机 */
export function businessPlanAddBusiness(data: BusinessPlanAddBusinessReq) {
  return usePost<any>('/api/businessPlan/addBusiness', data)
}

/** 日程-添加客户 */
export function businessPlanAddCustomer(data: BusinessPlanAddCustomerReq) {
  return usePost<any>('/api/businessPlan/addCustomer', data)
}

/** 日历-获取有日程的日期list */
export function businessPlanBusinessPlanDateList(
  data: BusinessPlanDateListReq,
) {
  return usePost<any>('/api/businessPlan/businessPlanDateList', data)
}

/** 日程-排序 */
export function businessPlanChangeScore(data: GengGaiPaiXuDuiXiang) {
  return usePost<any>('/api/businessPlan/changeScore', data)
}

/** 任务数量统计 */
export function businessPlanCount(data: BusinessPlanCountReq) {
  return usePost<any>('/api/businessPlan/count', data)
}

/** 日程-删除任务 */
export function businessPlanDelete(data: BusinessPlanDeletePlanReq) {
  return usePost<any>('/api/businessPlan/delete', data)
}

/** 商机日程规则设置-分页查看员工规则设置 */
export function businessPlanGetBusinessEmployeeRule(
  data: BusinessEmployeeRulePageReq,
) {
  return usePost<any>('/api/businessPlan/getBusinessEmployeeRule', data)
}

/** 商机日程规则设置-查看商机流转规则设置 */
export function businessPlanGetBusinessFollowRule() {
  return usePost<any>('/api/businessPlan/getBusinessFollowRule')
}

/** 商机日程规则设置-查看公司通用规则设置 */
export function businessPlanGetBusinessTenantRule() {
  return usePost<any>('/api/businessPlan/getBusinessTenantRule')
}

/** 根据任务方案类型，返回后面拜访的日期 */
export function businessPlanGetDateListByTaskType(
  data: BusinessPlanDateListByTypeReq,
) {
  return usePost<any>('/api/businessPlan/getDateListByTaskType', data)
}

/** 获取明天任务设置状态 */
export function businessPlanGetDayConfig(data: GetDayConfigReq) {
  return usePost<any>('/api/businessPlan/getDayConfig', data)
}

/** 日程-定位列表 */
export function businessPlanLocateList(data: BusinessPlanPunchReq) {
  return usePost<any>('/api/businessPlan/locateList', data)
}

/** 查询我的日程 */
export function businessPlanMyPlanList(data: MyBusinessPlanReq) {
  return usePost<any>('/api/businessPlan/myPlanList', data)
}

/** 我的日程-分页 */
export function businessPlanMyPlanPageList(data: BusinessPlanPageReq) {
  return usePost<any>('/api/businessPlan/myPlanPageList', data)
}

/** 日程-分页 */
export function businessPlanPageList(data: BusinessPlanPageReq) {
  return usePost<any>('/api/businessPlan/pageList', data)
}

/** 日程-推荐商机并加入日程 */
export function businessPlanRecommended2Plan(data: Recommended2PlanReq) {
  return usePost<any>('/api/businessPlan/recommended2Plan', data)
}

/** 日程-推荐商机 */
export function businessPlanRecommendedBusiness(
  data: BusinessPlanRecommendedReq,
) {
  return usePost<any>('/api/businessPlan/recommendedBusiness', data)
}

/** 日程-推荐商机结果 */
export function businessPlanRecommendedResult(data: Recommended2PlanReq) {
  return usePost<any>('/api/businessPlan/recommendedResult', data)
}

/** 设置明天任务设置状态 */
export function businessPlanSaveDayConfig(data: SaveDayConfigReq) {
  return usePost<any>('/api/businessPlan/saveDayConfig', data)
}

/** 商机日程规则设置-员工任务设置 */
export function businessPlanSetBusinessEmployeeRule(
  data: BusinessEmployeeRuleReq,
) {
  return usePost<any>('/api/businessPlan/setBusinessEmployeeRule', data)
}

/** 商机日程规则设置-商机流转规则设置 */
export function businessPlanSetBusinessFollowRule(data: BusinessFollowRuleReq) {
  return usePost<any>('/api/businessPlan/setBusinessFollowRule', data)
}
