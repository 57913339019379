import type {
  BuMenXinXiRuCan,
  DeptListReq,
  DeptReq,
  ListEmployeeReq,
} from './_interfaces.ts'

/** 查询用户部门信息 */
export function deptAcquireDeptInfo(data: DeptReq) {
  return usePost<any>('/api/dept/acquireDeptInfo', data)
}

/** 查询部门列表 */
export function deptDeptList(data: DeptListReq) {
  return usePost<any>('/api/dept/deptList', data)
}

/** 查询租户下全量部门树 */
export function deptDeptTreeComplex() {
  return usePost<any>('/api/dept/deptTreeComplex')
}

/** 查询租户下部门员工树 */
export function deptDeptWithEmployees() {
  return usePost<any>('/api/dept/deptWithEmployees')
}

/** 查询当前部门及子部门信息 */
export function deptListDeptByParent() {
  return usePost<any>('/api/dept/listDeptByParent')
}

/** 部门及子部门下员工基本信息 */
export function deptListEmployee(data: ListEmployeeReq) {
  return usePost<any>('/api/dept/listEmployee', data)
}

/** 获取部门下员工基本信息 */
export function deptListEmployeeByDept(data: BuMenXinXiRuCan) {
  return usePost<any>('/api/dept/listEmployeeByDept', data)
}
