<!-- 重复的项目商机 -->
<script setup lang="ts">
import dayjs from 'dayjs'
import { businessAddSignBusinessProject, businessReceiveBusiness, businessVerifyRepeatBusiness } from '~/api/business'
import { yuanToWanyuan } from '~/utils/index'
import { subDate, textJoin } from '~/utils'

const props = defineProps({
  // 创建来源,必传  我的:myself   公海:open_sea
  createSource: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [] as any[],
  },
  /** 1点击“项目名称”下面的‘去查看’触发的本弹窗  2点击“项目编号”下面的‘去查看’触发的本弹窗  3点击保存按钮触发的本弹窗 */
  triggerType: {
    type: Number,
    default: 1,
  },
  /** 项目名称输入内容 */
  projectNameSearchStr: {
    type: String,
    default: '',
  },
  /** 项目编号输入内容 */
  projectCodeSearchStr: {
    type: String,
    default: '',
  },
  /** 当前是否是在签约 */
  isSign: {
    type: Boolean,
    default: false,
  },
  // 签约方式
  signType: {
    type: String,
    default: '',
  },
  signParamObj: {
    type: Object,
    default: () => {},
  },
  // 客户id
  customerId: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['success', 'pageJump'])

const listData = ref([]) as any
const projectNameQuery = ref('')
const enterpriseCodeQuery = ref('')

onMounted(() => {
  listData.value = props.list
  if (props.triggerType === 1) {
    projectNameQuery.value = props.projectNameSearchStr
  }
  else if (props.triggerType === 2) {
    enterpriseCodeQuery.value = props.projectCodeSearchStr
  }
  else {
    projectNameQuery.value = props.projectNameSearchStr
    enterpriseCodeQuery.value = props.projectCodeSearchStr
  }
})

async function getSimilarBiz() {
  const { data } = await businessVerifyRepeatBusiness({
    businessType: 'PROJECT',
    name: projectNameQuery.value,
    code: enterpriseCodeQuery.value,
  })
  if (data.value)
    listData.value = data.value?.list || []
}

/** 领取并创建 */
async function claimAndCreate(record: any) {
  // 客户签约商机，走新增逻辑的时候的重复列表
  if (props.isSign) {
    const { data } = await businessAddSignBusinessProject({
      ...record,
      step: 2, // 1需要验证 2直接新增
      createSource: props.createSource,
      businessReceiveSource: 'bigdata_search',
      businessType: 'PROJECT',
      customerId: props.customerId,
      customerType: props.signParamObj.customerType,
      signType: props.signType,
    })
    if (data.value) {
      AMessage.success('领取并创建成功')
      getSimilarBiz()
      emit('success')
      emit('pageJump', { businessId: data.value.businessId, signId: data.value.signId })
    }
  }
  else {
    const { data } = await businessReceiveBusiness({
      businessList: [record],
      businessReceiveSource: 'bigdata_search',
      businessType: 'PROJECT',
    })
    if (data.value) {
      const { successCnt } = data.value
      // 领取并创建成功
      if (+successCnt === 1) {
        AMessage.success('领取并创建成功')
        getSimilarBiz()
        emit('success')
      }
      else {
        AMessage.error('领取并创建失败')
      }
    }
  }
}

function renderTimetoYMD(data: string) {
  return data ? dayjs(data).format('YYYY-MM-DD') : ''
}

const columns: any[] = [
  {
    title: '项目名称',
    dataIndex: 'projectName',
    slotName: 'projectName',
    fixed: 'left',
    ellipsis: true,
    tooltip: true,
    width: 300,
  },
  {
    title: '项目阶段',
    dataIndex: 'projectType',
    width: 125,
  },
  {
    title: '地区',
    dataIndex: 'projectType',
    render: ({ record }) => textJoin('', record.biddingCorpProvince, record.biddingCorpCity, record.biddingCorpArea),
    width: 125,
  },
  {
    title: '招标单位',
    dataIndex: 'biddingCorpName',
    width: 200,
  },
  // {
  //   title: '招标单位地址',
  //   dataIndex: 'biddingCorpAddr',
  //   minWidth: 300,
  //   ellipsis: true,
  //   tooltip: true,
  // },
  {
    title: '公告发布时间',
    dataIndex: 'publishTime',
    width: 120,
    render: ({ record }) => subDate(record.publishTime),
  },
  { title: '招标单位电话', dataIndex: 'phone', width: 180 },
  // {
  //   title: '项目预算（万元）',
  //   dataIndex: 'projectBudget',
  //   slotName: 'projectBudget',
  //   width: 150,
  // },
  // {
  //   title: '项目阶段',
  //   dataIndex: 'projectType',
  //   width: 130,
  // },
  { title: '报名开始时间', dataIndex: 'signUpBeginTime', width: 120, render: ({ record }) => subDate(record.signUpBeginTime) },
  { title: '报名结束时间', dataIndex: 'signUpEndTime', width: 120, render: ({ record }) => subDate(record.signUpEndTime) },
  {
    title: '文件下载开始时间',
    dataIndex: 'fileDownloadStartTime',
    width: 180,
    render: ({ record }) => subDate(record.fileDownloadStartTime),
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        if (!b.fileDownloadStartTime)
          return -1
        const sortRes = dayjs(a.fileDownloadStartTime).diff(b.fileDownloadStartTime)
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '文件下载结束时间',
    dataIndex: 'fileDownloadEndTime',
    width: 180,
    render: ({ record }) => subDate(record.fileDownloadEndTime),
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        if (!b.fileDownloadEndTime)
          return -1
        const sortRes = dayjs(a.fileDownloadEndTime).diff(b.fileDownloadEndTime)
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '投标开始时间',
    dataIndex: 'biddingStartTime',
    width: 180,
    render: ({ record }) => subDate(record.biddingStartTime),
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        if (!b.biddingStartTime)
          return -1
        const sortRes = dayjs(a.biddingStartTime).diff(b.biddingStartTime)
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '投标结束时间',
    dataIndex: 'biddingEndTime',
    width: 180,
    render: ({ record }) => subDate(record.biddingEndTime),
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        if (!b.biddingEndTime)
          return -1
        const sortRes = dayjs(a.biddingEndTime).diff(b.biddingEndTime)
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '开标时间',
    dataIndex: 'biddingOpeningTime',
    width: 180,
    render: ({ record }) => subDate(record.biddingOpeningTime),
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        if (!b.biddingOpeningTime)
          return -1
        const sortRes = dayjs(a.biddingOpeningTime).diff(b.biddingOpeningTime)
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },

  // {
  //   title: '文件下载时间',
  //   slotName: 'fileDownloadTime',
  //   sortable: {
  //     sortDirections: ['ascend', 'descend'],
  //     sorter: (a: any, b: any, extra: any) => {
  //       if (!b.fileDownloadEndTime)
  //         return -1
  //       const sortRes = dayjs(a.fileDownloadStartTime).diff(b.fileDownloadStartTime)
  //       return extra.direction === 'ascend' ? sortRes : -sortRes
  //     },
  //   },
  // },
  // {
  //   title: '投标时间',
  //   dataIndex: 'biddingTime',
  //   slotName: 'biddingTime',
  //   sortable: {
  //     sortDirections: ['ascend', 'descend'],
  //     sorter: (a: any, b: any, extra: any) => {
  //       if (!b.biddingEndTime)
  //         return -1
  //       const sortRes = dayjs(a.biddingStartTime).diff(b.biddingStartTime)
  //       return extra.direction === 'ascend' ? sortRes : -sortRes
  //     },
  //   },
  // },
  // {
  //   title: '开标时间',
  //   dataIndex: 'biddingOpeningTime',
  // },
  // {
  //   title: '最近获取时间',
  //   dataIndex: 'created',
  //   width: 180,
  // },
  {
    title: '招标方式',
    dataIndex: 'biddingType',
    slotName: 'biddingType',
    width: 120,
  },
  {
    title: '项目行业',
    dataIndex: 'industryType',
    slotName: 'industryType',
    width: 160,
  },
  {
    title: '操作',
    dataIndex: 'opration',
    slotName: 'opration',
    width: 140,
    fixed: 'right',
  },
]
</script>

<template>
  <div class="mb-24px flex">
    <div class="flex items-center">
      <div class="whitespace-nowrap">
        项目名称：
      </div>
      <AInput v-model="projectNameQuery" placeholder="请输入" class="w-240px" allow-clear />
    </div>
    <div class="m-l-16px flex items-center">
      <div class="whitespace-nowrap">
        项目编号：
      </div>
      <AInput v-model="enterpriseCodeQuery" placeholder="请输入" class="w-240px" allow-clear />
    </div>
    <AButton class="ml-12px" type="primary" @click="getSimilarBiz">
      搜索
    </AButton>
  </div>
  <AAlert class="mb-16px border-none">
    <div class="color-#33363A">
      根据输入信息匹配到以下商机，可直接领取；仅展示前20条匹配结果，需要更精准匹配请输入完整项目名称或项目编号
    </div>
  </AAlert>
  <ATable
    :columns="columns"
    :data="listData"
    :pagination="false"
    :scroll="{
      x: 2000,
    }"
  >
    <template #projectBudget="{ record }">
      {{ yuanToWanyuan(record.projectBudget) }}
    </template>
    <template #fileDownloadTime="{ record }">
      {{ record.fileDownloadStartTime }}~{{ record.fileDownloadEndTime }}
    </template>
    <template #biddingTime="{ record }">
      {{ record.biddingStartTime }}~{{ record.biddingEndTime }}
    </template>
    <template #opration="{ record }">
      <AButton v-if="!+record.followerId" class="px-0 text-sm" type="text" @click="claimAndCreate(record)">
        领取并创建
      </AButton>
      <span v-else class="text-sm color-#adafb0">
        {{ `${record.followerName}已领取` }}
      </span>
    </template>
  </ATable>
</template>
