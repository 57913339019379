/** 招标方式下拉框 */
export function configGetBiddingTypeLabel() {
  return usePost<any>('/api/config/getBiddingTypeLabel')
}

/** 企业获奖等级下拉框 */
export function configGetEnterpriseAwardLabel() {
  return usePost<any>('/api/config/getEnterpriseAwardLabel')
}

/** 企业资质下拉框 */
export function configGetEnterpriseCertlabel() {
  return usePost<any>('/api/config/getEnterpriseCertlabel')
}

/** 企业所属行业下拉框 */
export function configGetEnterpriseIndustryLabel() {
  return usePost<any>('/api/config/getEnterpriseIndustryLabel')
}

/** 企业经营阶段下拉框 */
export function configGetEnterpriseOperationScopeLabel() {
  return usePost<any>('/api/config/getEnterpriseOperationScopeLabel')
}

/** 企业状态下拉框 */
export function configGetEnterpriseStatusLabel() {
  return usePost<any>('/api/config/getEnterpriseStatusLabel')
}

/** 企业类型下拉框 */
export function configGetEnterpriseTypelabel() {
  return usePost<any>('/api/config/getEnterpriseTypelabel')
}

/** 行业类型下拉框 */
export function configGetIndustryTypeLabel() {
  return usePost<any>('/api/config/getIndustryTypeLabel')
}

/** 人才所获奖项下拉框 */
export function configGetPersonAwardLabel() {
  return usePost<any>('/api/config/getPersonAwardLabel')
}

/** 人才证书下拉框 */
export function configGetPersonCertificateLabel() {
  return usePost<any>('/api/config/getPersonCertificateLabel')
}

/** 人员资质下拉框 */
export function configGetPersonCertlabel() {
  return usePost<any>('/api/config/getPersonCertlabel')
}

/** 人才职称下拉框 */
export function configGetPersonTitleLabel() {
  return usePost<any>('/api/config/getPersonTitleLabel')
}

/** 人才职称等级下拉框 */
export function configGetPersonTitleLevelLabel() {
  return usePost<any>('/api/config/getPersonTitleLevelLabel')
}

/** 日程规则 */
export function configGetPlanRules() {
  return usePost<any>('/api/config/getPlanRules')
}

/** 项目阶段下拉框 */
export function configGetProjectPhaseLabel() {
  return usePost<any>('/api/config/getProjectPhaseLabel')
}
