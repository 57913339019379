import { type LocationQueryRaw, stringifyQuery } from 'vue-router'
import { createFetch, isObject } from '@vueuse/core'
import type { MaybeRef, UseFetchOptions, UseFetchReturn } from '@vueuse/core'
import { notNullish } from '@antfu/utils'
import { handleCodeError } from '~/api/errorHandler'
import { login } from '~/api/_user'

const useRequest = createFetch({
  baseUrl: import.meta.env.VITE_API_URL,
  options: {
    async beforeFetch({ options, cancel }) {
      const token = useLocalStorage('token', '')
      if (!token.value) {
        const status = await login()
        if (!status)
          cancel()
      }

      options.headers = {
        ...options.headers,
        satoken: token.value,
        sourceSystem: import.meta.env.VITE_SOURCE_SYSTEM,
      }
      return { options }
    },
    afterFetch({ data, response }) {
      if (data.code !== 200) {
        handleCodeError(data.code, data.msg)
        data = null
      }
      else {
        data = notNullish(data.data) ? data.data : {}
      }
      return { data, response }
    },
    onFetchError({ data, error }) {
      console.error('onFetchError: ', data, error?.name, error?.message)
      if (error?.name !== 'AbortError')
        AMessage.error('网络错误，请稍后再试')
      data = undefined
      return { data, error }
    },
  },
  fetchOptions: { mode: 'cors' },
})

/**
 * 封装 get 请求
 * @param url 请求地址
 * @param query 请求参数
 */
export function useGet<T = unknown>(url: MaybeRef<string>, query?: MaybeRef<unknown>) {
  const _url = computed(() => {
    const _url = unref(url)
    const _query = unref(query)
    const queryString = (isObject(_query) ? stringifyQuery(_query as LocationQueryRaw) : _query) || ''
    return `${_url}${queryString ? '?' : ''}${queryString}`
  })

  return useRequest<T>(_url).json()
}

/**
 * 封装 post 请求
 * @param url 请求地址
 * @param payload 请求参数
 */
export function usePost<T = unknown>(
  url: MaybeRef<string>,
  payload?: MaybeRef<unknown>,
): UseFetchReturn<T> {
  return useRequest<T>(url).post(payload).json()
}

export function usePostWithHeader<T = unknown>(
  url: MaybeRef<string>,
  headers: UseFetchOptions,
  payload?: MaybeRef<unknown>,
): UseFetchReturn<T> {
  return useRequest<T>(url, headers).post(payload).json()
}

const useExport = createFetch({
  baseUrl: import.meta.env.VITE_API_URL,
  options: {
    async beforeFetch({ options, cancel }) {
      const token = useLocalStorage('token', '')
      if (!token.value) {
        const status = await login()
        if (!status)
          cancel()
      }

      options.headers = {
        ...options.headers,
        satoken: token.value,
        sourceSystem: import.meta.env.VITE_SOURCE_SYSTEM,
      }
      return { options }
    },
    async afterFetch({ data, response }) {
      if (data.size < 500) { // 这里size是随便写的 如果是错误消息转的blob会很短 为了兼容消息提示
        const text = await data.text()
        const json = JSON.parse(text)
        handleCodeError(json.code, json.msg)
        data = null
      }
      return { data, response }
    },
    onFetchError({ data, error }) {
      console.error('onFetchError: ', data, error?.name, error?.message)
      if (error?.name !== 'AbortError')
        AMessage.error('网络错误，请稍后再试')
      data = undefined
      return { data, error }
    },
  },
  fetchOptions: { mode: 'cors' },
})

export function usePostDownLoad<T = unknown>(
  url: MaybeRef<string>,
  payload?: MaybeRef<unknown>,
) {
  return useExport<T>(url).post(payload).blob()
}
