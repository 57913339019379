<!-- 新增跟进记录弹窗 -->
<script setup lang="ts">
import dayjs from 'dayjs'
import UploadBtn from './UploadBtn.vue'
import { businessContactList } from '~/api/businessContact'
import { businessFollowV2Follow } from '~/api/businessFollow'
import { businessPlanGetDateListByTaskType } from '~/api/businessPlan'
import type { PositionModal } from '~/components/gaodeMap/GaodeMap.vue'
import { contactTypeOptions, coopIntentionOptions, followUpWayOptions, getOptionLabel } from '~/utils/options'
import type { IOption } from '~/utils/options'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  // true 编辑，false 新建
  isEdit: {
    type: Boolean,
    default: false,
  },
  // 商机id（编辑需传）
  businessId: {
    type: String,
    default: '',
  },
  /** 商机类型  ENTERPRISE:企业商机  PERSON:人才商机  PROJECT:项目商机   */
  businessType: {
    type: String,
    default: '',
  },
  // 签约id
  signId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:visible', 'success'])

const baseUrl = import.meta.env.VITE_API_URL
// const uploadUrl = `${baseUrl}/api/file/uploadFile` // 文件上传地址

function getBusinessTypeByKey(str: string) {
  if (str === 'ENTERPRISE')
    return 1

  if (str === 'PERSON')
    return 2

  if (str === 'PROJECT')
    return 3
}

interface IFile {
  /** 文件名称 */
  name: string
  /** 上传组件内部生成的id */
  uid: string //
  /** 上传成功后的文件访问路径 */
  url: string
  /** 上传成功的文件id */
  id: string
}
interface IContactPersonOption extends IOption {
  name: string
}
const _visible = ref(false)
const contactPersonList = ref([] as IContactPersonOption[]) // 联系人列表
const addContactModalVisible = ref(false) // 新建联系人弹窗
const aMapModalVisible = ref(false) // 地图选点弹窗
const location = ref({} as PositionModal)
const date = ref()
const needSetPlanType = ref(-1) // 是否需要下次拜访

const formRef = ref()
const formData = reactive({
  contactsPerson: '', // 联系人
  followUpWay: '', // 跟进方式
  photos: '' as any, // 跟进照片
  // 所在地点
  address: '',
  content: '', // 沟通内容
  intention: '', // 合作意向@ts-ignore
  date: '',
  setPlanType: '' as any, // 设置拜访计划类型，0设置拜访日期，1按任务方案设置日期
  dateList: [] as any,
  // nextPlan:'' as any,  // 是否需要下次拜访，0不需要，1需要
})
// 是否是登门拜访
const isVisitAtDoor = computed(() => formData.followUpWay === 'VISIT' || formData.followUpWay === 'OFFLINE_MEETING')
const uploadResult = ref([] as IFile[])
// const satoken = localStorage.getItem('token') as string
onMounted(() => {
  getBusinessContactList()
  getBusinessTaskType()
})
/** 获取联系人列表 */
async function getBusinessContactList(query = '') {
  const { data } = await businessContactList({
    name: query,
    page: 1,
    size: 200,
    relId: props.businessId,
    relType: getBusinessTypeByKey(props.businessType),
  })
  if (data.value) {
    const d = data.value.records || []
    contactPersonList.value = d.map((item: any) => {
      const label = getOptionLabel(contactTypeOptions(), +item.type) // 电话/邮箱/微信号
      const value = item.value // 联系方式
      return {
        value: item.id,
        label: (label && value) ? `${item.name}（${label}:${value}）` : item.name,
        name: item.name,
      }
    })
  }
}

/** 根据任务方案类型，返回后面拜访的日期 */
async function getBusinessTaskType() {
  const { data } = await businessPlanGetDateListByTaskType({
    id: props.businessId,
    taskType: 2,
    type: props.businessType,
  })
  if (data.value)
    formData.dateList = data.value
}

/** 搜索联系人 */
function searchContactsPerson(query: string) {
  getBusinessContactList(query)
}
function addContactSuccess({ id, name, type, value }: any) {
  const defalutUserExistInList = contactPersonList.value.find(item => item.value === id)
  // 默认值不存在列表中且选中值是默认值
  if (!defalutUserExistInList) {
    const label = getOptionLabel(contactTypeOptions(), +type) // 电话/邮箱/微信号
    contactPersonList.value.push({
      value: id,
      label: (label && value) ? `${name}（${label}:${value}）` : name,
      name,
    })
  }
  formData.contactsPerson = id
}
/** 联系人弹窗下拉展示事件 */
function popupVisibleChange(visible: boolean) {
  if (visible)
    getBusinessContactList()
}
watchEffect(() => {
  _visible.value = props.visible
})
// 将跟进方式切换为非‘登门拜访’，则清空之前选的图片和位置信息
watch(isVisitAtDoor, (val) => {
  if (!val) {
    // uploadResult.value = []
    formData.address = ''
  }
})
watch(() => uploadResult, () => {
  // formData.photos = uploadResult.value?.length ? uploadResult.value : ''
  isVisitAtDoor.value && resetPhotosField()
}, { immediate: false, deep: true })

// 自定义上传
async function customRequest(option: any) {
  const { onProgress, onError, onSuccess, fileItem, name } = option
  const xhr = new XMLHttpRequest()
  if (xhr.upload) {
    xhr.upload.onprogress = function (event) {
      let percent
      if (event.total > 0) {
        // 0 ~ 1
        percent = event.loaded / event.total
      }
      onProgress(percent, event)
    }
  }
  xhr.onerror = function error(e) {
    onError(e)
  }
  xhr.onload = function onload() {
    if (xhr.status < 200 || xhr.status >= 300)
      return onError(xhr.responseText)
    const data = JSON.parse(xhr.response)
    if (data.code === 200)
      onSuccess(data)
    else
      onError('上传失败')
  }
  const formData = new FormData()
  formData.append(name || 'file', fileItem.file)
  formData.append('relId', props.businessId)
  formData.append('relType', '1') // 业务类型 1：跟进 2：excel导入
  xhr.open('post', `${baseUrl}/api/file/uploadFile`, true)
  xhr.setRequestHeader('satoken', localStorage.getItem('token') as string)
  xhr.send(formData)

  return {
    abort() {
      xhr.abort()
    },
  }
}

/** 图片上传成功 */
function uploadSuccess(res: any) {
  const response = res.response
  const { fileUrl, id } = response.data
  uploadResult.value.push({
    name: res.name,
    uid: res.uid,
    id,
    url: fileUrl,
  })
}

function beforeUpload(file: any) {
  const size = file.size / 1024 / 1024 // 单位 M
  const max = 10
  if (size > max) {
    AMessage.error(`文件大小不能超过${max}M`)
    return false
  }
  else {
    return true
  }
}

function beforeRemoveFile(fileItem: any): any {
  const { uid } = fileItem
  const idx = uploadResult.value.findIndex(item => item.uid === uid)
  uploadResult.value.splice(idx, 1)
  return true
}

function mapOk(position: PositionModal) {
  location.value = position
  formData.address = position.address as string
  resetAddressField()
}
function resetAddressField() {
  formRef?.value?.validateField('address')
}
function resetPhotosField() {
  formRef?.value?.validateField('photos')
}
function handleOk() {
  formRef.value.validate().then(async (err: any) => {
    if (!err) {
      const dateArr = formData.dateList?.filter((f: any) => f)
      // if (formData.setPlanType === 1 && !dateArr.length) {
      //   AMessage.error('至少要选择一个回访日期')
      //   return
      // }
      const contactPerson = contactPersonList.value.find((item: any) => item.value === formData.contactsPerson)
      const { data } = await businessFollowV2Follow({
        contactPersonId: formData.contactsPerson, // 联系人id
        contactPersonName: contactPerson?.name || '', // 联系人名称
        addr: location.value.address, // 地址
        businessId: props.businessId,
        businessType: props.businessType,
        content: formData.content,
        coordinate: isVisitAtDoor.value ? `${location.value.lng},${location.value.lat}` : '', // 经度，纬度
        fileUrlList: isVisitAtDoor.value ? (formData.photos || []).map((item: any) => item.url) : [], // 图片文件id列表
        followResult: formData.intention, // 跟进结果
        followType: formData.followUpWay, // 跟进方式
        date: formData.date,
        setPlanType: needSetPlanType.value === -1 ? -1 : formData.setPlanType,
        dateList: dateArr,
      })
      if (data.value) {
        AMessage.success('操作成功')
        emit('success')
        handleCancel()
      }
    }
  })
}
function handleCancel() {
  emit('update:visible', false)
}

function changenextPlan(val: any) {
  needSetPlanType.value = val
}
function changeDateType(val: any) {
  formData.setPlanType = val
}
function dateChange(val: any, idx: any) {
  formData.dateList[idx] = val
}
</script>

<template>
  <AModal width="735px" :body-style="{ maxHeight: '550px', overflow: 'auto' }" :visible="_visible" :mask-closable="false" title-align="start" @ok="handleOk" @cancel="handleCancel">
    <template #title>
      填写跟进记录
    </template>
    <AForm ref="formRef" :model="formData" auto-label-width layout="vertical">
      <div class="group-label">
        跟进结果
      </div>
      <AFormItem field="intention" label="合作意向" :rules="[{ required: true, message: '请选择合作意向' }]">
        <ARadioGroup v-model="formData.intention" :options="coopIntentionOptions()" />
      </AFormItem>
      <div class="group-label">
        跟进情况
      </div>
      <AFormItem
        field="contactsPerson" label="联系人" :hide-asterisk="formData.intention === 'UNCONTACTED'" :rules="[{
          required: true,
          validator: (value: string, cb: Function) => {
            if (formData.intention !== 'UNCONTACTED' && !formData.contactsPerson) {
              cb('请选择联系人');
            }
            else {
              cb();
            }
          },
        }]"
      >
        <ASelect
          v-model="formData.contactsPerson"
          :trigger-props="{ updateAtScroll: true }"
          :filter-option="false"
          placeholder="请选择"
          class="w-320px"
          allow-search
          show-footer-on-empty
          @popup-visible-change="popupVisibleChange"
          @search="searchContactsPerson"
        >
          <AOption v-for="item in contactPersonList" :key="item.value" :value="item.value">
            {{ item.label }}
          </AOption>
          <template #footer>
            <div>
              <AButton type="text" @click="addContactModalVisible = true">
                <IconPlus /> 新建联系人
              </AButton>
            </div>
          </template>
        </ASelect>
      </AFormItem>
      <AFormItem
        field="followUpWay" label="跟进方式" :hide-asterisk="formData.intention === 'UNCONTACTED'" :rules="[{
          required: true,
          validator: (value: string, cb: Function) => {
            if (formData.intention !== 'UNCONTACTED' && !formData.followUpWay) {
              cb('请选择跟进方式');
            }
            else {
              cb();
            }
          },
        }]"
      >
        <ARadioGroup v-model="formData.followUpWay" :options="followUpWayOptions()" />
      </AFormItem>
      <AFormItem
        v-if="isVisitAtDoor" field="photos" :hide-asterisk="formData.intention === 'UNCONTACTED'" label="跟进照片" :rules="[{
          required: true,
          validator: (value: string, cb: Function) => {
            if (formData.intention !== 'UNCONTACTED' && !formData.photos.length) {
              cb('请选择文件');
            }
            else if (formData.intention !== 'UNCONTACTED' && formData.photos.some((item) => !item.url)) {
              cb('存在上传中或上传失败的文件');
            }
            else {
              cb();
            }
          },
        }]"
      >
        <UploadBtn v-model:fileList="formData.photos" class="leading-22px" accept="image/*" :limit="9" />
      </AFormItem>
      <AFormItem
        v-if="isVisitAtDoor" field="address" label="所在地点" :hide-asterisk="formData.intention === 'UNCONTACTED'" :rules="[{
          required: true,
          validator: (value: string, cb: Function) => {
            if (formData.intention !== 'UNCONTACTED' && !formData.address) {
              cb('请选择地点');
            }
            else {
              cb();
            }
          },
        }]"
      >
        <AInput v-model="formData.address" class="w-320px" placeholder="请地图上选择地点" readonly @click="aMapModalVisible = true" />
      </AFormItem>
      <AFormItem field="content" label="沟通内容" :rules="[{ required: true, message: '请输入沟通内容' }]">
        <ATextarea v-model="formData.content" placeholder="请输入" :max-length="500" :auto-size="{ minRows: 2, maxRows: 6 }" class="w-320px" show-word-limit />
      </AFormItem>

      <AFormItem field="needSetPlanType" label="下次拜访计划" class="mb-1px">
        <div class="mr-12px">
          是否需要下次拜访
        </div>
        <ARadioGroup v-model="needSetPlanType" class="mt-6px" :disabled="signId ? true : false" @change="changenextPlan">
          <ARadio :value="1">
            需要
          </ARadio>
          <ARadio :value="-1">
            不需要
          </ARadio>
        </ARadioGroup>
      </AFormItem>
      <AFormItem v-if="needSetPlanType === 1" field="setPlanType" label="">
        <ARadioGroup v-model="formData.setPlanType" class="mt-6px" :disabled="signId ? true : false" @change="changeDateType">
          <ARadio :value="0">
            设置拜访日期
          </ARadio>
          <ARadio :value="1">
            按任务方案设置日期
          </ARadio>
        </ARadioGroup>
      </AFormItem>
      <AFormItem v-if="formData.setPlanType === 0" field="date" label="" hide-asterisk>
        <ADatePicker
          v-model="date"
          class="w-260px"
          value-format="YYYY-MM-DD"
          allow-clear
          :show-now-btn="false"
          :disabled-date="(current) => dayjs(current).isBefore(dayjs().subtract(0, 'day'))"
          @change="(value) => formData.date = value"
        />
      </AFormItem>
      <AFormItem v-if="formData.setPlanType === 1" field="date" label="">
        <div class="flex flex-col">
          <div class="mb12px flex">
            <p class="w80px">
              方案说明：
            </p>前3次每周跟进1次，后3次每月跟进1次
          </div>
          <div class="flex">
            <p class="w80px shrink-0">
              回访日期：
            </p>
            <div class="flex flex-wrap">
              <div v-for="(item, index) in formData.dateList" :key="index" class="mb12px mr12px">
                <a-date-picker
                  :default-value="item"
                  class="w-160px"
                  :show-now-btn="false"
                  :disabled-date="(current) => dayjs(current).isBefore(dayjs().subtract(0, 'day'))"
                  @change="checked => dateChange(checked, index)"
                />
              </div>
            </div>
          </div>
        </div>
      </AFormItem>
    </AForm>
  </AModal>
  <!-- 新建联系人弹窗 -->
  <ModalAddContact
    v-if="addContactModalVisible"
    v-model:visible="addContactModalVisible"
    :rel-id="props.businessId"
    :rel-type="getBusinessTypeByKey(props.businessType)"
    @success="addContactSuccess"
  />
  <!-- 地图选点弹窗 -->
  <GaodeMapModal v-if="aMapModalVisible" v-model:visible="aMapModalVisible" :default-position="location" @ok="mapOk" />
</template>

<style lang='less' scoped>
.group-label{
  position:relative;
  margin-bottom: 16px;
  padding-left:11px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  &:before{
    display: block;
    content:'';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 12px;
    background: #376AF6;
    border-radius: 2px;
  }
}
</style>
