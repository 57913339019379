import OSS from 'ali-oss'
import { fileGetAliyunStsSign, fileGetOssConfig } from '~/api/file'

interface IOssConfig {
  /** yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou */
  region: string
  /** 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret） */
  accessKeyId: string
  accessKeySecret: string
  /** 从STS服务获取的安全令牌（SecurityToken） */
  stsToken: string
  bucket: string
  /** 如："MSG/20230911" */
  // dir: string;
  /** 如："oss-cn-hangzhou.aliyuncs.com" */
  endpoint: string
}

interface IUploadRes {
  /** 文件地址 */
  fileUrl?: string
  /** 文件名称 */
  fileName?: string
  /** 文件在oss中的名称 */
  fileNameInOss?: string
}

/** 上传文件到阿里 oss
 *  @param file 选择的文件对象
 *  @param options.progress  获取上传进度的回调函数  参考文档： https://help.aliyun.com/zh/oss/developer-reference/multipart-upload-3?spm=a2c4g.11186623.0.i35
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export async function ossUpload(file: File, options?: { progress?: Function }): Promise<IUploadRes> {
  const progressCb = options?.progress || function () {}
  try {
    const { client, config } = await getOssClient()
    const ossFileName = getOssFileName(file.name) // abc_1694421788368.png
    const fileSize = file.size / 1024 / 1024 // 文件大小 M

    const options = {
      headers: {
        'Content-Type': getContentType(file.name.split('.').pop() || ''),
        'mime': 'image/jpg',
      },
      progress: progressCb,
    }

    const buffer = await transferFileToBuffer(file)
    const result
      = fileSize > 100
        ? await client.multipartUpload(ossFileName, buffer, options) // 大于 100M 使用分片上传
        : await client.put(ossFileName, buffer, options) // 普通上传

    if (result?.res?.status === 200) {
      const ossUrl = (result?.res?.requestUrls || [])[0]
      return {
        fileUrl: ossUrl.split('?')[0], // 分片上传的时候路径上有问号，需要去除 格式如：http://wygtech-shangyun-test.oss-cn-hangzhou.aliyuncs.com/%E5%A4%A7%E5%B0%8F9.46MB_1704786356469.png?uploadId=55D49FADDD824F838A2E7D18AE0B8B56
        fileName: file.name,
        fileNameInOss: result.name,
      }
    }
  }
  catch (error) {
    throw new Error('上传失败')
  }
  return {}
}

/** 设置 contentType, 控制上传成功后的地址用浏览器打开是走预览还是走下载 */
function getContentType(ext: string) {
  switch (ext) {
    // 设置图片的 contentType，保证上传后的图片能正常预览
    case 'png':
    case 'jpg':
    case 'jpeg':
      return 'image/jpg'
    // 由于 txt 预览会乱码，所以这里设置值为 application/octet-stream，走下载
    case 'txt':
      return 'application/octet-stream'
    // 不设置contentType，走浏览器的默认行为，能预览则预览(如：pdf,mp4),不能预览则自动下载(如：doc,ppt)
    default:
      return ''
  }
}

/** 获取 oss 预览或下载地址
 * 官方文档 https://help.aliyun.com/zh/oss/developer-reference/preview-or-download-an-object?spm=a2c4g.11186623.0.0.5aee5325ZsteFg
 */
export async function getPreviewOrDownloadUrl(fileUrl: string, originFileName: string) {
  try {
    const { client, config } = await getOssClient()

    // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名为originName。
    const downloadResponse = {
      'content-disposition': `attachment; filename=${encodeURIComponent(originFileName)}`,
    }
    const fileDir = parseFileDirFromUrl(fileUrl)
    const downloadUrl = client.signatureUrl(fileDir, { response: downloadResponse })
    const previewUrl = client.signatureUrl(fileDir)

    return { downloadUrl, previewUrl }
  }
  catch (error) {
    AMessage.error('链接获取失败')
    return { downloadUrl: '', previewUrl: '' }
  }
}

/** 获取 oss 上传配置 */
async function getConfig(): Promise<IOssConfig> {
  const data1 = await fileGetAliyunStsSign()
  // console.log(data1.data.value, 'data1')

  const data2 = await fileGetOssConfig()
  return {
    region: 'oss-cn-hangzhou',
    accessKeyId: data1.data.value.accessKeyIdSts || '',
    accessKeySecret: data1.data.value.secretKeyIdSts || '',
    stsToken: data1.data.value.securityToken || '',
    bucket: data2.data.value.bucketName || '',
    endpoint: data2.data.value.vendpoint || '',
  }
}

/** 获取 oss client */
async function getOssClient() {
  const config = await getConfig()
  const client = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: config?.accessKeyId,
    accessKeySecret: config?.accessKeySecret,
    stsToken: config?.stsToken,
    bucket: config?.bucket,
  })
  return { client, config }
}

/** 上传到oss的文件名称(保证唯一性) */
function getOssFileName(originFileName: string) {
  if (originFileName) {
    const lasDotIdx = originFileName.lastIndexOf('.')
    const fileNameWithoutExt = originFileName.slice(0, lasDotIdx) // 不包含文件后缀的文件名
    const ext = originFileName.split('.').pop() || '' // 文件后缀
    return `${fileNameWithoutExt}_${new Date().getTime()}.${ext}` // 加个时间戳
  }
  else {
    return ''
  }
}

/** 通过文件url, 解析出文件路径
 * 如：将 https://wygsy-shangyun-test.oss-cn-hangzhou.aliyuncs.com/MSG/20230919/%E5%BD%95%E5%83%8F_1695087628393.mp4
 * 处理成： /MSG/20230919/录像_1695087628393.mp4
 */
function parseFileDirFromUrl(fileUrl: string) {
  if (!fileUrl)
    return ''

  const url = decodeURI(fileUrl)
  return url.replace(/https?.*.(com|cn|edu|uk)/, '')
}

// 将文件处理成buffer, 解决测试环境报错 "Must provide Buffer/Blob/File for put."
function transferFileToBuffer(file: File) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new window.FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = async (e: any) => {
        const buffer = new OSS.Buffer(e.target.result)
        resolve(buffer)
      }
    }
    catch (error) {
      reject(new Error('上传失败'))
    }
  })
}
