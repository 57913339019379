<script setup lang="ts">
import { WMenu } from '@wyg/business-components'

const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:collapsed'])
const modelCollapsed = useVModel(props, 'collapsed', emit)

const router = useRouter()
const route = useRoute()

const menuStore = useMenuStore()
const menus = menuStore.list
const items = menuStore.all

const selectedKeys = ref<string[]>([])
watch(() => route.name, () => {
  const cur = route.name as string
  const curSplit = cur.split('-')
  if (cur.endsWith('-detail')) {
    const name = items.find(
      item => item.name?.startsWith(curSplit[0]
    + (curSplit.length > 2 ? `-${curSplit[1]}` : '')))?.name
    if (name)
      selectedKeys.value = [name]
  }
  else {
    selectedKeys.value = [cur]
  }
}, { immediate: true })

const openKeys = ref<string[]>([])
watch(() => route.name, () => {
  for (let i = 0, length = menus.length; i < length; i++) {
    if (menus[i].children?.find((item: any) => item.name === route.name))
      return openKeys.value = Array.from(new Set(openKeys.value.concat(String(i))))
  }
}, { immediate: true })

function goto(name: string) {
  const timestamp = new Date().getTime()
  router.push({ name, query: { t: timestamp } })
}
</script>

<template>
  <WMenu
    :menu-collapse="modelCollapsed"
    title="CRM系统"
    :menu-data="menus"
    :selected-keys="selectedKeys"
    :listener-route-change="() => {}"
    @collapse="val => modelCollapsed = val"
    @go="(val: any) => goto(val.name)"
  />
</template>
