import dayjs from 'dayjs'

export interface IOption {
  value: string | number
  label: string
  count?: number
  children?: IOption[]
}
const optionsAll = { label: '全部', value: 'all' }

/**
 * 商机类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function businessTypeOptions(includeAll = false): IOption[] {
  const options = [
    { label: '企业', value: 'ENTERPRISE' },
    { label: '人才', value: 'PERSON' },
    { label: '项目', value: 'PROJECT' },
  ]
  if (includeAll)
    options.unshift({ label: '全部', value: 'POOL' })

  return options
}

/**
 * 推荐
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function recommendOptions(includeAll = false): IOption[] {
  const options = [
    { label: '今日推荐', value: 1 },
    { label: '历史推荐', value: 2 },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 企业资质数量
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function enterpriseCertificateAmountOptions(includeAll = false): IOption[] {
  const options = [
    { label: '0', value: ',0' },
    { label: '1-10', value: '1,10' },
    { label: '10-20', value: '10,20' },
    { label: '20以上', value: '20,' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 执业资格证数量/人员证书数量
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function practiceCertificateAmountOptions(includeAll = false): IOption[] {
  const options = [
    { label: '0', value: ',0' },
    { label: '1-50', value: '1,50' },
    { label: '50-100', value: '50,100' },
    { label: '100以上', value: '100,' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}
/**
 * 注册人员数量
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function registeredPersonAmountOptions(includeAll = false): IOption[] {
  const options = [
    { label: '0', value: ',0' },
    { label: '1-10', value: '1,10' },
    { label: '11-20', value: '11,20' },
    { label: '21-50', value: '21,50' },
    { label: '51-100', value: '51,100' },
    { label: '100以上', value: '100,' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 工程业绩数量/项目业绩数量
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function projectAchievementAmountOptions(includeAll = false): IOption[] {
  const options = [
    { label: '0', value: ',0' },
    { label: '1-20', value: '1,20' },
    { label: '20-100', value: '20,100' },
    { label: '100以上', value: '100,' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 是否分公司
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function isBranchOfficeOptions(): IOption[] {
  const options = [
    { label: '不是分公司', value: 0 },
    { label: '是分公司', value: 1 },
  ]
  return options
}

/**
 * 分公司数量
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function branchOfficeAmountOptions(includeAll = false): IOption[] {
  const options = [
    { label: '0', value: ',0' },
    { label: '1-20', value: '1,20' },
    { label: '20-100', value: '20,100' },
    { label: '100以上', value: '100,' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 个人业绩数量
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function personalAchievementAmountOptions(includeAll = false): IOption[] {
  const options = [
    { label: '无业绩', value: '0' },
    { label: '1-3', value: '1' },
    { label: '4-6', value: '2' },
    { label: '6以上', value: '3' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 人员风险
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function personnelRiskOptions(includeAll = false): IOption[] {
  const options = [
    { label: '有风险', value: '1' },
    { label: '无风险', value: '2' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 商机状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function businessStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '跟进中', value: 'follow' },
    { label: '已成交', value: 'sign' },
    { label: '无效', value: 'invalid' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 商机状态----新
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function newBusinessStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '全部', value: 'all' },
    { label: '跟进中', value: 'follow' },
    { label: '已签约', value: 'sign' },
    { label: '无效', value: 'invalid' },
    { label: '有效', value: 'valid' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 任务状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function taskStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '未开始', value: 'READY' },
    { label: '进行中', value: 'RUNNING' },
    { label: '已结束', value: 'FINISH' },
    { label: '已停用', value: 'STOP' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}
/**
 * 导入状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function importStatusOptions(includeAll = false, businessType: any): IOption[] {
  let options = []
  if (businessType === 'IMPORT') {
    options = [
      { label: '导入中', value: 1 },
      { label: '已结束', value: 2 },
      { label: '异常', value: 3 },
    ]
  }
  else {
    options = [
      { label: '导出中', value: 1 },
      { label: '已结束', value: 2 },
      { label: '异常', value: 3 },
    ]
  }
  // const options = [
  //   { label: '导入中', value: 1 },
  //   { label: '已结束', value: 2 },
  //   { label: '异常', value: 3 },
  // ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 导入类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function importTypeOptions(includeAll = false): IOption[] {
  const options = [
    { label: '导入', value: 'IMPORT' },
    { label: '导出', value: 'EXPORT' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 签约状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function signingStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '已签约', value: '1' },
    { label: '未签约', value: '2' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}
/**
 * 客户签约状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function customerSignStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '已签约', value: 'FINISHED' },
    { label: '未签约', value: 'PROGRESS' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 项目预算
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function projectBudgetOptions(includeAll = false): IOption[] {
  const options = [
    { label: '0-50万', value: '1' },
    { label: '50-200万', value: '2' },
    { label: '200-500万', value: '3' },
    { label: '500-1000万', value: '4' },
    { label: '1000万以上', value: '5' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}

/**
 * 项目时效
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function businessValidOptions(includeAll = false): IOption[] {
  const options = [
    { label: '有效', value: 1 },
    { label: '无效', value: 0 },
    { label: '无法识别', value: 2 },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 商机来源
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function businessSource(includeAll = false): IOption[] {
  const options = [
    { label: '商机推送', value: 'radar' },
    { label: '手动创建', value: 'manually' },
    { label: '检索查询', value: 'search' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 跟进方式
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function followUpWayOptions(includeAll = false): IOption[] {
  const options = [
    { label: '电话沟通', value: 'PHONE' },
    { label: '微信聊天', value: 'WECHAT' },
    { label: '登门拜访', value: 'VISIT' },
    { label: '线下会谈', value: 'OFFLINE_MEETING' },
    { label: '其它', value: 'OTHER' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 回访方式
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function reVisitOptions(includeAll = false): IOption[] {
  const options = [
    { label: '设置回访日期', value: 'HAND' },
    { label: '按回访方案设置回访日期', value: 'AUTO' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 合作意向
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function coopIntentionOptions(includeAll = false): IOption[] {
  const options = [
    { label: '高意向', value: 'HIGH' },
    { label: '中意向', value: 'MIDDLE' },
    { label: '低意向', value: 'LOW' },
    { label: '无意向', value: 'NONE' },
    { label: '意向不明', value: 'UNKNOWN' },
    { label: '未联系上', value: 'UNCONTACTED' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 学历
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function educationOptions(includeAll = false): IOption[] {
  const options = [
    { label: '大专', value: '大专' },
    { label: '大学本科', value: '大学本科' },
    { label: '硕士', value: '硕士' },
    { label: '博士', value: '博士' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 联系方式
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function contactTypeOptions(includeAll = false): IOption[] {
  const options = [
    { label: '电话', value: 1 },
    { label: '邮箱', value: 2 },
    { label: '微信号', value: 3 },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 客户标签
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function customerLabelOptions(includeAll = false): IOption[] {
  const options = [
    { label: '加盟', value: 'ENTERPRISE' },
    { label: '人才', value: 'TALENT' },
    { label: '项目', value: 'PROJECT' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 合作频率
 */
export function cooperateTimesListOptions(): IOption[] {
  const options = [
    { label: '首次合作', value: 1 },
    { label: '二次合作', value: 2 },
    { label: '三次合作', value: 3 },
    { label: '多次合作', value: 4 },
  ]
  return options
}
/**
 * 合作项目规模
 */
export function cooperateExtentListOptions(): IOption[] {
  const options = [
    { label: '大', value: '大' },
    { label: '中', value: '中' },
    { label: '小', value: '小' },
  ]
  return options
}
/**
 * 客户签约时间
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function signTimeOptions(includeAll = false): IOption[] {
  // dayjs.extend(relativeTime)
  const beforeThreeMonth = dayjs().subtract(3, 'month').format('YYYY-MM-DD 00:00:00')// 三个月前
  const beforeSixMonth = dayjs().subtract(6, 'month').format('YYYY-MM-DD 00:00:00')// 六个月前
  const beforeOneYarn = dayjs().subtract(1, 'year').format('YYYY-MM-DD 00:00:00')// 一年前
  const options = [
    { label: '3个月以内', value: `max,${beforeThreeMonth}` },
    { label: '3-6月', value: `${beforeThreeMonth},${beforeSixMonth}` },
    { label: '6月-1年', value: `${beforeSixMonth},${beforeOneYarn}` },
    { label: '1年以上', value: beforeOneYarn },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)
  return options
}

/**
 * 操作类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function fixtureTagOptions(includeAll = false): IOption[] {
  const options = [
    { label: '创建商机', value: 'BUSINESS_CREATE' },
    { label: '编辑信息', value: 'BUSINESS_EDIT' },
    { label: '添加联系人', value: 'CONTACT_ADD' },
    { label: '删除联系人', value: 'CONTACT_REMOVE' },
    { label: '变更跟进人', value: 'BUSINESS_MODIFIED_FOLLOWER' },
    { label: '不再跟进', value: 'BUSINESS_NOT_FOLLOW' },
    { label: '继续跟进', value: 'BUSINESS_FOLLOW' },
    { label: '重新跟进', value: 'BUSINESS_FOLLOW_AGAIN' },
    { label: '标记为无效', value: 'BUSINESS_MARK_INVALID' },
    { label: '签约', value: 'BUSINESS_MARK_DEAL' },
    { label: '关联客户', value: 'BUSINESS_LINK' },
    { label: '创建任务', value: 'BUSINESS_TASK_CREATE' },
    { label: '停用任务', value: 'BUSINESS_TASK_STOP' },
    { label: '提前结束任务', value: 'BUSINESS_TASK_EARLY_FINISH' },
    { label: '编辑任务', value: 'BUSINESS_TASK_MODIFY' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 签约类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function signTagOptions(includeAll = false): IOption[] {
  const options = [
    { label: '线下签约', value: 'JOIN_OFFLINE' },
    { label: '线上签约', value: 'JOIN_ONLINE' },
    { label: '入职', value: 'ONBOARDED' },
    { label: '劳务外包', value: 'ONBOARDED_OUT' },
    { label: '立项', value: 'APPROVAL' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 成交类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function dealTagOptions(includeAll = false): IOption[] {
  const options = [
    { label: '入职', value: 'ONBOARDED' },
    { label: '加盟', value: 'JOIN' },
    { label: '立项', value: 'APPROVAL' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 成交状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function dealStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '已完成', value: 'FINISHED' },
    { label: '进行中', value: 'PROGRESS' },
    { label: '撤销', value: 'REVOKE' },
    { label: '删除', value: 'DELETE' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

export function getOptionLabel(options: IOption[], value?: string | number | boolean) {
  return options.find(item => item.value === value)?.label
}

/**
 * 任务类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function taskTypeOptions(includeAll = false): IOption[] {
  const options = [
    { label: '地推', value: 'offline_marketing' },
  ]
  if (includeAll)
    options.unshift(optionsAll)

  return options
}
/**
 * 商机领取状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function receiveOptions(includeAll = false): IOption[] {
  const options = [
    { label: '已领取', value: '1' },
    { label: '未领取', value: '2' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 业绩绑定状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function getBindStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '已绑定', value: '1' },
    { label: '未绑定', value: '2' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
/**
 * 账号状态
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function getPersonalStatusOptions(includeAll = false): IOption[] {
  const options = [
    { label: '正常', value: 1 },
    { label: '停用', value: 0 },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}

/**
 * 驻地类型
 * @param includeAll 是否包含'全部'选项，默认false
 */
export function getResidentTypeOptions(includeAll = false): IOption[] {
  const options = [
    { label: '常驻', value: '2' },
    { label: '临时', value: '1' },
  ]
  if (includeAll)
    options.unshift(optionsAll as any)

  return options
}
