import type {
  OssStsTokenReq,
} from './_interfaces.ts'

/** 获取阿里云临时有效凭证 */
export function fileGetAliyunStsSign() {
  return useGet<any>('/api/file/getAliyunStsSign')
}

/** 获取oss 配置 */
export function fileGetOssConfig() {
  return useGet<any>('/api/file/getOssConfig')
}

/** 获取oss STS token */
export function fileGetSTSCredentials(data: OssStsTokenReq) {
  return usePost<any>('/api/file/getSTSCredentials', data)
}

/** 上传文件 */
export function fileUploadFile(data: {
  // 业务id
  relId?: string
  // 业务类型 1：跟进(放的是商机id) 2：excel导入 3:模板文件
  relType?: number
}) {
  return usePost<any>('/api/file/uploadFile', data)
}
