import type {
  CountCustomerBusinessNumReq,
  CustomerEnterpriseAddReq,
  CustomerEnterpriseUpReq,
  CustomerPersonAddReq,
  CustomerPersonCertificateReq,
  CustomerPersonTitleReq,
  CustomerPersonUpReq,
  PageCustomerEnterpriseReq,
  PageCustomerPersonIdReq,
  PageCustomerPersonReq,
  SouSuoQiYeKeHurequest,
  SouSuoRenCaiKeHurequest,
  VerifyRepeatCustomerReq,
  VerifyRepeatPersonCustomerReq,
} from './_interfaces.ts'

/** 企业客户新增 */
export function customerEnterpriseAdd(data: CustomerEnterpriseAddReq) {
  return usePost<any>('/api/customer/enterprise/add', data)
}

/** 计算商机数量 */
export function customerEnterpriseCountBusinessNum(
  data: CountCustomerBusinessNumReq,
) {
  return usePost<any>('/api/customer/enterprise/countBusinessNum', data)
}

/** 企业客户详情 */
export function customerEnterpriseDetail(data: { id?: string }) {
  return useGet<any>('/api/customer/enterprise/detail', data)
}

/** 企业客户分页列表 */
export function customerEnterprisePageList(data: PageCustomerEnterpriseReq) {
  return usePost<any>('/api/customer/enterprise/pageList', data)
}

/** 搜索企业客户(下拉框数据) */
export function customerEnterpriseSampleList(data: SouSuoQiYeKeHurequest) {
  return usePost<any>('/api/customer/enterprise/sampleList', data)
}

/** 企业客户编辑 */
export function customerEnterpriseUpdate(data: CustomerEnterpriseUpReq) {
  return usePost<any>('/api/customer/enterprise/update', data)
}

/** 校验重复商机 */
export function customerEnterpriseVerifyRepeatBusiness(
  data: VerifyRepeatCustomerReq,
) {
  return usePost<any>('/api/customer/enterprise/verifyRepeatBusiness', data)
}

/** 个人客户新增 */
export function customerPersonAdd(data: CustomerPersonAddReq) {
  return usePost<any>('/api/customer/person/add', data)
}

/** 新增执业资格证 */
export function customerPersonAddCertificate(
  data: CustomerPersonCertificateReq,
) {
  return usePost<any>('/api/customer/person/addCertificate', data)
}

/** 新增职称 */
export function customerPersonAddTitle(data: CustomerPersonTitleReq) {
  return usePost<any>('/api/customer/person/addTitle', data)
}

/** 计算商机数量 */
export function customerPersonCountBusinessNum(
  data: CountCustomerBusinessNumReq,
) {
  return usePost<any>('/api/customer/person/countBusinessNum', data)
}

/** 删除执业资格证 */
export function customerPersonDeleteCertificate(data: { id?: string }) {
  return useGet<any>('/api/customer/person/deleteCertificate', data)
}

/** 删除职称 */
export function customerPersonDeleteTitle(data: { id?: string }) {
  return useGet<any>('/api/customer/person/deleteTitle', data)
}

/** 个人客户详情 */
export function customerPersonDetail(data: { id?: string }) {
  return useGet<any>('/api/customer/person/detail', data)
}

/** 个人客户分页列表 */
export function customerPersonPageList(data: PageCustomerPersonReq) {
  return usePost<any>('/api/customer/person/pageList', data)
}

/** 查询客户id通过手机号 */
export function customerPersonQueryIdByPhone(data: PageCustomerPersonIdReq) {
  return usePost<any>('/api/customer/person/queryIdByPhone', data)
}

/** 搜索人才客户(下拉框数据) */
export function customerPersonSampleList(data: SouSuoRenCaiKeHurequest) {
  return usePost<any>('/api/customer/person/sampleList', data)
}

/** 编辑个人客户 */
export function customerPersonUpdate(data: CustomerPersonUpReq) {
  return usePost<any>('/api/customer/person/update', data)
}

/** 校验重复个人客户 */
export function customerPersonVerifyRepeatCustomer(
  data: VerifyRepeatPersonCustomerReq,
) {
  return usePost<any>('/api/customer/person/verifyRepeatCustomer', data)
}
