<!-- 任务状态标签 -->
<script setup lang="ts">
import { getOptionLabel, taskStatusOptions } from '~/utils/options'

const props = defineProps({
  status: {
    type: String,
    default: '',
  },
})
const statusText = computed(() => {
  return getOptionLabel(taskStatusOptions(), props.status)
})
</script>

<template>
  <div
    v-if="statusText"
    class="inline-block rounded-sm px-8px py-4px text-xs shrink-0"
    :class="[
      props.status === 'READY' ? 'color-#FA9E18 bg-#FEF5E8' : '',
      props.status === 'RUNNING' ? 'color-#376AF6 bg-#EBF0FE' : '',
      props.status === 'FINISH' ? 'color-#03D071 bg-#E6FAF1' : '',
      props.status === 'STOP' ? 'color-#FA4753! bg-#FEEDEE!' : '',
    ]"
  >
    {{ statusText }}
  </div>
</template>
