<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { Message } from '@arco-design/web-vue'
import loadAMap from './loadAMap'
import _bg from '~/assets/amap-label.png'

export interface PositionModal {
  /** 经度 */
  lng: string
  /** 纬度 */
  lat: string
  address?: string
  /** 国家 */
  country?: string
  /**  省份 */
  province?: string
  /** 市 */
  city?: string
  /** 区县 */
  area?: string
  /** 除省市区之外的街道的信息 */
  location?: string
}
const props = defineProps({
  containerId: {
    type: String,
    default: 'container',
  },
  modelValue: {
    type: Object,
    default: () => {},
  },
  // 默认地点
  defaultPosition: {
    type: Object as PropType<PositionModal>,
    default: () => ({
      lng: '',
      lat: '',
      address: '',
    }),
  },
  /** 是否展示搜索框 */
  showSearchInput: {
    type: Boolean,
    default: true,
  },
  /** 输入框提示 */
  placeholder: {
    type: String,
    default: '请输入地址',
  },
  /** 容器样式 */
  style: {
    type: Object,
    default: () => ({
      width: '100%',
      height: '500px',
    }),
  },
  /** 是否可点击地图 */
  enableClick: {
    type: Boolean,
    default: true,
  },
})
const emit = defineEmits(['update:modelValue', 'change'])
/** 默认定位地点 */
const _defaultPosition: PositionModal = {
  lng: '116.398559',
  lat: '39.906929',
  address: '北京市东城区东华门街道天安门广场',
  country: '中国', // 国家
  province: '北京市', // 省份
  city: '', // 市
  area: '东城区', // 区县
}

let _map = null as any
let Gaode = null as any
let driving = null as any
const query = ref('')
const form = reactive({
  lng: '', // 经度
  lat: '', // 纬度
  address: '',
  country: '', // 国家
  province: '', // 省份
  city: '', // 市
  area: '', // 区县
}) as PositionModal
onMounted(async () => {
  await initMap()
})
let count = 0
function initCenter() {
  if (count > 10) {
    console.error('地图加载失败')
    return
  }
  if (!_map) {
    setTimeout(() => {
      count++
      _map.initCenter([116.397428, 39.90923], true)
    }, 100)
  }
  else {
    count = 0
    _map.setZoomAndCenter(9, [116.397428, 39.90923], true)
    driving.clear()
  }
}
async function initMap() {
  const { lng, lat, address } = props.defaultPosition
  const _lng = lng || _defaultPosition.lng
  const _lat = lat || _defaultPosition.lat
  const _address = address || _defaultPosition.address
  query.value = _address || ''
  const key = '87275703b7e5a7c419c8dc49bf1c7e93'
  const safeKey = 'b7d733d2de784e30e5796c12d0eb986f'
  const loadParams = {
    containerId: props.containerId,
    center: [_lng, _lat],
    zoom: 9, // 放大比例
    resizeEnable: true,
    keyboardEnable: false,
  }
  const [maploaded, MapObj] = await loadAMap(key, safeKey, loadParams)
  _map = maploaded
  Gaode = MapObj
  driving = new Gaode.Driving({ map: _map })
  if (props.enableClick) {
    _map.on('click', (ev: any) => {
      // 触发事件的地理坐标，AMap.LngLat 类型
      const lnglat = ev.lnglat
      // 触发事件的像素坐标，AMap.Pixel 类型
      // const pixel = ev.pixel
      getAddress(lnglat)
      form.lng = ev.lnglat.getLng()
      form.lat = ev.lnglat.getLat()
    })
  }
}
function getAddress(lnglat: any) {
  _map.clearMap()
  const text = new Gaode.Text({})
  let address
  Gaode.plugin('AMap.Geocoder', () => {
    // 异步加载插件
    address = new Gaode.Geocoder()
    address.getAddress(lnglat, (status: string, res: any) => {
      if (status === 'complete' && res.info === 'OK') {
        text.setPosition(lnglat)
        text.setText(
          `${res.regeocode.formattedAddress}
          <div style="background: url(${_bg}) 0px 0px no-repeat; position: absolute; width: 11px; height: 10px; top: 25px; left: 10px; overflow: hidden;"></div>`,
        )
        text.setStyle({
          'background-color': 'rgb(238,93,91)',
          'color': '#fff',
        })
        text.setAnchor('bottom-left')
        _map.add(text)
        const { country, province, city, district, township, street, streetNumber } = res?.regeocode?.addressComponent
        form.country = country
        form.province = province
        form.city = city
        form.area = district
        form.address = res.regeocode.formattedAddress
        form.location = township + street + streetNumber
        query.value = form.address!
        emit('update:modelValue', form)
        emit('change', form)
      }
      else {
        Message.info('获取地址失败')
      }
    })
  })
}

function inputChange(val: string) {
  if (!val)
    emit('update:modelValue', '')
}
function searchQuery() {
  _map.clearMap()
  if (!query.value)
    return

  let address: any
  Gaode.plugin('AMap.AutoComplete', () => {
    address = new Gaode.AutoComplete()
    address.search(query.value, (status: string, res: any) => {
      // 存在查询结果
      if (status === 'complete' && res.info === 'OK')
        handleMarker(res)
      // 不存在查询结果
      else if (status === 'no_data')
        Message.info('获取地址失败')
      else
        Message.info('获取地址失败')
    })
  })
}
/** 将查询结果在地图上标注出来 */
function handleMarker(res: any) {
  const markers: any[] = []
  res.tips.forEach((item: any) => {
    if (item.location) {
      const marker = new Gaode.Marker({
        position: item.location,
      })
      marker.on('click', (ev: any) => {
        // 触发事件的地理坐标，AMap.LngLat 类型
        const lnglat = ev.lnglat
        // 触发事件的像素坐标，AMap.Pixel 类型
        // const pixel = ev.pixel
        getAddress(lnglat)
        form.lng = ev.lnglat.getLng()
        form.lat = ev.lnglat.getLat()
      })
      markers.push(marker)
    }
  })
  _map.add(markers)
  _map.setFitView(markers)
}

function drivingSearch(coordinateList: string[]) {
  if (count > 10) {
    console.error('地图加载失败')
    return
  }
  if (!Gaode) {
    setTimeout(() => {
      count++
      drivingSearch(coordinateList)
    }, 100)
    return
  }
  else {
    count = 0
  }
  const list = coordinateList.filter(i => i).map(i => i.split(','))
  if (list.length) {
    const first = list[0]
    const last = list[list.length - 1]
    const waypoints = []
    for (let i = 1; i < list.length - 1; i++)
      waypoints.push(new Gaode.LngLat(list[i][0], list[i][1]))
    driving.search(new Gaode.LngLat(first[0], first[1]), new Gaode.LngLat(last[0], last[1]), { waypoints },
      (status: string, result: any) => {
        if (status !== 'complete')
          console.error(`获取驾车数据失败：${result}`)
      })
  }
  else {
    driving.clear()
  }
}

defineExpose({
  defaultPosition: _defaultPosition,
  drivingSearch,
  initCenter,
})
</script>

<template>
  <div style="width: 100%;">
    <AInputSearch
      v-if="showSearchInput"
      v-model="query"
      :style="{ width: '100%' }"
      :placeholder="placeholder"
      button-text="搜索"
      search-button
      @change="inputChange"
      @search="searchQuery"
    />
    <div :id="containerId" :style="style" />
  </div>
</template>
