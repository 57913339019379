import type {
  JieChuQianYuerequest,
  KeHuQianYueJiLuFenYerequest,
  QianYueJiLuFenYerequest,
  QianYueRequest,
} from './_interfaces.ts'

/** 签约记录 */
export function signingCustomerPageList(data: KeHuQianYueJiLuFenYerequest) {
  return usePost<any>('/api/signing/customer/pageList', data)
}

/** 签约记录 */
export function signingPageList(data: QianYueJiLuFenYerequest) {
  return usePost<any>('/api/signing/pageList', data)
}

/** 解除关联 */
export function signingRemove(data: JieChuQianYuerequest) {
  return usePost<any>('/api/signing/remove', data)
}

/** 新增签约 */
export function signingSave(data: QianYueRequest) {
  return usePost<any>('/api/signing/save', data)
}
