import type { RouteLocationNormalizedLoaded } from 'vue-router'

export interface ITab {
  name: string
  title: string
}

export const useTabBarStore = defineStore('tabBar', () => {
  const router = useRouter()

  const visible = ref(true)

  const allTab = {
    'workbenche': '工作台',
    'dashboard': '仪表盘',
    // 'radar': '商机雷达',
    'business-search': '商机检索',
    'enterprise-my-list': '企业池-我的商机',
    'enterprise-public-list': '企业池-企业商机管理',
    'talent-my-list': '人才池-我的商机',
    'talent-public-list': '人才池-人才商机管理',
    'project-my-list': '项目池-我的商机',
    'project-public-list': '项目池-项目商机管理',
    'customer-my-list': '我的客户',
    'customer-all-list': '全部客户',
    'schedule-my': '我的日程',
    'performance-sy-my': '我的业绩',
    'performance-sy-all': '全部业绩',
    'schedule-employee': '每日工作管理',
    'daily-today-data': '数据统计',
    'schedule-settings': '日程设置',
    'task': '市场活动',
    'task-followed-detail': '我跟进的-详情',
    'settings': 'CRM设置',
    'rule-set': '商机分派规则设置',
    'import-task': '导入任务',
    'schedule-rule': '商机日程规则',
    'dispatch-rule': '自动分派规则',
    'region-management': '区域管理',
    'marketing-personnel': '市场人员管理',
    'target-settings': '目标设置',
    'goal-completion-statistics': '目标完成统计',
    'business-cloud-tenant-details': '商云租户明细',
  } as any

  const curTab = ref('')
  const cacheTabs = ref<ITab[]>([])
  const cacheRoutes = ref<any>({})

  function setCurTab(route: RouteLocationNormalizedLoaded) {
    const name = route.name as string
    curTab.value = name
    if (!Object.keys(allTab).includes(name))
      return

    const index = cacheTabs.value.findIndex(item => item.name === name)
    if (index === -1)
      cacheTabs.value.push({ name, title: allTab[name] })

    const { path, query, params } = route
    cacheRoutes.value[name] = { name, path, query, params }
  }

  function deleteTab(name: string) {
    const index = cacheTabs.value.findIndex(item => item.name === name)

    if (name === curTab.value) {
      curTab.value = (cacheTabs.value[index + 1] || cacheTabs.value[index - 1]).name
      router.push(cacheRoutes.value[curTab.value])
    }

    if (index > -1)
      cacheTabs.value.splice(index, 1)
  }

  return { visible, curTab, cacheTabs, cacheRoutes, setCurTab, deleteTab }
})
