<!-- 地图选点弹窗 -->
<script setup lang="ts">
import type { PositionModal } from '~/components/gaodeMap/GaodeMap.vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  defaultPosition: {
    type: Object as PropType<PositionModal>,
    default: () => ({
      lng: '',
      lat: '',
      address: '',
    }),
  },
})
const emit = defineEmits(['update:visible', 'ok'])
const mapRef = ref(null) as any

const _visible = ref(false)
const location = ref({
  lng: '',
  lat: '',
  address: '',
})
watchEffect(() => {
  _visible.value = props.visible
})

function handleOk() {
  const hasSelect = location.value.lat && location.value.lng // 地图选点的值
  const hasDefault = props.defaultPosition.lat && props.defaultPosition.lng
  if (!hasSelect && !hasDefault && !mapRef.value?.defaultPosition) {
    AMessage.error('请选择地点')
    return
  }
  // 取值逻辑：用户手动选的地点 或 传给当前弹窗组件的默认地点 或 地图组件的默认地点
  const value
  = hasSelect
    ? location.value
    : hasDefault
      ? props.defaultPosition
      : mapRef.value?.defaultPosition
  emit('ok', value)
  emit('update:visible', false)
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    :width="600"
    :visible="_visible"
    :mask-closable="false"
    title-align="start"
    title="地图选点"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <GaodeMap ref="mapRef" v-model="location" :default-position="defaultPosition" :style="{ width: '100%', height: '300px' }" />
  </AModal>
</template>
