<script setup lang="ts">
import bus from '~/utils/bus'
import { initBusinessSubscribe } from '~/api/init'
import { MicroEnv } from '~/utils/micro'

const collapsed = ref(false)

initBusinessSubscribe()

const router = useRouter()
const menuStore = useMenuStore()
const tabBarStore = useTabBarStore()
bus.on('change_route', (data: any) => {
  // eslint-disable-next-line no-console
  console.log('#【travel】 收到基座的change_route事件 =====', data)

  menuStore.visible = data.menu
  tabBarStore.visible = data.tabBar

  const path = data.path
  if (path)
    router.push({ path })
})
</script>

<template>
  <AConfigProvider size="medium">
    <ALayout :class="MicroEnv ? 'h-micro' : 'h-screen'">
      <ALayoutSider v-if="menuStore.visible" :width="208" :collapsed-width="56" :collapsed="collapsed">
        <TheMenu v-model:collapsed="collapsed" />
      </ALayoutSider>
      <ALayoutContent class="box-border flex flex-col bg-#EBEFF4 px-5 pb-4">
        <div v-if="tabBarStore.visible">
          <TheTabBar />
        </div>
        <div class="flex-1 overflow-auto bg-white">
          <RouterView v-slot="{ Component }">
            <KeepAlive :exclude="['import-task']">
              <component :is="Component" :key="$route.fullPath" />
            </KeepAlive>
          </RouterView>
        </div>
      </ALayoutContent>
    </ALayout>
  </AConfigProvider>
</template>

<style scoped>
.arco-layout-sider-collapsed .arco-menu-collapsed{
  width: 56px;
}
.h-micro{
  height: calc(100vh - 64px);
}
.menu-scrollbar-thumb{
  &::-webkit-scrollbar {
    /* 滚动条宽度 */
    width: 1px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb:hover,
  &::-webkit-scrollbar-thumb:active {
    background-color: transparent;
  }
}
</style>
