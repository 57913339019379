<script setup lang="ts">
import type { IOption } from '~/utils/options'
import { businessPageEnterpriseSampleList, businessPagePersonSampleList, businessPageProjectSampleList } from '~/api/business'
import { customerEnterpriseSampleList, customerPersonSampleList } from '~/api/customer'

const props = defineProps({
  // 商机类型 ENTERPRISE PERSON PROJECT
  businessType: {
    type: String,
    default: '',
  },
  // 当前是要获取客户列表还是商机列表： 'customer'获取客户列表，  'business' 获取商机列表
  type: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['change'])
const selectId = ref('') // 选择的已有客户
const customerType = ref('PERSON')
const customerOptions = ref([
  { label: '个人客户', value: 'PERSON' },
  { label: '企业客户', value: 'ENTERPRISE' },
])
const list = ref([] as IOption[]) //

onMounted(async () => {
  getData()
})

function getData(query = '') {
  const type = props.type
  // 获取已有客户列表
  if (type === 'customer') {
    getCustomerList(query)
  }
  // 获取已有商机列表
  else if (type === 'business') {
    if (props.businessType === 'ENTERPRISE')
      getEnterpriseBizList(query)
    if (props.businessType === 'PROJECT')
      getProjectBizList(query)
    if (props.businessType === 'PERSON')
      getTalentBizList(query)
  }
}

watch(customerType, () => {
  selectId.value = ''
  getCustomerList()
})

watch(selectId, () => {
  emit('change', selectId.value)
})

defineExpose({
  customerType,
})
function getCustomerList(query = '') {
  if (customerType.value === 'PERSON')
    getPersonalCustomerList(query)

  if (customerType.value === 'ENTERPRISE')
    getEnterpriseCustomerList(query)
}
/** 获取企业客户列表 */
async function getEnterpriseCustomerList(query = '') {
  const { data } = await customerEnterpriseSampleList({
    enterpriseName: query,
    page: 1,
    size: 200,
  })
  if (data.value) {
    const d = (data.value?.records || []) as any[]
    list.value = d.map((item) => {
      return {
        value: item.id,
        label: item.enterpriseName,
      }
    })
  }
}
/** 获取个人客户列表 */
async function getPersonalCustomerList(query = '') {
  const { data } = await customerPersonSampleList({
    personName: query,
    page: 1,
    size: 200,
  })
  if (data.value) {
    const d = (data.value?.records || []) as any[]
    list.value = d.map((item) => {
      return {
        value: item.id,
        label: item.personName,
      }
    })
  }
}
/** 获取企业商机列表 */
async function getEnterpriseBizList(query = '') {
  const { data } = await businessPageEnterpriseSampleList({
    enterpriseName: query,
    page: 1,
    size: 200,
  })
  if (data.value) {
    const d = (data.value?.records || []) as any[]
    list.value = d.map((item) => {
      return {
        value: item.id,
        label: item.enterpriseName,
      }
    })
  }
}
/** 获取项目商机列表 */
async function getProjectBizList(query = '') {
  const { data } = await businessPageProjectSampleList({
    name: query,
    page: 1,
    size: 200,
  })
  if (data.value) {
    const d = (data.value?.records || []) as any[]
    list.value = d.map((item) => {
      return {
        value: item.id,
        label: item.projectName,
      }
    })
  }
}
/** 获取人才商机列表 */
async function getTalentBizList(query = '') {
  const { data } = await businessPagePersonSampleList({
    name: query,
    page: 1,
    size: 200,
  })
  if (data.value) {
    const d = (data.value?.records || []) as any[]
    list.value = d.map((item) => {
      return {
        value: item.id,
        label: item.personName,
      }
    })
  }
}
</script>

<template>
  <div class="mb-8px text-sm color-#333333">
    {{ props.type === 'customer' ? "客户名称" : "商机名称" }}
  </div>
  <div v-if="props.type === 'customer'" class="flex">
    <ASelect v-model="customerType" placeholder="请选择" class="mr-4px w-220px">
      <AOption v-for="item in customerOptions" :key="item.value" :value="item.value">
        {{ item.label }}
      </AOption>
    </ASelect>
    <!-- 可选的客户列表 -->
    <ASelect v-model="selectId" placeholder="请选择" allow-search unmount-on-close :filter-option="false" @search="val => getData(val)">
      <AOption v-for="item in list" :key="item.value" :value="item.value">
        {{ item.label }}
      </AOption>
    </ASelect>
  </div>
  <template v-if="props.type === 'business'">
    <!-- 可选的商机列表 -->
    <ASelect v-model="selectId" placeholder="请选择" allow-search :filter-option="false" @search="val => getData(val)">
      <AOption v-for="item in list" :key="item.value" :value="item.value">
        {{ item.label }}
      </AOption>
    </ASelect>
  </template>
</template>
