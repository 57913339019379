import { phoneLandlineReg } from '~/utils/reg'

/** 手机号校验 */
export function validatePhone(value: string, callback: Function) {
  if (!value) {
    callback()
  }
  else {
    if (!phoneLandlineReg.test(value))
      callback('请按正确的电话格式输入')
    else
      callback()
  }
}
