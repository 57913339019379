import { cooperateTimesListOptions } from '~/utils/options'
import type { IOption } from '~/utils/options'
import { fieldTransfer } from '~/utils/index'
import {
  configGetBiddingTypeLabel,
  configGetEnterpriseAwardLabel,
  configGetEnterpriseCertlabel,
  configGetEnterpriseIndustryLabel,
  configGetEnterpriseOperationScopeLabel,
  configGetEnterpriseStatusLabel,
  configGetEnterpriseTypelabel,
  configGetIndustryTypeLabel,
  configGetPersonCertificateLabel,
  configGetPersonCertlabel,
  configGetPersonTitleLevelLabel,
  configGetProjectPhaseLabel,
} from '~/api/config'

const optionsAll: IOption[] = [{ label: '全部', value: 'all' }]

/**
 * @param fn 接口调用函数
 * @param options 一些额外的参数
 * @returns
 */
function getOptions(
  fn: Function,
  options?: {
    force?: boolean // 是否重新调接口拿
    includeAll?: boolean
    labelKey?: string
    valueKey?: string
    childrenKey?: string
  }) {
  let list: IOption[] = []
  return async function () {
    const labelKey = options?.labelKey || 'label'
    const valueKey = options?.valueKey || 'value'
    const childrenKey = options?.childrenKey || 'children'
    // 从缓存中拿
    if (list.length && !options?.force) {
      return options?.includeAll ? optionsAll.concat(list) : list
    }
    // 调接口拿
    else {
      const { data } = await fn()
      if (data.value) {
        const arr = data.value || []
        list = fieldTransfer(arr, { labelKey, valueKey, childrenKey })
        return options?.includeAll ? optionsAll.concat(list) : list
      }
      else {
        return []
      }
    }
  }
}
/**
 *  一级选项从接口取，二级选项自定义
 * @param fn 接口调用函数
 * @param getSecondOption 二级选项调用函数
 * @param options 一些额外的参数
 * @returns
 */
function getDefineOptions(fn: Function, getSecondOption: Function,
  options?: {
    force?: boolean // 是否重新调接口拿
    includeAll?: boolean
    labelKey?: string
    valueKey?: string
    childrenKey?: string
  }) {
  let list: IOption[] = []
  return async function () {
    const labelKey = options?.labelKey || 'label'
    const valueKey = options?.valueKey || 'value'
    const childrenKey = options?.childrenKey || 'children'
    // 从缓存中拿
    if (list.length && !options?.force) {
      return options?.includeAll ? optionsAll.concat(list) : list
    }
    // 调接口拿
    else {
      const { data } = await fn()
      if (data.value) {
        const arr = data.value || []
        const secondOption = getSecondOption()
        if (arr.length) {
          arr.map((item: any) => {
            item.children = secondOption
            return item
          })
        }
        list = fieldTransfer(arr, { labelKey, valueKey, childrenKey })
        return options?.includeAll ? optionsAll.concat(list) : list
      }
      else {
        return []
      }
    }
  }
}

/** 企业状态 */
export const getEnterpriseStatusOptions = getOptions(configGetEnterpriseStatusLabel)
/** 招标方式 */
export const getTenderWayOptions = getOptions(configGetBiddingTypeLabel)
/** 职称等级 */
export const getLevelOptions = getOptions(configGetPersonTitleLevelLabel)
/** 项目阶段 */
export const getProjectTypeOptions = getOptions(configGetProjectPhaseLabel)
/** 项目阶段合作数量 */
export const getProjectTypeCountOptions = getDefineOptions(configGetProjectPhaseLabel, cooperateTimesListOptions)
/** 行业类型 */
export const getIndustryOptions = getOptions(configGetIndustryTypeLabel)
/** 企业行业类型 */
export const getEnterpriseIndustryOptions = getOptions(configGetEnterpriseIndustryLabel)
/** 资质缺口 */
export const getLackCorpCerListOptions = getOptions(configGetIndustryTypeLabel)
/** 项目行业合作数量 */
export const getcooperateIndustryListOptions = getDefineOptions(configGetIndustryTypeLabel, cooperateTimesListOptions)
/** 企业资质 */
export const getCorpCertOptions = getOptions(configGetEnterpriseCertlabel)
/** 人员证书 */
export const getPersonCertOptions = getOptions(configGetPersonCertificateLabel)
/** 人员资质 */
export const getPersonCertlabel = getOptions(configGetPersonCertlabel)
/** 企业类型 */
export const getEnterpriseTypelabel = getOptions(configGetEnterpriseTypelabel)
/** 企业经营阶段 */
export const getEnterpriseOperationScopeLabel = getOptions(configGetEnterpriseOperationScopeLabel)
/** 所获奖项-企业 */
export const getEnterpriseAwardLabel = getOptions(configGetEnterpriseAwardLabel)
