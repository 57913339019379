<!-- eslint-disable @typescript-eslint/unified-signatures -->
<script setup lang="ts">
import type { PropType } from 'vue'
import { guid } from '~/utils/index'
import { ossUpload } from '~/utils/oss'

import paperClipIcon from '~/assets/photo.png'

interface FileItem {
  uid?: string
  /** 文件状态： INIT-选择文件后还未开始上传的状态 , 空-用户自己传进来的数据 */
  status?: 'INIT' | 'UPLOADING' | 'SUCCESS' | 'ERROR'
  /** 文件对象 */
  file?: File
  /** 文件名称 */
  name?: string
  /** 文件url */
  url?: string
  /** 当前上传进度 */
  progress?: number
}
const props = defineProps({
  fileList: {
    type: Object as PropType<FileItem[]>,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: [],
  },
  /** 接收的上传文件类型（非必传），具体参考 HTML标准  */
  accept: {
    type: String,
    default: '',
  },
  /** 是否多选 */
  multiple: {
    type: Boolean,
    default: true,
  },
  isDisabled: {
    type: Boolean,
    default: true,
  },
  /** 最大上传数量, -1：不限制数量 */
  limit: {
    type: Number,
    default: -1,
  },
  customeClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  // change 事件触发条件：文件新增、删除、单条数据状态/上传进度等更新
  (e: 'change', value: FileItem[]): void
  // delete 事件触发条件：文件删除
  (e: 'delete', value: FileItem): void
  // success 事件触发条件：某个文件上传成功
  (e: 'success', value: FileItem): void
  (e: 'update:fileList', value: FileItem[]): void
}>()

const theFileList = ref<FileItem[]>([])
const inputRef = ref<any>(null)

function handleCtClick() {
  inputRef.value && (inputRef.value.value = null) // 解决两次选择相同的文件，第二次选择不会触发@input事件问题
  inputRef.value?.click()
}

function onChange(e: any) {
  const files = e?.target?.files || []
  if (props.limit !== -1 && files.length + theFileList.value.length > props.limit) {
    AMessage.warning(`最多上传${props.limit}个文件`)
    return
  }
  const fileArr: File[] = Array.from(files)
  fileArr.forEach((item: File) => {
    const fileObj: FileItem = {
      status: 'INIT',
      uid: guid(),
      name: item.name,
      file: item,
      progress: 0, // 文件刚选择，进度为0
    }
    addFile(fileObj)
    upload(fileObj)
  })
}

async function upload(fileObj: FileItem) {
  fileObj.status = 'UPLOADING'
  fileObj.progress = 0
  try {
    const res = await ossUpload(fileObj.file!, {
      progress: (p: number) => {
        fileObj.progress = p
        updateItem(fileObj.uid!, fileObj)
      },
    })
    fileObj.status = 'SUCCESS'
    fileObj.name = res.fileName
    fileObj.url = res.fileUrl
    fileObj.progress = 1 // 上传完成，进度100%
    updateItem(fileObj.uid!, fileObj)
    emit('success', fileObj)
  }
  catch (error) {
    fileObj.status = 'ERROR'
    updateItem(fileObj.uid!, fileObj)
  }
}

/** 重试 */
function retry(uid: string) {
  const newfileObj = updateItem(uid, { status: 'UPLOADING', progress: 0 })
  upload({ ...newfileObj })
}

async function preview(url: string, name: string) {
  window.open(url)
}

/** 数据新增 */
function addFile(fileObj: FileItem) {
  theFileList.value.unshift(fileObj)
  emit('update:fileList', theFileList.value)
  emit('change', theFileList.value)
}

/** 数据删除 */
function deleteFile(uid: string) {
  const idx = theFileList.value.findIndex(item => item.uid === uid)
  const theDelete = theFileList.value[idx]
  theFileList.value.splice(idx, 1)
  emit('update:fileList', theFileList.value)
  emit('change', theFileList.value)
  emit('delete', theDelete)
}

/** 单条数据更新(如状态变化，进度变化) */
function updateItem(uid: string, val: FileItem): FileItem {
  const idx = theFileList.value.findIndex(item => item.uid === uid)
  const fileObj = theFileList.value[idx]
  theFileList.value[idx] = { ...fileObj, ...val }
  emit('update:fileList', theFileList.value)
  emit('change', theFileList.value)
  return theFileList.value[idx]
}

// watch(
//   () => props.fileList,
//   (val: FileItem[]) => {
//     theFileList.value = val.map((item) => {
//       return {
//         ...item,
//         uid: item.uid || guid(), // 用户 v-model 进来的值没有uid, 设置一个
//       }
//     })
//   },
//   {
//     deep: true,
//     immediate: true,
//   },
// )
</script>

<template>
  <div class="mb-6px w-full" :class="[props.customeClass]">
    <div class="cursor-pointer" @click="handleCtClick">
      <slot>
        <AButton v-if="isDisabled" class="mb-6px" type="primary">
          <icon-upload />
          上传图片
        </AButton>
      </slot>
    </div>
    <input ref="inputRef" type="file" hidden :accept="props.accept" :multiple="props.multiple" @input="onChange">

    <div>
      <div v-for="item in theFileList" :key="item.uid" class="mt-10px text-14px">
        <div
          class="flex items-center rounded-md py-10px pl-12px pr-16px" :class="[
            item.status === 'ERROR' ? 'bg-#FFEDEE' : 'bg-#F5F8FF',
          ]"
        >
          <div class="flex-1 overflow-hidden" :class="[item.status === 'ERROR' ? 'color-#fc3444' : 'color-#272C3E']">
            <span class="flex flex-1 items-center overflow-hidden">
              <img :src="paperClipIcon" class="mr-8px h-16px w-16px shrink-0">
              <span class="flex-1 truncate"> {{ item.name }} </span>
            </span>
          </div>
          <div class="ml-24px shrink-0">
            <icon-loading v-if="item.status === 'UPLOADING'" class="color-#aaa h-14px! w-14px!" />
            <span v-if="item.url" class="ml-12px cursor-pointer color-#4678FF" @click="preview(item.url!, item.name!)">预览</span>
            <span v-if="item.status === 'ERROR'" class="cursor-pointer color-#3E69FA" @click="retry(item.uid!)">重试</span>
            <span v-if="isDisabled" class="ml-12px cursor-pointer color-#4678FF" @click="deleteFile(item.uid!)">删除</span>
          </div>
        </div>
        <!-- 上传进度条，默认最小1% -->
        <div v-if="item.status === 'UPLOADING' || item.status === 'SUCCESS'">
          <AProgress
            color="#4678FF"
            class="the-progress"
            :percent="item.progress! < 0.01 ? 0.01 : item.progress"
            :show-text="false"
            size="small"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.the-progress {
  float: left; // 去除间隔
  :deep(.arco-progress-line) {
    height: 1.5px !important; // 进度条高度
  }
}
</style>
