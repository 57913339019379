<!-- 重复的项目商机 -->
<script setup lang="ts">
import { businessAddSignBusinessPerson, businessReceiveBusiness, businessVerifyRepeatBusiness } from '~/api/business'
import { customerPersonPageList } from '~/api/customer'
import { textJoin } from '~/utils'

const props = defineProps({
  // 创建来源,必传  我的:myself   公海:open_sea
  createSource: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [] as any[],
  },
  /** 1点击“姓名”下面的‘去查看’触发的本弹窗  2点击“手机号”下面的‘去查看’触发的本弹窗  3点击保存按钮触发的本弹窗 */
  triggerType: {
    type: Number,
    default: 1,
  },
  /** 姓名输入内容 */
  nameSearchStr: {
    type: String,
    default: '',
  },
  /** 手机号输入内容 */
  telephoneSearchStr: {
    type: String,
    default: '',
  },
  /** business 商机  customer 客户 */
  type: {
    type: String,
    default: 'business',
  },
  /** 是否显示操作列 */
  showActionColumn: {
    type: Boolean,
    default: false,
  },
  /** 当前是否是在签约 */
  isSign: {
    type: Boolean,
    default: false,
  },
  // 签约方式
  signType: {
    type: String,
    default: '',
  },
  signParamObj: {
    type: Object,
    default: () => {},
  },
  // 客户id
  customerId: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['success', 'associationCustomer', 'pageJump'])
const columns: any[] = [
  {
    title: '姓名',
    dataIndex: 'personName',
    slotName: 'personName',
    fixed: 'left',
    ellipsis: true,
    tooltip: true,
    width: 120,
  },
  {
    title: '电话',
    dataIndex: 'phone',
    width: 125,
  },
  {
    title: '省市区',
    dataIndex: 'addr',
    width: 300,
    ellipsis: true,
    tooltip: true,
    render: ({ record }) => textJoin('', record.province, record.city, record.area),
  },
  {
    title: '四库证书数量',
    dataIndex: 'skCertificateCnt',
    width: 140,
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.skCertificateCnt - +b?.skCertificateCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  {
    title: '个人业绩数量',
    dataIndex: 'achievementCnt',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.achievementCnt - +b?.achievementCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
    width: 150,
  },
  {
    title: '职称',
    dataIndex: 'title',
    width: 130,
  },
  {
    title: '执业资格证',
    dataIndex: 'certificateName',
    ellipsis: true,
    tooltip: true,
  },
  { title: '注册单位', dataIndex: 'registeredCompanyList', ellipsis: true, tooltip: true },
  { title: '证书编号', dataIndex: 'certificateNum', ellipsis: true, tooltip: true },
  { title: '所获奖项', dataIndex: 'awardList', ellipsis: true, tooltip: true },
  { title: '项目业绩阶段', dataIndex: 'projectAchievementScopeList', ellipsis: true, tooltip: true },
  { title: '项目业绩行业', dataIndex: 'projectAchievementIndustryList', ellipsis: true, tooltip: true },
  { title: '项目业绩规模', dataIndex: 'projectAchievementScaleList', ellipsis: true, tooltip: true },
  {
    title: '学历',
    dataIndex: 'education',
    width: 130,
    ellipsis: true,
    tooltip: true,
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 60,
  },
  {
    title: '良好行为',
    dataIndex: 'goodBehaviorCnt',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.goodBehaviorCnt - +b?.goodBehaviorCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
    width: 110,
  },
  {
    title: '不良行为',
    dataIndex: 'badBehaviorCnt',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.badBehaviorCnt - +b?.badBehaviorCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
    width: 110,
  },
  {
    title: '黑名单记录',
    dataIndex: 'blacklistRecordCnt',
    width: 130,
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a: any, b: any, extra: any) => {
        const sortRes = +a?.blacklistRecordCnt - +b?.blacklistRecordCnt
        return extra.direction === 'ascend' ? sortRes : -sortRes
      },
    },
  },
  // {
  //   title: '最近获取时间',
  //   dataIndex: 'created',
  //   width: 180,
  //   sortable: {
  //     sortDirections: ['ascend', 'descend'],
  //   },
  // },
]
const listData = ref([]) as any
const personNameQuery = ref('')
const enterpriseCodeQuery = ref('')
const columnsData = ref([]) as any

onMounted(() => {
  listData.value = props.list
  if (props.triggerType === 1) {
    personNameQuery.value = props.nameSearchStr
  }
  else if (props.triggerType === 2) {
    enterpriseCodeQuery.value = props.telephoneSearchStr
  }
  else {
    personNameQuery.value = props.nameSearchStr
    enterpriseCodeQuery.value = props.telephoneSearchStr
  }
  // 如果不展示操作列
  if (!props.showActionColumn) {
    columnsData.value = columns
  }
  // 展示操作列
  else {
    columnsData.value = columns.concat({
      title: '操作',
      dataIndex: 'opration',
      slotName: 'opration',
      width: 140,
      fixed: 'right',
    })
  }
})

async function getSimilarBiz() {
  if (props.type === 'business') {
    const { data } = await businessVerifyRepeatBusiness({
      businessType: 'PERSON',
      name: personNameQuery.value,
      code: enterpriseCodeQuery.value,
    })
    if (data.value)
      listData.value = data.value?.list || []
  }
  else {
    const { data } = await customerPersonPageList({
      page: 1,
      size: 20,
      personName: personNameQuery.value,
      phone: enterpriseCodeQuery.value,
    })
    if (data.value)
      listData.value = data.value?.records || [] // 匹配到的重复客户列表
  }
}

/** 领取并创建 */
async function claimAndCreate(record: any) {
  // 客户签约商机，走新增逻辑的时候的重复列表
  if (props.isSign) {
    const { data } = await businessAddSignBusinessPerson({
      ...record,
      step: 2, // 1需要验证 2直接新增
      createSource: props.createSource,
      businessReceiveSource: 'bigdata_search',
      businessType: 'PERSON',
      customerId: props.customerId,
      customerType: props.signParamObj.customerType,
      signType: props.signType,
    })
    if (data.value) {
      AMessage.success('领取并创建成功')
      getSimilarBiz()
      emit('success')
      emit('pageJump', { businessId: data.value.businessId, signId: data.value.signId })
    }
  }
  else {
    const { data } = await businessReceiveBusiness({
      businessList: [record],
      businessReceiveSource: 'bigdata_search',
      businessType: 'PERSON',
    })
    if (data.value) {
      const { successCnt } = data.value
      // 领取并创建成功
      if (+successCnt === 1) {
        AMessage.success('领取并创建成功')
        getSimilarBiz()
        emit('success')
      }
      else {
        AMessage.error('领取并创建失败')
      }
    }
  }
}

/** 关联客户 */
function associationCustomer(record: any) {
  const customerId = record.id
  emit('associationCustomer', customerId)
}
</script>

<template>
  <div class="mb-24px flex">
    <div class="flex items-center">
      <div class="whitespace-nowrap">
        姓名：
      </div>
      <AInput v-model="personNameQuery" placeholder="请输入" class="w-240px" allow-clear />
    </div>
    <div class="m-l-16px flex items-center">
      <div class="whitespace-nowrap">
        手机号：
      </div>
      <AInput v-model="enterpriseCodeQuery" placeholder="请输入" class="w-240px" allow-clear />
    </div>
    <AButton class="ml-12px" type="primary" @click="getSimilarBiz">
      搜索
    </AButton>
  </div>
  <AAlert class="mb-16px border-none">
    <div class="color-#33363A">
      根据输入信息匹配到以下商机，可直接领取；仅展示前20条匹配结果，需要更精准匹配请输入完整姓名或手机号
    </div>
  </AAlert>
  <ATable
    :columns="columnsData"
    :data="listData"
    :pagination="false"
    :scroll="{
      x: 2500,
    }"
  >
    <!-- <template #personName="{ record }">
      <span style="color:#376AF6">{{ record.personName }}</span>
    </template> -->
    <template #opration="{ record }">
      <!-- 商机 -->
      <template v-if="props.type === 'business'">
        <AButton v-if="!+record.followerId" class="px-0 text-sm" type="text" @click="claimAndCreate(record)">
          领取并创建
        </AButton>
        <span v-else class="text-sm color-#adafb0">
          {{ `${record.followerName}已领取` }}
        </span>
      </template>
      <template v-else>
        <AButton type="text" class="px-0 text-sm" @click="associationCustomer(record)">
          关联客户
        </AButton>
      </template>
    </template>
  </ATable>
</template>
