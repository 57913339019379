import bus from '../bus'
import { APP_NAME, childAddGlobalListener, childAddListener, childSendData } from './micro'

declare global {
  interface Window {
    crmEventCenter: any
    microApp: any
  }
}

interface MessageObject {
  /** 消息类型 */
  type: string
  /** 消息体 */
  data: any
  /** 消息来源 */
  source: string
  /** 消息目标 */
  target: string
}

/** 是否微前端环境 */
export const MicroEnv = !!window.microApp // !!isMicro
/** 消息类型枚举 */
enum MESSAGE_TYPES {
  /** 更新信息时 */
  UPDATE_INFO = 'update_info',
  /** 需要执行退出登录时 */
  LOGOUT = 'logout',

  CHANGE_ROUTE = 'change_route',
}

type TYPES = keyof typeof MESSAGE_TYPES
/**
 * 接收来自基座的消息
 */
export function handleMicroData() {
  // arcoEventCenter 是基座添加到window的数据通信对象
  if (MicroEnv) {
    // 监听基座下发的数据变化
    const dataListener = (resData: any) => {
      // eslint-disable-next-line no-console
      console.log('【child crm】 获取的全局数据', resData)
      const { type, data, source } = resData as MessageObject
      // 判断依赖数据是否变化才触发，避免其他全局发送数据变化的触发
      // if (globalType) {
      bus.emit(type, data)
      // }
    }
    // 子应用绑定全局监听函数
    childAddGlobalListener(dataListener, true)
    childAddListener(dataListener, true)
  }
}

/**
 * 租户中心 向基座发送消息
 * @param type TYPES
 * @param data 消息体
 */
export function sendMicroMessage(type: string, data?: any, cb?: Function) {
  if (MicroEnv) {
    const _data = { type, data, source: APP_NAME }
    childSendData(_data)
  }
  else {
    if (cb)
      cb()
  }
}
