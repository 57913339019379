import type {
  AddBusinessEnterpriseReq,
  AddBusinessPersonReq,
  AddBusinessProjectReq,
  AddOrUpdateBusinessAddrReq,
  AddSignBusinessEnterpriseReq,
  AddSignBusinessPersonReq,
  AddSignBusinessProjectReq,
  AssignBusinessReq,
  BusinessNumReq,
  BusinessSetVisitPlanReq,
  DeleteBusinessAddrReq,
  FenYeChaXunQiYeShangJiGongHai,
  FenYeChaXunQiYeShangJiJianDanShuJu,
  FenYeChaXunRenCaiShangJiGongHai,
  FenYeChaXunRenCaiShangJiJianDanShuJu,
  FenYeChaXunXiangMuShangJiJianDanShuJu,
  GuanLianShangJiRequest,
  InnerRpcBusinessReceiveFlagReq,
  InnerRpcXinZengXiangMuShangJirpcJieKou,
  InnerRpclong,
  MyBusinessPlanReq,
  PageBusinessMixReq,
  PageTargetBusinessReq,
  ReceiveBusinessReq,
  VerifyRepeatBusinessReq,
  WoDeShangJiQuanJuFenLei,
  XiangMuShangJiFenYeChaXunrpcrequest,
  XiangMuShangJiGongHaiChaXun,
  XiangMuShangJiXiuGairpcrequest,
} from './_interfaces.ts'

/** 新增企业商机 */
export function businessAddBusinessEnterprise(data: AddBusinessEnterpriseReq) {
  return usePost<any>('/api/business/addBusinessEnterprise', data)
}

/** 新增人才商机 */
export function businessAddBusinessPerson(data: AddBusinessPersonReq) {
  return usePost<any>('/api/business/addBusinessPerson', data)
}

/** 新增项目商机 */
export function businessAddBusinessProject(data: AddBusinessProjectReq) {
  return usePost<any>('/api/business/addBusinessProject', data)
}

/** 新增编辑商机地址 */
export function businessAddOrUpdateBusinessAddr(
  data: AddOrUpdateBusinessAddrReq,
) {
  return usePost<any>('/api/business/addOrUpdateBusinessAddr', data)
}

/** 新增企业商机(创建/领取/签约) */
export function businessAddSignBusinessEnterprise(
  data: AddSignBusinessEnterpriseReq,
) {
  return usePost<any>('/api/business/addSignBusinessEnterprise', data)
}

/** 新增人才商机(创建/领取/签约) */
export function businessAddSignBusinessPerson(data: AddSignBusinessPersonReq) {
  return usePost<any>('/api/business/addSignBusinessPerson', data)
}

/** 新增项目商机(创建/领取/签约) */
export function businessAddSignBusinessProject(
  data: AddSignBusinessProjectReq,
) {
  return usePost<any>('/api/business/addSignBusinessProject', data)
}

/** 分派商机 */
export function businessAssignBusiness(data: AssignBusinessReq) {
  return usePost<any>('/api/business/assignBusiness', data)
}

/** 我的商机全局筛选分类 */
export function businessBusinessClassification(data: WoDeShangJiQuanJuFenLei) {
  return usePost<any>('/api/business/businessClassification', data)
}

/** 商机各状态数量 */
export function businessBusinessNum(data: BusinessNumReq) {
  return usePost<any>('/api/business/businessNum', data)
}

/** 删除商机地址 */
export function businessDeleteBusinessAddr(data: DeleteBusinessAddrReq) {
  return usePost<any>('/api/business/deleteBusinessAddr', data)
}

/** 修改企业商机 */
export function businessEditBusinessEnterprise(data: AddBusinessEnterpriseReq) {
  return usePost<any>('/api/business/editBusinessEnterprise', data)
}

/** 修改人才商机 */
export function businessEditBusinessPerson(data: AddBusinessPersonReq) {
  return usePost<any>('/api/business/editBusinessPerson', data)
}

/** 修改项目商机 */
export function businessEditBusinessProject(data: AddBusinessProjectReq) {
  return usePost<any>('/api/business/editBusinessProject', data)
}

/** 获取企业商机状态 */
export function businessEnterpriseBusinessReceiveFlag(
  data: InnerRpcBusinessReceiveFlagReq,
) {
  return usePost<any>('/api/business/enterprise/businessReceiveFlag', data)
}

/** 企业商机详情 */
export function businessEnterpriseDetail(data: InnerRpclong) {
  return usePost<any>('/api/business/enterprise/detail', data)
}

/** 获取我的日程企业商机列表 */
export function businessEnterpriseMyPlanList(data: MyBusinessPlanReq) {
  return usePost<any>('/api/business/enterprise/myPlanList', data)
}

/** 企业商机详情 */
export function businessEnterpriseInfo(data: {
  // 商机id
  id?: string
}) {
  return useGet<any>('/api/business/enterpriseInfo', data)
}

/** 导出企业商机 */
export function businessExportBusinessEnterprise(data: {
  // 地址信息
  addr?: string
  // 区
  addrList0area?: string
  // 市
  addrList0city?: string
  // 省
  addrList0province?: string
  // 区县
  area?: string
  // 获奖等级
  awardList?: string[]
  // 商机状态 all:全部 follow:跟进中,sign:已签约,invalid:无效,valid:有效
  businessStatus?: string
  // 市
  city?: string
  // 企业经营阶段
  companyOperationScopeMapList?: string[]
  // 坐标
  coordinate?: string
  // 企业资质
  corpCertList?: string[]
  // 企业资质最大值
  corpCertMax?: string
  // 企业资质最小值
  corpCertMin?: string
  // 创建结束时间
  createEndTime?: string
  // 创建开始时间
  createStartTime?: string
  // 客户id
  customerId?: string
  // 客户类型 ENTERPRISE：企业客户 PERSON：人才客户
  customerType?: string
  // 距离 单位为米
  distance?: string
  // 成立结束时间
  endTime?: string
  // 企业状态
  enterpriseStatus?: string
  // 企业类型
  enterpriseType?: string[]
  // 企业分类列表
  enterpriseTypeList?: string[]
  // 分支机构公司数量最大值
  filialeCompanyMax?: string
  // 分支机构公司数量最小值
  filialeCompanyMin?: string
  flowerIdList?: string[]
  // 跟进人id
  followerId?: string
  // 跟进人id列表
  followerIds?: string[]
  // 跟进结果 HIGH：高意向 MIDDLE：中意向 LOW：低意向 NON：无意向 UNKNOWN：意向不明 UNCONTACTED：未联系上
  followResult?: string
  // 跟进方式 PHONE：电话,WECHAT：微信,VISIT：登门拜访, OFFLINE_MEETING：线下会谈, OTHER:其他
  followType?: string
  // 跟进方式列表 多选 PHONE：电话,WECHAT：微信,VISIT：登门拜访, OFFLINE_MEETING：线下会谈, OTHER:其他
  followTypeList?: string[]
  // 行业类型
  industryType?: string[]
  // 是否是分公司，0不是，1是
  isCompanyFiliale?: number
  // 是否已读
  isRead?: number
  // 缺少的企业资质
  lackCorpCertList?: string[]
  // 资质缺口--即注册人员缺口
  lackQualicaficationList?: string[]
  // 最近跟进时间，数据只传日期
  lastFollowDate?: string
  // 主查询
  name?: string
  // 排序字段 字段参数名
  orderColumn?: string
  // 排序规则 1：倒叙,2：正序
  orderType?: number
  page?: number
  // 业绩规模
  projectAchievementScaleList?: string[]
  // 省份
  province?: string
  // 注册人员证书名称
  qualicaficationList?: string[]
  // 注册人员证书最大值
  qualicaficationMax?: string
  // 注册人员证书最小值
  qualicaficationMin?: string
  // 领取商机时间，数据只传日期
  receiveDate?: string
  // 领取类型 1:已领取，2:未领取
  receiveType?: string
  // 注册资本最大值
  registeredCapitalMax?: string
  // 注册资本最小值
  registeredCapitalMin?: string
  // 注册人员数最大值
  regPersonCntMax?: string
  // 注册人员数最小值
  regPersonCntMin?: string
  // 企业评分最大值
  scoreMax?: number
  // 企业评分最小值
  scoreMin?: number
  size?: number
  // 四库业绩数最大值
  skAchievementCntMax?: string
  // 四库业绩数最小值
  skAchievementCntMin?: string
  // 四库资质数最大值
  skQualificationCntMax?: string
  // 四库资质数最小值
  skQualificationCntMin?: string
  // 商机来源 radar：雷达 create：创建 open_sea：公海 mini_program：小程序 advanced_search：高级搜索 bigdata_search：大数据搜索
  source?: string
  sourceList?: string[]
  // 成立开始时间
  startTime?: string
  // 任务 Id，给任务详情页使用
  taskId?: string
  // 查询类型 1：我的商机 2：公海，9:全部（不限制跟进人）
  type?: number
}) {
  return usePost<any>('/api/business/exportBusinessEnterprise', data)
}

/** 关联工商/人才库/项目库信息 */
export function businessLink(data: GuanLianShangJiRequest) {
  return usePost<any>('/api/business/link', data)
}

/** 企业商机列表(下拉框简单数据) */
export function businessPageEnterpriseSampleList(
  data: FenYeChaXunQiYeShangJiJianDanShuJu,
) {
  return usePost<any>('/api/business/pageEnterprise/sampleList', data)
}

/** 企业商机混合查询（包含雷达） */
export function businessPageEnterpriseBusinessMix(data: PageBusinessMixReq) {
  return usePost<any>('/api/business/pageEnterpriseBusinessMix', data)
}

/** 企业商机列表 */
export function businessPageEnterpriseBusinessPool(
  data: FenYeChaXunQiYeShangJiGongHai,
) {
  return usePost<any>('/api/business/pageEnterpriseBusinessPool', data)
}

/** 企业商机列表-PC */
export function businessPageEnterpriseBusinessPoolPc(
  data: FenYeChaXunQiYeShangJiGongHai,
) {
  return usePost<any>('/api/business/pageEnterpriseBusinessPoolPc', data)
}

/** 人才商机列表(下拉框简单数据) */
export function businessPagePersonSampleList(
  data: FenYeChaXunRenCaiShangJiJianDanShuJu,
) {
  return usePost<any>('/api/business/pagePerson/sampleList', data)
}

/** 人才商机混合查询（包含雷达） */
export function businessPagePersonBusinessMix(data: PageBusinessMixReq) {
  return usePost<any>('/api/business/pagePersonBusinessMix', data)
}

/** 人才商机列表 */
export function businessPagePersonBusinessPool(
  data: FenYeChaXunRenCaiShangJiGongHai,
) {
  return usePost<any>('/api/business/pagePersonBusinessPool', data)
}

/** 项目商机列表(下拉框简单数据) */
export function businessPageProjectSampleList(
  data: FenYeChaXunXiangMuShangJiJianDanShuJu,
) {
  return usePost<any>('/api/business/pageProject/sampleList', data)
}

/** 项目商机混合查询（包含雷达） */
export function businessPageProjectBusinessMix(data: PageBusinessMixReq) {
  return usePost<any>('/api/business/pageProjectBusinessMix', data)
}

/** 项目商机列表 */
export function businessPageProjectBusinessPool(
  data: XiangMuShangJiGongHaiChaXun,
) {
  return usePost<any>('/api/business/pageProjectBusinessPool', data)
}

/** 查询目标商机 */
export function businessPageTargetList(data: PageTargetBusinessReq) {
  return usePost<any>('/api/business/pageTargetList', data)
}

/** 获取人才商机状态 */
export function businessPersonBusinessReceiveFlag(
  data: InnerRpcBusinessReceiveFlagReq,
) {
  return usePost<any>('/api/business/person/businessReceiveFlag', data)
}

/** 人才商机详情 */
export function businessPersonInfo(data: {
  // 商机id
  id?: string
}) {
  return useGet<any>('/api/business/personInfo', data)
}

/** 新增项目商机 */
export function businessProjectAdd(
  data: InnerRpcXinZengXiangMuShangJirpcJieKou,
) {
  return usePost<any>('/api/business/project/add', data)
}

/** 获取项目商机状态 */
export function businessProjectBusinessReceiveFlag(
  data: InnerRpcBusinessReceiveFlagReq,
) {
  return usePost<any>('/api/business/project/businessReceiveFlag', data)
}

/** 项目商机详情 */
export function businessProjectGet(data: {
  // 项目商机ID
  reqprojectId?: string
}) {
  return useGet<any>('/api/business/project/get', data)
}

/** 修改项目商机 */
export function businessProjectModified(data: XiangMuShangJiXiuGairpcrequest) {
  return usePost<any>('/api/business/project/modified', data)
}

/** 分页查询项目商机 */
export function businessProjectPageList(
  data: XiangMuShangJiFenYeChaXunrpcrequest,
) {
  return usePost<any>('/api/business/project/pageList', data)
}

/** 项目商机详情 */
export function businessProjectInfo(data: {
  // 商机id
  id?: string
}) {
  return useGet<any>('/api/business/projectInfo', data)
}

/** 领取商机 */
export function businessReceiveBusiness(data: ReceiveBusinessReq) {
  return usePost<any>('/api/business/receiveBusiness', data)
}

/** 添加商机拜访计划 */
export function businessSetVisitPlan(data: BusinessSetVisitPlanReq) {
  return useGet<any>('/api/business/setVisitPlan', data)
}

/** 校验重复商机 */
export function businessVerifyRepeatBusiness(data: VerifyRepeatBusinessReq) {
  return usePost<any>('/api/business/verifyRepeatBusiness', data)
}
