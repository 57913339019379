<!-- 批量导入弹窗 -->
<script setup lang="ts">
import uploadIcon from '~/assets/upload.png'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 业务类型 1:企业商机 2:人才商机 3:项目商机 4:企业客户 5:个人客户 */
  taskType: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['update:visible', 'success'])
const baseUrl = import.meta.env.VITE_API_URL
const uploadUrl = `${baseUrl}/api/importTask/importExcel` // 文件导入地址
const satoken = localStorage.getItem('token') as string

const _visible = ref(false)
const uploadRef = ref()
const fileList = ref([])
watchEffect(() => {
  _visible.value = props.visible
})

function beforeUpload(file: any) {
  const size = file.size / 1024 / 1024 // 单位 M
  const max = 5
  if (size > max) {
    AMessage.error(`文件大小不能超过${max}M`)
    return false
  }
  else {
    return true
  }
}

function uploadSuccess(res: any) {
  AMessage.success('文件导入中,可在导入列表中查看导入任务')
  handleCancel()
}
function fileChange(val: any) {
  fileList.value = val
}

/** 下载的导入模板 */
function downloadTemplate() {
  const env = import.meta.env
  let url = `https://${env.VITE_OSS_BUCKET}.${env.VITE_OSS_REGION}.aliyuncs.com/crmFile/`
  switch (props.taskType) {
    case 1:
      url += '企业商机模板.xlsx'
      break
    case 2:
      url += '人才商机模板.xlsx'
      break
    case 3:
      url += '项目商机模板.xlsx'
      break
    case 4:
      url += '企业客户模板.xlsx'
      break
    case 5:
      url += '个人客户模板.xlsx'
  }
  window.open(url)
}
async function handleOk() {
  uploadRef.value.submit()
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    width="588px"
    :visible="_visible"
    :mask-closable="false"
    title="批量导入"
    title-align="start"
    ok-text="导入"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="mb-24px flex items-center">
      <div class="mr-16px w-84px whitespace-nowrap text-right text-sm color-#333">
        导入模板
      </div>
      <AButton type="primary" @click="downloadTemplate">
        <template #icon>
          <icon-download />
        </template>
        下载导入模板
      </AButton>
    </div>
    <div class="mb-24px flex items-start">
      <div class="mr-16px w-84px whitespace-nowrap text-right text-sm color-#333">
        上传导入文件
      </div>
      <AUpload
        ref="uploadRef"
        accept=".xlsx,.xls,.csv"
        :auto-upload="false"
        draggable
        :limit="1"
        :action="uploadUrl"
        :headers="{ satoken }"
        :data="{ taskType: String(props.taskType) }"
        @before-upload="beforeUpload"
        @success="uploadSuccess"
        @change="fileChange"
      >
        <template #upload-button>
          <div
            style="
              background-color: #F5F8FF;
              color: #989B9E;
              border: 1px dashed #376AF6;
              height: 180px;
              padding-top:50px;
              width: 400px;
              border-radius: 2;
              text-align: center;"
          >
            <div>
              <div>
                <AImage :src="uploadIcon" width="24" />
              </div>
              <span class="text-sm color-#33363A">把文件拖拽到此或</span>
              <span class="text-sm color-#3370FF"> 点击上传</span>

              <div class="mt-4px px-12px text-xs color-#989B9E">
                支持上传后缀名为：.xls .xlsx 的表格文件，每次导入不超过2000条，文件大小不超过5M
              </div>
            </div>
          </div>
        </template>
      </AUpload>
    </div>
    <template #footer>
      <div>
        <AButton @click="handleCancel">
          取消
        </AButton>
        <AButton type="primary" class="ml-12px" :disabled="!fileList.length" @click="handleOk()">
          导入
        </AButton>
      </div>
    </template>
  </AModal>
</template>
