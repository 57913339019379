import type { IMenuRes, IMenuRoute } from '~/api/_user'
import { fetchMenuList } from '~/api/_user'
import { cloneDeep } from '~/utils'

export const useMenuStore = defineStore('menu', () => {
  const visible = ref(true)

  const isFromServer = ref(true)

  const config = ref<IMenuRes | null>({ menuRoute: [], permission: [] })
  async function fetchConfig() {
    if (config.value?.menuRoute?.length || !isFromServer.value)
      return
    const { data } = await fetchMenuList()
    config.value = data.value
  }

  const local: IMenuRoute[] = [{
    meta: { locale: 'CRM系统' },
    children: [
      {
        meta: { locale: '首页' },
        children: [
          { name: 'workbenche', meta: { locale: '工作台' } },
          { name: 'dashboard', meta: { locale: '仪表盘' } },
        ],
      },
      // { name: 'radar', meta: { locale: '商机雷达' } },
      { name: 'business-search', meta: { locale: '商机检索', hideChildrenInMenu: true } },
      {
        meta: { locale: '企业池' },
        children: [
          { name: 'enterprise-my-list', meta: { locale: '我的商机' } },
          { name: 'enterprise-public-list', meta: { locale: '企业商机管理' } },
        ],
      },
      {
        meta: { locale: '人才池' },
        children: [
          { name: 'talent-my-list', meta: { locale: '我的商机' } },
          { name: 'talent-public-list', meta: { locale: '人才商机管理' } },
        ],
      },
      {
        meta: { locale: '项目池' },
        children: [
          { name: 'project-my-list', meta: { locale: '我的商机' } },
          { name: 'project-public-list', meta: { locale: '项目商机管理' } },
        ],
      },
      {
        meta: { locale: '客户管理' },
        children: [
          { name: 'customer-my-list', meta: { locale: '我的客户' } },
          { name: 'customer-all-list', meta: { locale: '全部客户' } },
        ],
      },
      {
        meta: { locale: '日程管理' },
        children: [
          { name: 'schedule-my', meta: { locale: '我的日程' } },
          // { name: 'schedule-employee', meta: { locale: '员工日程', hideInMenu: true } },
          { name: 'schedule-settings', meta: { locale: '日程设置' } },
        ],
      },
      {
        name: 'task',
        meta: { locale: '市场活动', hideChildrenInMenu: true },
        children: [
          { name: 'task-followed-detail', meta: { locale: '我跟进的-详情' } },
        ],
      },
      { name: 'settings', meta: { locale: 'CRM设置' } },
      { name: 'rule-set', meta: { locale: '商机分派规则设置' } },
      {
        meta: { locale: '业绩认领' },
        children: [
          { name: 'performance-sy-my', meta: { locale: '我的业绩' } },
          { name: 'performance-sy-all', meta: { locale: '全部业绩' } },
        ],
      },
      {
        meta: { locale: '管理统计' },
        children: [
          { name: 'daily-today-data', meta: { locale: '数据统计' } },
          { name: 'schedule-employee', meta: { locale: '每日工作管理' } },
          { name: 'goal-completion-statistics', meta: { locale: '目标完成统计' } },
          { name: 'business-cloud-tenant-details', meta: { locale: '商云租户明细' } },
          { name: 'daily-work-statistics', meta: { locale: '每日工作管理', hideInMenu: true } },
        ],
      },
      {
        meta: { locale: '规则设置' },
        children: [
          { name: 'schedule-rule', meta: { locale: '商机日程规则' } },
          { name: 'dispatch-rule', meta: { locale: '自动分派规则' } },
          { name: 'marketing-personnel', meta: { locale: '市场人员管理' } },
          { name: 'region-management', meta: { locale: '区域管理' } },
          { name: 'target-settings', meta: { locale: '目标设置' } },
        ],
      },
      { name: 'import-task', meta: { locale: '导入任务', hideInMenu: true } },
    ],
  }]

  const all = computed(() => {
    const res: IMenuRoute[] = []
    const traverse = (item: IMenuRoute) => {
      if (item.children)
        item.children.forEach(traverse)

      if (item.name)
        res.push(item)
    }
    if (isFromServer.value)
      config.value?.menuRoute.forEach(traverse)
    else
      local.forEach(traverse)

    res.sort((a, b) => (a.meta.order || 0) - (b.meta.order || 0))
    return res
  })

  const list = computed(() => {
    let res: IMenuRoute[] = isFromServer.value ? cloneDeep(config.value?.menuRoute) : local
    if (!res?.length)
      return []
    res = res[0].children!
    res.sort((a, b) => (a.meta.order || 0) - (b.meta.order || 0))
    const traverse = (routes: IMenuRoute[], layer: number) => {
      if (!routes)
        return null

      const collector: any = routes.map((element) => {
        if (element.meta?.hideInMenu === true)
          return null

        // leaf node
        if (element.meta?.hideChildrenInMenu || !element.children) {
          element.children = []
          return element
        }

        // route filter hideInMenu true
        element.children = element.children.filter(x => x.meta?.hideInMenu !== true)
        // Associated child node
        const subItem = traverse(element.children, layer + 1)

        if (subItem.length) {
          element.children = subItem
          return element
        }
        // the else logic
        if (layer >= 1) {
          element.children = subItem
          return element
        }

        if (element.meta?.hideInMenu === false)
          return element

        return null
      })
      return collector.filter(Boolean)
    }

    return traverse(res, 0)
  })

  return { visible, isFromServer, config, fetchConfig, all, list }
})
