<!-- 不再跟进弹窗 -->
<script setup lang="ts">
import { businessFollowGiveUp } from '~/api/businessFollow'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 商机id */
  businessId: {
    type: String,
    default: '',
  },
  /** 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机 */
  businessType: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['update:visible', 'success'])
const _visible = ref(false)
const formRef = ref()
const formData = reactive({
  type: 'BACK', // BACK:放回,INVALID:无效
  remark: '', // 备注
})
watchEffect(() => {
  _visible.value = props.visible
})

function handleOk() {
  formRef.value.validate().then(async (err: any) => {
    if (!err) {
      const { data } = await businessFollowGiveUp({
        businessId: props.businessId, // 商机id
        businessType: props.businessType, // 商机类型 ENTERPRISE:企业商机 PERSON:人才商机 PROJECT:项目商机
        flowType: formData.type, // 跟进方式,BACK:放回,INVALID:无效
        noFollowReason: formData.type === 'INVALID' ? formData.remark : '', // 不再跟进理由
      })
      if (data.value) {
        AMessage.success('操作成功')
        emit('success')
        handleCancel()
      }
    }
  })
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    :visible="_visible"
    :mask-closable="false"
    title-align="start"
    ok-text="提交"
    title="不再跟进"
    width="480px"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <AForm ref="formRef" :model="formData" auto-label-width layout="vertical">
        <ARadioGroup v-model="formData.type" field="type" direction="vertical">
          <ARadio value="BACK" class="mb-10px">
            放回 「公海」，后续可由其他同事跟进
          </ARadio>
          <ARadio value="INVALID" class="mb-10px">
            商机无价值，标记为无效
          </ARadio>
        </ARadioGroup>
        <AFormItem v-if="formData.type === 'INVALID'" field="remark" label="具体原因" :rules="[{ required: true, message: '请输入具体原因' }]">
          <ATextarea v-model="formData.remark" :max-length="999" :auto-size="{ minRows: 2, maxRows: 6 }" placeholder="请输入商机无价值的具体原因，比如电话是空号、负责人已更换等" show-word-limit />
        </AFormItem>
      </AForm>
    </div>
  </AModal>
</template>
