import MapLoader from '@amap/amap-jsapi-loader'

async function loadAMap(key = '87275703b7e5a7c419c8dc49bf1c7e93', safeCode = 'b7d733d2de784e30e5796c12d0eb986f', loadParams: any) {
  let maploaded = null as any
  let MapObj = null as any
  window._AMapSecurityConfig = {
    securityJsCode: safeCode,
  }
  const loadFn = async () => {
    if (window.GaodeMap !== undefined) {
      Promise.resolve(window.GaodeMap)
      return
    }
    window.onLoad = function () {
      // 解决打包的时候 AMap 被当成 arcoDesign 的组件，从而去加载对应的样式文件，导致的编译报错
      window.GaodeMap = window.AMap
      Promise.resolve(window.GaodeMap)
    }
    await MapLoader.load({
      key, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Geocoder', 'AMap.Autocomplete', 'AMap.Driving'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((IMap) => {
      MapObj = IMap
      maploaded = new IMap.Map(loadParams.containerId || 'container', loadParams)
    })
  }
  await loadFn()
  return [maploaded, MapObj]
}
export default loadAMap
