<!-- 新增/编辑企业商机弹窗， 新增/编辑企业客户弹窗 ，签约弹窗 -->
<script setup lang='ts'>
import dayjs from 'dayjs'
import RepeatEnterpriseBiz from './RepeatEnterpriseBiz.vue'
import type { PositionModal } from '~/components/gaodeMap/GaodeMap.vue'
import { businessAddBusinessEnterprise, businessAddSignBusinessEnterprise, businessEditBusinessEnterprise, businessEnterpriseInfo, businessVerifyRepeatBusiness } from '~/api/business'
import { customerLabelOptions } from '~/utils/options'
import { signingSave } from '~/api/signing'
import { customerEnterpriseAdd, customerEnterpriseDetail, customerEnterpriseUpdate, customerEnterpriseVerifyRepeatBusiness } from '~/api/customer'
import type { BusinessEnterpriseInfoResp, CustomerEnterpriseDetailResp } from '~/api/_interfaces'
import { numberInputFormatter, numberInputSave, wanyuanToYuan, yuanToWanyuan } from '~/utils/index'
import { validatePhone } from '~/utils/validate'
import { jumpToJoinEnterprises, jumpToProject } from '~/utils/pageJump'
import type { IOption } from '~/utils/options'
import { getEnterpriseStatusOptions, getIndustryOptions } from '~/utils/options2'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  // true 编辑，false 新建
  isEdit: {
    type: Boolean,
    default: false,
  },
  // 页面来源   1:企业商机 2:人才商机 3:项目商机 4:企业客户 5:个人客户
  pageFrom: {
    type: Number,
    default: 0,
  },
  // 表单类型  'business': 新增/编辑企业商机  'customer':新增/编辑企业客户  sign:签约或关联客户
  formType: {
    type: String,
    default: '',
  },
  // 创建来源（商机必传）  myself:我的   open_sea:公海  customer:客户
  createSource: {
    type: String,
    default: '',
  },
  // 签约方式（签约必传，关联客户不传）  JOIN_OFFLINE：线下签约，JOIN_ONLINE：线上签约， ONBOARDED:入职， ONBOARDED_OUT:劳务外包，APPROVAL:立项
  signType: {
    type: String,
    default: '',
  },
  // 商机id(编辑企业商机和商机签约客户必传)
  businessId: {
    type: String,
    default: '',
  },
  // 客户id(编辑客户和客户签约商机必传)
  customerId: {
    type: String,
    default: '',
  },
  // 是否来自于雷达
  isFromRadar: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:visible', 'success'])
const existedListRef = ref(null) as any

const _visible = ref(false)
const tabType = ref(1) as any// tab类型 1已有  2新增
const selectedExistId = ref('') // 选中的已经存在的数据id
const showEnterpriseExtraLine = ref(false)
const showCodeExtraLine = ref(false)
const aMapModalVisible = ref(false) // 地图选点弹窗
const location = ref({} as PositionModal)
const onSaving = ref(false)
const industryType = ref([])// 所属行业

// 企业客户标签列表
const labelArr = ref([])

let dataId = '' // 签约走新增的时候，表单新增成功的数据id
let repeatBizList_enterprise = [] as any[]// 重复商机列表(通过企业名称查出来的)
let repeatBizList_code = [] as any[]// 重复商机列表(通过信用代码查出来的)
let nameRepeatType = '' // 重复类型(通过企业名称查出来的),MAYBE[疑似重复],MUST[一定重复]
let codeRepeatType = '' // 重复类型(通过信用代码查出来的),MAYBE[疑似重复],MUST[一定重复]

// 重复商机列表弹窗
const repeatEnterpriseBizModal = ref({
  visible: false,
  showConfirmBtn: false, // 是否展示底部的‘直接创建按钮’
  list: [] as any[],
  triggerType: 0, // 1点击“企业名称”下面的‘去查看’触发弹窗  2点击“信用代码”下面的‘去查看’触发弹窗  3点击保存按钮触发弹窗
  showActionColumn: true, // 是否展示操作列
  repeatType: '', // 重复类型，MAYBE[疑似重复],MUST[一定重复]
})
const formRef = ref()
const formData = ref<any>({
  enterpriseName: '', // 企业名称
  enterpriseStatus: '', // 企业状态
  creditCode: '', // 信用代码
  telephone: '', // 联系电话
  legalRepresentative: '', // 法定代表人
  registeredCapital: '' as any, // 注册资本
  establishmentTime: '', // 成立时间
  industry: '', // 所属行业
  address: '' as any, // 公司地址
  siKuQualifications: '' as any, // 四库资质
  siKuPerformance: '' as any, // 四库业绩
  registeredPerson: '' as any, // 注册人员
  labelList: [], // 客户标签
})

const industryOptions = ref<IOption[]>([])
const enterpriseStatusOptions = ref<IOption[]>([])
onMounted(async () => {
  const formType = props.formType
  // 获取企业商机详情
  if (formType === 'business' && props.isEdit)
    getBusinessEnterpriseInfo()

  // 获取企业客户详情
  else if (formType === 'customer' && props.isEdit)
    getEnterpriseCustomerDetail()

  industryOptions.value = await getIndustryOptions()
  enterpriseStatusOptions.value = await getEnterpriseStatusOptions()
})
function handleData(val: any, targetKey: string) {
  if (val?.length)
    formData.value[targetKey] = val.map((item: any) => item.join(','))
  else
    formData.value[targetKey] = [] as any
}
watch(() => industryType.value, (val: any) => {
  handleData(val, 'industry')
}, { deep: true })
/** 是否来源于商机页面 */
const isFromBusinessPage = computed(() => {
  const pageFrom = +props.pageFrom
  return pageFrom === 1 || pageFrom === 2 || pageFrom === 3
})
/** 是否来源于客户页面 */
const isFromCustomerPage = computed(() => {
  const pageFrom = +props.pageFrom
  return pageFrom === 4 || pageFrom === 5
})
/** 当前是否是企业商机表单 */
const isBusinessForm = computed(() => {
  const formType = props.formType
  return formType === 'business' || (isFromCustomerPage.value && formType === 'sign' && +tabType.value === 2)
})
/** 当前是否是企业客户表单 */
const isCustomerForm = computed(() => {
  // 新增/编辑企业客户商机 或 商机签约客户的时候新增客户
  return props.formType === 'customer' || (isFromBusinessPage.value && props.formType === 'sign' && +tabType.value === 2)
})
const title = computed(() => {
  let str = ''
  const editText = props.isEdit ? '编辑' : '新增'
  if (props.formType === 'business') {
    str = `${editText}企业商机`
  }
  else if (props.formType === 'customer') {
    str = `${editText}企业客户`
  }
  else if (props.formType === 'sign') {
    if (isFromBusinessPage.value)
      return '关联客户'

    else if (isFromCustomerPage.value)
      return '签约'
  }
  return str
})

// 是否是签约
const isSign = computed(() => {
  return props.formType === 'sign'
})

const signParamObj = computed(() => {
  const pageFrom = +props.pageFrom
  const _tabType = +tabType.value
  // 企业商机签约客户（商机类型是 ENTERPRISE，客户类型是选框中选的值 或 ENTERPRISE(新增的情况))
  if (pageFrom === 1) {
    return {
      businessType: 'ENTERPRISE',
      customerType: _tabType === 1 ? existedListRef?.value?.customerType : 'ENTERPRISE',
    }
  }
  // 项目商机签约客户（商机类型是 PROJECT，客户类型是选框中选的值 或 ENTERPRISE(新增的情况))
  else if (pageFrom === 3) {
    return {
      businessType: 'PROJECT',
      customerType: _tabType === 1 ? existedListRef?.value?.customerType : 'ENTERPRISE',
    }
  }
  // 客户签约企业商机（商机类型是 ENTERPRISE，客户类型取决于页面来源)
  else if (pageFrom === 4 || pageFrom === 5) {
    return {
      businessType: 'ENTERPRISE',
      customerType: pageFrom === 4 ? 'ENTERPRISE' : 'PERSON',
    }
  }
})

/** 处理addrBusinessList 找到默认地址的id */
function getAddrId(params: any) {
  let addrDefault = null
  if (params.businessAddrList) {
    addrDefault = params.businessAddrList.find((e: any) => {
      return e.isDefault && e.addrType === 'OFFICE'
    })
  }
  // console.log('addrDefault---',addrDefault,!addrDefault ? '' : addrDefault.id);

  return !addrDefault ? '' : addrDefault.id
}

/** 获取企业商机详情 */
async function getBusinessEnterpriseInfo() {
  const { data } = await businessEnterpriseInfo({ id: props.businessId })
  if (data.value) {
    const d = data.value as BusinessEnterpriseInfoResp
    formData.value = {
      enterpriseName: d.enterpriseName,
      enterpriseStatus: d.enterpriseStatus,
      creditCode: d.enterpriseCode,
      telephone: d.phone,
      legalRepresentative: d.corporateName,
      registeredCapital: yuanToWanyuan(d.registeredCapital),
      establishmentTime: d.establishmentTime ? dayjs(d.establishmentTime).format('YYYY-MM-DD 00:00:00') : '',
      industry: d.categoryName,
      address: d.addr,
      addrId: getAddrId(d),
      siKuQualifications: numberInputFormatter(d.skQualificationCnt),
      siKuPerformance: numberInputFormatter(d.skAchievementCnt),
      registeredPerson: numberInputFormatter(d.regPersonCnt),
    }
    // const coordinate = d.coordinate ? d.coordinate.split(',') : []
    location.value = {
      lng: d.lng || '',
      lat: d.lat || '',
      address: d.addr,
      country: d.country,
      province: d.province,
      city: d.city,
      area: d.area,
    }
  }
}

/** 获取企业客户详情 */
async function getEnterpriseCustomerDetail() {
  const { data } = await customerEnterpriseDetail({ id: props.customerId })
  if (data.value) {
    const d = data.value as CustomerEnterpriseDetailResp
    formData.value = {
      enterpriseName: d.enterpriseName,
      enterpriseStatus: d.enterpriseStatus,
      creditCode: d.enterpriseCode,
      telephone: d.phone,
      legalRepresentative: d.corporateName,
      registeredCapital: yuanToWanyuan(d.registeredCapital),
      establishmentTime: d.establishmentTime,
      industry: d.categoryName,
      address: d.addr,
      addrId: getAddrId(d),
      siKuQualifications: numberInputFormatter(d.skQualificationCnt),
      siKuPerformance: numberInputFormatter(d.skAchievementCnt),
      registeredPerson: numberInputFormatter(d.regPersonCnt),
      labelList: (d.labels || []).map(item => item.labelName) as any,

    }
    const coordinate = d.coordinate ? d.coordinate.split(',') : []
    location.value = {
      lng: coordinate[0] || '',
      lat: coordinate[1] || '',
      address: d.addr,
      country: d.country,
      province: d.province,
      city: d.city,
      area: d.area,
      location: d.location,
    }
    labelArr.value = d.labels ? d.labels : []
  }
}

watch(tabType, () => {
  selectedExistId.value = ''
})

watchEffect(() => {
  _visible.value = props.visible
})

function mapOk(position: PositionModal) {
  location.value = position
  formData.value.address = position.address
}

/** 获取重复的企业商机 */
async function getSimilarBiz(type: number) {
  const not1 = type === 1 && !formData.value.enterpriseName
  const not2 = type === 2 && !formData.value.creditCode
  if (not1 || not2)
    return

  const { data } = await businessVerifyRepeatBusiness({
    businessType: 'ENTERPRISE',
    name: type === 1 ? formData.value.enterpriseName : '',
    code: type === 2 ? formData.value.creditCode : '',
  })
  if (data.value) {
    const list = data.value?.list || [] // 匹配到的商机列表
    if (type === 1) {
      repeatBizList_enterprise = list
      showEnterpriseExtraLine.value = !!list.length
    }
    else {
      repeatBizList_code = list
      showCodeExtraLine.value = !!list.length
    }
  }
}

/** 获取重复的客户 */
async function getSimilarCustomer(type: number) {
  const params = {
    page: 1,
    size: 20,
    enterpriseName: type === 1 ? formData.value.enterpriseName : '',
    enterpriseCode: type === 2 ? formData.value.creditCode : '',
  }
  if (props.isEdit)
    params.id = props.customerId

  const { data } = await customerEnterpriseVerifyRepeatBusiness(params)
  if (data.value) {
    const list = data.value?.list || [] // 匹配到的重复客户列表
    if (type === 1) {
      repeatBizList_enterprise = list
      nameRepeatType = data.value.repeatType
      showEnterpriseExtraLine.value = !!list.length
    }
    else {
      repeatBizList_code = list
      codeRepeatType = data.value.repeatType
      showCodeExtraLine.value = !!list.length
    }
  }
}

/** 展示匹配商机弹窗 */
function checkSimilarBiz(type: number, showActionColumn = true) {
  repeatEnterpriseBizModal.value.visible = true
  repeatEnterpriseBizModal.value.triggerType = type
  repeatEnterpriseBizModal.value.showActionColumn = showActionColumn
  // 点击企业名称下的去查看
  if (type === 1) {
    // repeatEnterpriseBizModal.value.triggerType = 1
    repeatEnterpriseBizModal.value.list = repeatBizList_enterprise
  }

  // 点击信用代码下的去查看
  else {
    // repeatEnterpriseBizModal.value.triggerType = 2
    repeatEnterpriseBizModal.value.list = repeatBizList_code
  }
}

/**
 * @param needRepeatValidate 是否需要重复校验
 */
function handleOk(needRepeatValidate = true) {
  onSaving.value = true

  try {
    // 选择已有商机进行签约
    if (isSign.value && +tabType.value === 1) {
      signFn()
    }
    // 企业商机
    else if (isBusinessForm.value) {
      businessFormSave(needRepeatValidate)
    }
    // 企业客户
    else if (isCustomerForm.value) {
    // 商机签约客户的时候，如果新建成功，但是绑定失败（存在dataId）, 则直接拿着 dataId 走签约逻辑
      if (+tabType.value === 2 && !needRepeatValidate && dataId)
        signFn()
      // 企业客户新建/编辑
      else
        customerFormSave(needRepeatValidate)
    }
  }
  catch (error) {
    onSaving.value = true
  }
}

/** 签约 */
async function signFn() {
  const _tabType = +tabType.value
  if (_tabType === 1 && !selectedExistId.value) {
    AMessage.error('请选择')
    return
  }
  let businessId = ''
  let customerId = ''
  // 商机签约客户， 则客户 id 使用用户自己选的值或新增的值, 商机 id 使用 props 值
  if (isFromBusinessPage.value) {
    customerId = _tabType === 1 ? selectedExistId.value : dataId
    businessId = props.businessId
  }
  // 客户签约商机， 则商机 id 使用用户自己选的值, 客户 id 使用 props 值
  else {
    businessId = selectedExistId.value
    customerId = props.customerId
  }
  // 商机雷达里的立项
  const isProjectRadar = props.isFromRadar && isFromBusinessPage.value
  const param = isProjectRadar
    ? {
        radarBusinessId: businessId,
        businessSource: 'radar',
        businessType: signParamObj.value?.businessType, // 商机类型
        customerType: signParamObj.value?.customerType, // 客户类型
        customerId,
        signType: props.signType, // 签约类型
      }
    : {
        businessId,
        businessSource: props.createSource === 'open_sea' ? 'open_sea' : '', // 项目公海里的立项需要传 businessSource
        businessType: signParamObj.value?.businessType, // 商机类型
        customerType: signParamObj.value?.customerType, // 客户类型
        customerId,
        signType: props.signType, // 签约类型
      }
  const { data } = await signingSave(param)
  onSaving.value = false
  if (data.value) {
    dataId = ''
    AMessage.success('操作成功')
    emit('success')
    handleCancel()
    pageJump({ businessId: data.value.businessId, signId: data.value.signId })
  }
}

/** 三方跳转 */
function pageJump({ businessId = '', signId = '' }) {
  // 不存在签约方式，说明是关联客户，不需要跳转
  if (!props.signType)
    return
  if (isSign.value) {
    // 如果是立项
    if (+props.pageFrom === 3) {
      jumpToProject({ businessId, signId })
    }
    else {
      // 枚举转化
      const _type = props.signType === 'JOIN_ONLINE' ? 'ONLINE' : props.signType === 'JOIN_OFFLINE' ? 'OFFLINE' : ''
      jumpToJoinEnterprises({ type: _type, businessId, signId })
    }
  }
}

/**
 * 新建/编辑商机表单数据保存
 * @param needRepeatValidate 是否需要重复校验
 */
function businessFormSave(needRepeatValidate: boolean) {
  formRef.value.validate().then(async (err: any) => {
    if (err)
      onSaving.value = false
    if (!err) {
      let res = null as any
      if (isCustomerForm && formData.value.industry === '')
        formData.value.industry = []
      if (isCustomerForm && typeof formData.value.industry === 'string')
        formData.value.industry = [formData.value.industry]
      const param = handleBusinessSaveData()
      // 企业商机创建并领取并签约
      if (isSign.value) {
        res = await businessAddSignBusinessEnterprise({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
          createSource: props.createSource,
          businessType: signParamObj.value?.businessType, // 商机类型
          signType: props.signType, //  签约类型
          customerType: signParamObj.value?.customerType, // 客户类型
          customerId: props.customerId,
        })
      }
      else if (props.isEdit) {
        res = await businessEditBusinessEnterprise({
          ...param,
          id: props.businessId,
          createSource: props.createSource,
        })
      }
      else {
        res = await businessAddBusinessEnterprise({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
          createSource: props.createSource,
        })
      }
      onSaving.value = false
      if (res?.data?.value) {
        const list = res?.data?.value?.list || []
        if (props.isEdit || !list.length || !needRepeatValidate) {
          AMessage.success(props.isEdit ? '保存成功' : '创建成功')
          emit('success')
          handleCancel()
          if (isSign.value)
            pageJump({ businessId: res?.data.value.businessId, signId: res?.data.value.signId })
        }
        else {
          repeatEnterpriseBizModal.value = {
            visible: true,
            triggerType: 3,
            list,
            showConfirmBtn: true,
            showActionColumn: props.formType !== 'customer',
          }
        }
      }
    }
  })
}

/**
 * 新建/编辑企业客户表单数据保存
 * @param needRepeatValidate 是否需要重复校验
 */
function customerFormSave(needRepeatValidate: boolean) {
  formRef.value.validate().then(async (err: any) => {
    if (err)
      onSaving.value = false
    if (!err) {
      let res = null as any
      const param = handleCustomerSaveData()
      if (props.isEdit) {
        res = await customerEnterpriseUpdate({
          ...param,
          id: props.customerId,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
        })
      }
      else {
        res = await customerEnterpriseAdd({
          ...param,
          step: needRepeatValidate ? 1 : 2, // 1需要验证 2直接新增
        })
      }
      onSaving.value = false
      if (res?.data?.value) {
        const list = res?.data?.value?.list || []
        // 编辑企业商机不需要出重复弹窗
        if ((props.isEdit && isBusinessForm.value) || !list.length || !needRepeatValidate) {
          if (isSign.value) {
            dataId = res?.data?.value?.id // 新增成功的id
            signFn()
          }
          else {
            AMessage.success('操作成功')
            emit('success')
            handleCancel()
          }
        }
        else {
          repeatEnterpriseBizModal.value = {
            visible: true,
            triggerType: 3,
            list,
            showConfirmBtn: true,
            // 企业客户的重复弹窗不需要展示操作列，但是企业商机和企业商机签约企业客户的重复弹窗需要展示操作列
            showActionColumn: props.formType !== 'customer',
            repeatType: res?.data?.value?.repeatType,
          }
        }
      }
    }
  })
}

/** 处理企业商机保存数据 */
function handleBusinessSaveData() {
  // const hasCoordinate = !!location.value.lng && !!location.value.lat
  const param = {
    addr: location.value.address || '', // 办公地址
    country: location.value.country || '', // 国家
    province: location.value.province || '', // 省份
    city: location.value.city || '', // 市
    area: location.value.area || '', // 区县
    // coordinate: hasCoordinate ? `${location.value.lng},${location.value.lat}` : '', // 经度，纬度
    lat: location.value.lat || '',
    lng: location.value.lng || '',
    industryType: formData.value.industry, // 行业名称
    corporateName: formData.value.legalRepresentative, // 法人名称
    // createId: 0,
    // createName: '',
    // created: '',
    enterpriseCode: formData.value.creditCode, // 企业信用代码
    enterpriseName: formData.value.enterpriseName, // 企业名称
    enterpriseStatus: formData.value.enterpriseStatus, // 企业状态
    establishmentTime: formData.value.establishmentTime, // 成立时间
    // id: 0,
    // isDelete: 0,
    // licenseId: 0, // 工商信息id
    // modified: '',
    phone: formData.value.telephone, // 电话
    registeredCapital: wanyuanToYuan(formData.value.registeredCapital), // 注册资本
    regPersonCnt: numberInputSave(formData.value.registeredPerson), // 注册人员数
    skAchievementCnt: numberInputSave(formData.value.siKuPerformance), // 四库业绩数
    skQualificationCnt: numberInputSave(formData.value.siKuQualifications), // 四库资质数
    addrId: formData.value.addrId,
    // step: 0, // 步骤 1需要验证 2直接新增
    // thirdId: 0, // 三方id
    // updateId: 0,
    // updateName: '',
  }
  return param
}
/** 处理企业客户保存数据 */
function handleCustomerSaveData() {
  const hasCoordinate = !!location.value.lng && !!location.value.lat
  const labels = formData.value.labelList.map((labelName: string) => {
    let id = ''
    if (labelArr.value?.length) {
      labelArr.value.forEach((x: any) => {
        if (labelName === x.labelName)
          id = x.id
      })
    }
    const params = {
      id,
      labelName,
    }
    return params
  })
  const param = {
    addr: location.value.address || '', // 办公地址
    country: location.value.country || '', // 国家
    province: location.value.province || '', // 省份
    city: location.value.city || '', // 市
    area: location.value.area || '', // 区县
    location: location.value.location || '',
    categoryName: formData.value.industry, // 行业名称
    coordinate: hasCoordinate ? `${location.value.lng},${location.value.lat}` : '', // 经度，纬度
    corporateName: formData.value.legalRepresentative, // 法人名称
    enterpriseCode: formData.value.creditCode, // 企业信用代码
    enterpriseName: formData.value.enterpriseName, // 企业名称
    enterpriseStatus: formData.value.enterpriseStatus, // 企业状态
    establishmentTime: formData.value.establishmentTime, // 成立时间
    // id: 0, // 主键 Id
    // {
    //   id: 0,
    //   labelName: '',
    // },
    // ],
    labels: props.isEdit ? labels : formData.value.labelList, // 客户标签
    phone: formData.value.telephone, // 联系电话
    registeredCapital: wanyuanToYuan(formData.value.registeredCapital), // 注册资本
    regPersonCnt: numberInputSave(formData.value.registeredPerson), // 注册人员数
    skAchievementCnt: numberInputSave(formData.value.siKuPerformance), // 四库业绩数
    skQualificationCnt: numberInputSave(formData.value.siKuQualifications), // 四库资质数
  }
  return param
}

/** 创建并领取成功，两个弹窗全部关闭 */
function receiveSuccess() {
  repeatEnterpriseBizModal.value.visible = false
  emit('success')
  handleCancel()
}
/** 关联客户 */
async function associationCustomer(customerId: string) {
  // 商机雷达里的立项
  const isProjectRadar = props.isFromRadar && isFromBusinessPage.value
  const param = isProjectRadar
    ? {
        radarBusinessId: props.businessId,
        businessSource: 'radar',
        businessType: signParamObj.value?.businessType, // 商机类型
        customerType: signParamObj.value?.customerType, // 客户类型
        customerId,
        signType: props.signType, // 签约类型
      }
    : {
        businessId: props.businessId,
        businessType: signParamObj.value?.businessType, // 商机类型
        customerType: signParamObj.value?.customerType, // 客户类型
        customerId,
        signType: props.signType, // 签约类型
      }
  const { data } = await signingSave(param)
  if (data.value) {
    dataId = ''
    AMessage.success('关联成功')
    emit('success')
    handleCancel()
    pageJump({ businessId: data.value.businessId, signId: data.value.signId })
  }
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    :visible="_visible"
    :mask-closable="false"
    width="735px"
    title-align="start"
    :title="title"
    :ok-loading="onSaving"
    :ok-text="isSign && isFromCustomerPage ? '下一步' : '确定'"
    @ok="handleOk(!props.isEdit || isSign)"
    @cancel="handleCancel"
  >
    <div>
      <!-- ENTERPRISE
      <div>pageFrom: {{ props.pageFrom }}</div>
      <div>formType: {{ props.formType }}</div>
      <div>isFromRadar: {{ props.isFromRadar }}</div>
      <div>signType: {{ props.signType }}</div> -->
      <!-- 签约才需要展示内容切换 -->
      <ARadioGroup v-if="isSign" v-model="tabType" type="button" class="mb-16px" @change="val => tabType = val">
        <ARadio :value="1">
          {{ isFromBusinessPage ? "已有客户" : "已有商机" }}
        </ARadio>
        <ARadio :value="2">
          {{ isFromBusinessPage ? "新增客户" : "新增商机" }}
        </ARadio>
      </ARadioGroup>
      <!-- 选择已有客户 -->
      <template v-if="isSign && tabType === 1">
        <ExistedList
          ref="existedListRef"
          :type="isFromBusinessPage ? 'customer' : 'business'"
          business-type="ENTERPRISE"
          @change="val => selectedExistId = val"
        />
      </template>

      <!-- 新建 -->
      <AForm v-if="(isSign && +tabType === 2) || !isSign" ref="formRef" :model="formData" auto-label-width layout="vertical">
        <ARow :gutter="24">
          <ACol :span="12">
            <AFormItem field="enterpriseName" label="企业名称" :rules="[{ required: true, message: '请输入企业名称' }]" validate-trigger="input">
              <AInput
                v-model="formData.enterpriseName"
                placeholder="请输入"
                :max-length="50"
                @input="showEnterpriseExtraLine = false"
                @change="formData.enterpriseName = formData.enterpriseName.trim()"
                @blur="isBusinessForm ? getSimilarBiz(1) : getSimilarCustomer(1)"
              />
              <template v-if="showEnterpriseExtraLine && formData.enterpriseName" #extra>
                <div>
                  <!-- 企业客户不管是新增还是编辑都要展示重复列表 -->
                  <template v-if="isCustomerForm">
                    {{ nameRepeatType === 'MUST' ? '已存在，' : '可能已存在，' }}
                    <AButton type="text" class="px-0px" @click="checkSimilarBiz(1, false)">
                      去查看
                    </AButton>
                  </template>
                  <!-- 企业商机只有新增才展示重复列表 -->
                  <template v-else-if="isBusinessForm && !props.isEdit ">
                    已匹配到商机，请勿重复新增，
                    <AButton type="text" class="px-0px" @click="checkSimilarBiz(1, true)">
                      去查看
                    </AButton>
                  </template>
                </div>
              </template>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="creditCode" label="信用代码">
              <AInput
                v-model="formData.creditCode"
                placeholder="请输入"
                :max-length="18"
                @input="showCodeExtraLine = false"
                @change="formData.creditCode = formData.creditCode.trim()"
                @blur="isBusinessForm ? getSimilarBiz(2) : getSimilarCustomer(2)"
              />
              <template v-if="showCodeExtraLine && formData.creditCode" #extra>
                <div>
                  <template v-if="isCustomerForm">
                    {{ codeRepeatType === 'MUST' ? '已存在，' : '可能已存在，' }}
                    <AButton type="text" class="px-0px" @click="checkSimilarBiz(2, false)">
                      去查看
                    </AButton>
                  </template>
                  <template v-else-if="isBusinessForm && !props.isEdit ">
                    已匹配到商机，请勿重复新增，
                    <AButton type="text" class="px-0px" @click="checkSimilarBiz(2, true)">
                      去查看
                    </AButton>
                  </template>
                </div>
              </template>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="telephone" label="联系电话" :rules="[{ validator: validatePhone }]">
              <AInput v-model="formData.telephone" placeholder="请输入" @change="formData.telephone = formData.telephone.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="legalRepresentative" label="法定代表人">
              <AInput v-model="formData.legalRepresentative" placeholder="请输入" :max-length="20" @change="formData.legalRepresentative = formData.legalRepresentative.trim()" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="registeredCapital" label="注册资本">
              <AInputNumber v-model="formData.registeredCapital" :min="0" :max="99999999" placeholder="请输入" hide-button :precision="4">
                <template #append>
                  万元
                </template>
              </AInputNumber>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="establishmentTime" label="成立时间">
              <ADatePicker
                v-model="formData.establishmentTime"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="w-full"
                :disabled-date="(current) => !dayjs(current).isBefore(dayjs())"
              />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="industry" label="所属行业">
              <ASelect v-if="isCustomerForm" v-model="formData.industry" placeholder="请选择" allow-search allow-clear>
                <AOption v-for="item in industryOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </AOption>
              </ASelect>
              <IndustryTypeCascader v-else v-model="industryType" :check-strictly="true" :max-tag-count="1" class="oneline-multiple-select" multiple />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="industry" label="企业状态">
              <ASelect v-model="formData.enterpriseStatus" placeholder="请选择" allow-clear>
                <AOption v-for="item in enterpriseStatusOptions" :key="item.value" :value="item.value">
                  {{ item.label }}
                </AOption>
              </ASelect>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="address" label="办公地址">
              <AInput v-model.trim="formData.address" placeholder="请地图上选择地点" allow-clear @click="aMapModalVisible = true" @clear.stop="formData.address = '';location = {} as any" />
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="siKuQualifications" label="四库资质">
              <AInputNumber v-model="formData.siKuQualifications" :min="0" :max="99999999" :precision="0" placeholder="请输入" hide-button>
                <template #append>
                  个
                </template>
              </AInputNumber>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="siKuPerformance" label="四库业绩">
              <AInputNumber v-model="formData.siKuPerformance" :min="0" :max="99999999" :precision="0" placeholder="请输入" hide-button>
                <template #append>
                  个
                </template>
              </AInputNumber>
            </AFormItem>
          </ACol>
          <ACol :span="12">
            <AFormItem field="registeredPerson" label="注册人员">
              <AInputNumber v-model="formData.registeredPerson" :min="0" :max="99999999" :precision="0" placeholder="请输入" hide-button>
                <template #append>
                  个
                </template>
              </AInputNumber>
            </AFormItem>
          </ACol>
          <ACol v-if="isCustomerForm" :span="12">
            <AFormItem field="registeredPerson" label="客户标签">
              <ASelect v-model="formData.labelList" class="w-100" placeholder="请选择" allow-clear multiple>
                <AOption v-for="item in customerLabelOptions()" :key="item.value" :value="item.value">
                  {{ item.label }}
                </AOption>
              </ASelect>
            </AFormItem>
          </ACol>
        </ARow>
      </AForm>
    </div>
  </AModal>
  <!-- 重复商机弹窗 -->
  <AModal
    v-if="repeatEnterpriseBizModal.visible"
    v-model:visible="repeatEnterpriseBizModal.visible"
    :mask-closable="false"
    :title="isBusinessForm ? '选择商机' : '选择客户'"
    title-align="start"
    fullscreen
    :footer="repeatEnterpriseBizModal.triggerType === 3
      && repeatEnterpriseBizModal.repeatType !== 'MUST'
      && nameRepeatType !== 'MUST'
      && codeRepeatType !== 'MUST'"
  >
    <RepeatEnterpriseBiz
      :type="isBusinessForm ? 'business' : 'customer'"
      :create-source="props.createSource"
      :trigger-type="repeatEnterpriseBizModal.triggerType"
      :list="repeatEnterpriseBizModal.list"
      :show-action-column="repeatEnterpriseBizModal.showActionColumn"
      :enterprise-name-search-str="formData.enterpriseName"
      :enterprise-code-search-str="formData.creditCode"
      :is-sign="isSign"
      :sign-param-obj="signParamObj"
      :sign-type="props.signType"
      :customer-id="props.customerId"
      @success="receiveSuccess"
      @page-jump="pageJump"
      @association-customer="associationCustomer"
    />
    <template #footer>
      <div>
        <AButton @click="repeatEnterpriseBizModal.visible = false">
          取消
        </AButton>
        <AButton type="primary" class="ml-12px" @click="handleOk(false)">
          直接创建
        </AButton>
      </div>
    </template>
  </AModal>
  <!-- 地图选点组件 -->
  <GaodeMapModal v-if="aMapModalVisible" v-model:visible="aMapModalVisible" :default-position="location" @ok="mapOk" />
</template>
