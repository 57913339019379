<!-- 新建联系人弹窗 -->
<script setup lang="ts">
import { businessContactAdd } from '~/api/businessContact'
import { emailReg, phoneLandlineReg } from '~/utils/reg'
import { contactTypeOptions, getOptionLabel } from '~/utils/options'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  /** 关联业务id */
  relId: {
    type: String,
    default: '',
  },
  /** 联系人关联类型 1:企业商机 2:人才商机 3:项目商机 4:企业客户 5:个人客户 */
  relType: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits(['update:visible', 'success'])
const _visible = ref(false)

const formRef = ref()
const formData = reactive({
  name: '', // 姓名
  contactType: 1, // 联系方式 ，电话|微信|邮箱
  contactTypeContent: '', // 电话号码
  remark: '', // 备注
})
const contactTypeText = computed(() => {
  return getOptionLabel(contactTypeOptions(), formData.contactType)
})
watchEffect(() => {
  _visible.value = props.visible
})
function contactTypeContentValidator(value: string, cb: Function) {
  if (!value) {
    cb(`请输入${contactTypeText.value}`)
    return
  }
  // 电话号码校验失败
  if (formData.contactType === 1) {
    if (!phoneLandlineReg.test(value))
      cb('请按正确的电话格式输入')
  }
  // 邮箱校验失败
  else if (formData.contactType === 2) {
    if (!emailReg.test(value))
      cb('格式必须为xxxxx@yyy.zz')
  }
}
function handleOk() {
  formRef.value.validate().then(async (err: any) => {
    if (!err) {
      const { data } = await businessContactAdd({
        name: formData.name, // 联系人姓名
        relId: props.relId, // 关联业务id
        relType: props.relType, // 联系人关联类型 1:企业商机 2:人才商机 3:项目商机 4:企业客户 5:个人客户
        remark: formData.remark, // 备注
        type: formData.contactType, // 联系方式 1：电话 2：邮箱 3：微信
        value: formData.contactTypeContent, // 联系号码
      })
      if (data.value) {
        const { id, name, type, value } = data.value
        AMessage.success('添加成功')
        emit('success', { id, name, type, value })
        handleCancel()
      }
    }
  })
}
function handleCancel() {
  emit('update:visible', false)
}
</script>

<template>
  <AModal
    title="添加联系人"
    :visible="_visible"
    :mask-closable="false"
    ok-text="提交"
    title-align="start"
    width="486px"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <AForm ref="formRef" :model="formData" auto-label-width layout="vertical">
      <AFormItem field="name" label="姓名" :rules="[{ required: true, message: '请输入姓名' }]">
        <AInput v-model="formData.name" placeholder="请输入" :max-length="20" />
      </AFormItem>
      <AFormItem field="contactType" label="联系方式" :rules="[{ required: true, message: '请选择' }]">
        <ARadioGroup v-model="formData.contactType" type="button">
          <ARadio v-for="item in contactTypeOptions()" :key="item.value" :value="item.value">
            {{ item.label }}
          </ARadio>
        </ARadioGroup>
      </AFormItem>
      <AFormItem field="contactTypeContent" :label="contactTypeText" :rules="[{ required: true, validator: contactTypeContentValidator }]">
        <AInput v-model="formData.contactTypeContent" placeholder="请输入" />
      </AFormItem>
      <AFormItem field="remark" label="备注">
        <ATextarea v-model="formData.remark" :max-length="999" :auto-size="{ minRows: 2, maxRows: 6 }" show-word-limit />
      </AFormItem>
    </AForm>
  </AModal>
</template>
