<script setup lang="ts">
const batchDistributionModalVisible = ref(false) // 批量分派弹窗
const batchTransferModalVisible = ref(false) // 批量转让弹窗
const batchImportModalVisible = ref(false) // 批量导入弹窗
const addFollowUpModalVisible = ref(false) // 新增跟进记录弹窗
const addContactModalVisible = ref(false) // 新建联系人弹窗
const noLongerFollowUpModalVisible = ref(false) // 不再跟进弹窗
const reFollowUpModalVisible = ref(false) // 重新跟进弹窗

const addEnterpriseModalVisible = ref(false) // 新增企业商机弹窗
const addProjectModalVisible = ref(false) // 新增项目商机弹窗
const addTalentModalVisible = ref(false) // 新增人才商机弹窗
const addCustomModalVisible = ref(false) // 新增企业客户弹窗
const addPersonalCustomModalVisible = ref(false) // 新增个人客户弹窗

const editEnterpriseModalVisible = ref(false) // 编辑企业商机弹窗
const editProjectModalVisible = ref(false) // 编辑项目商机弹窗
const editTalentModalVisible = ref(false) // 编辑人才商机弹窗
const editEnterpriseCustomModalVisible = ref(false) // 编辑企业客户弹窗
const editPersonalCustomModalVisible = ref(false) // 编辑个人客户弹窗

const bizSignEnterpriseCustomModalVisible = ref(false) // 商机签约企业客户弹窗
const bizSignPersonalCustomModalVisible = ref(false) // 商机签约个人客户弹窗

const customSignEnterpriseBizModalVisible = ref(false) // 客户签约企业商机弹窗
const customSignProjectBizModalVisible = ref(false) // 客户签约项目商机弹窗
const customSignTalentlBizModalVisible = ref(false) // 客户签约人才商机弹窗
</script>

<template>
  <div class="flex-1 rounded-2px bg-white">
    <AButtonGroup>
      <AButton type="primary" @click="addFollowUpModalVisible = true">
        新增跟进记录
      </AButton>
      <AButton type="primary" @click="noLongerFollowUpModalVisible = true">
        不再跟进
      </AButton>
      <AButton type="primary" @click="reFollowUpModalVisible = true">
        重新跟进
      </AButton>
    </AButtonGroup>
    <div class="mt-2" />
    <AButtonGroup>
      <AButton type="primary" @click="batchDistributionModalVisible = true">
        批量分派
      </AButton>
      <AButton type="primary" @click="batchTransferModalVisible = true">
        批量转让
      </AButton>
      <AButton type="primary" @click="batchImportModalVisible = true">
        批量导入
      </AButton>
      <AButton type="primary" @click="addContactModalVisible = true">
        新建联系人
      </AButton>
    </AButtonGroup>
    <div class="mt-6" />
    <AButtonGroup>
      <AButton type="primary" @click="addEnterpriseModalVisible = true">
        新增企业商机
      </AButton>
      <AButton type="primary" @click="addProjectModalVisible = true">
        新增项目商机
      </AButton>
      <AButton type="primary" @click="addTalentModalVisible = true">
        新增人才商机
      </AButton>
    </AButtonGroup>
    <div class="mt-2" />
    <AButtonGroup>
      <AButton type="primary" @click="editEnterpriseModalVisible = true">
        编辑企业商机
      </AButton>
      <AButton type="primary" @click="editProjectModalVisible = true">
        编辑项目商机
      </AButton>
      <AButton type="primary" @click="editTalentModalVisible = true">
        编辑人才商机
      </AButton>
    </AButtonGroup>

    <div class="mt-6" />
    <AButtonGroup>
      <AButton type="primary" @click="addCustomModalVisible = true">
        新增企业客户
      </AButton>
      <AButton type="primary" @click="addPersonalCustomModalVisible = true">
        新增个人客户
      </AButton>
    </AButtonGroup>
    <div class="mt-2" />
    <AButtonGroup>
      <AButton type="primary" @click="editEnterpriseCustomModalVisible = true">
        编辑企业客户
      </AButton>
      <AButton type="primary" @click="editPersonalCustomModalVisible = true">
        编辑个人客户
      </AButton>
    </AButtonGroup>

    <div class="mt-6" />
    <AButtonGroup>
      <AButton type="primary" @click="bizSignEnterpriseCustomModalVisible = true">
        商机签约企业客户
      </AButton>
      <AButton type="primary" @click="bizSignPersonalCustomModalVisible = true">
        商机签约个人客户
      </AButton>
    </AButtonGroup>

    <div class="mt-2" />
    <AButtonGroup>
      <AButton type="primary" @click="customSignEnterpriseBizModalVisible = true">
        客户签约企业商机
      </AButton>
      <AButton type="primary" @click="customSignProjectBizModalVisible = true">
        客户签约项目商机
      </AButton>
      <AButton type="primary" @click="customSignTalentlBizModalVisible = true">
        客户签约人才商机
      </AButton>
    </AButtonGroup>

    <div class="mt-4" />
    <div>
      企业状态标签：
      <!-- 存续 -->
      <TagEnterpriseStatus status="existence" />
      <span class="mx-1" />
      <!-- 停业 -->
      <TagEnterpriseStatus status="stop" />
      <span class="mx-1" />
      <!-- 吊销 -->
      <TagEnterpriseStatus status="revoked" />
      <span class="mx-1" />
      <!-- 已歇业 -->
      <TagEnterpriseStatus status="closed" />
      <span class="mx-1" />
      <!-- 清算 -->
      <TagEnterpriseStatus status="liquidation" />
      <span class="mx-1" />
      <!-- 撤销 -->
      <TagEnterpriseStatus status="quash" />
      <span class="mx-1" />
      <!-- 注销 -->
      <TagEnterpriseStatus status="logout" />
      <span class="mx-1" />
      <!-- 迁出 -->
      <TagEnterpriseStatus status="moveout" />
      <span class="mx-1" />
      <!-- 迁入 -->
      <TagEnterpriseStatus status="movein" />
    </div>

    <div class="mt-2" />
    <div>
      任务状态标签：
      <TagTaskStatus status="1" />
      <span class="mx-1" />
      <TagTaskStatus status="2" />
      <span class="mx-1" />
      <TagTaskStatus status="3" />
    </div>

    <!-- 批量分派弹窗 -->
    <ModalBatchDistribution v-model:visible="batchDistributionModalVisible" :business-ids="['111', '222', '333']" business-type="ENTERPRISE" />
    <!-- 批量转让弹窗 -->
    <ModalBatchTransfer v-if="batchTransferModalVisible" v-model:visible="batchTransferModalVisible" :business-ids="['111', '222', '333']" business-type="ENTERPRISE" />
    <!-- 批量导入弹窗 -->
    <ModalBatchImport v-if="batchImportModalVisible" v-model:visible="batchImportModalVisible" :task-type="1" />
    <!-- 新增跟进记录弹窗 -->
    <ModalAddFollowUp v-if="addFollowUpModalVisible" v-model:visible="addFollowUpModalVisible" business-id="29" business-type="ENTERPRISE" />
    <!-- 新建联系人弹窗 -->
    <ModalAddContact v-if="addContactModalVisible" v-model:visible="addContactModalVisible" rel-id="1234566677" :rel-type="1" />
    <!-- 不再跟进弹窗 -->
    <ModalNoLongerFollowUp v-if="noLongerFollowUpModalVisible" v-model:visible="noLongerFollowUpModalVisible" business-id="23242342424" business-type="ENTERPRISE" />
    <!-- 重新跟进弹窗 -->
    <ModalReFollowUp v-if="reFollowUpModalVisible" v-model:visible="reFollowUpModalVisible" business-id="23242342424" business-type="ENTERPRISE" />

    <!-- 新增企业商机弹窗 -->
    <ModalAddEnterprise v-if="addEnterpriseModalVisible" v-model:visible="addEnterpriseModalVisible" create-source="myself" :type="1" />
    <!-- 新增项目商机弹窗 -->
    <ModalAddProject v-if="addProjectModalVisible" v-model:visible="addProjectModalVisible" create-source="myself" :type="1" />
    <!-- 新增人才商机弹窗 -->
    <ModalAddTalent v-if="addTalentModalVisible" v-model:visible="addTalentModalVisible" create-source="myself" :type="1" />
    <!-- 新增企业客户弹窗 -->
    <ModalAddEnterprise v-if="addCustomModalVisible" v-model:visible="addCustomModalVisible" create-source="myself" :type="2" />
    <!-- 新增个人客户弹窗 -->
    <ModalAddTalent v-if="addPersonalCustomModalVisible" v-model:visible="addPersonalCustomModalVisible" create-source="myself" :type="2" business-id="123456788" />

    <!-- 编辑企业商机弹窗 -->
    <ModalAddEnterprise v-if="editEnterpriseModalVisible" v-model:visible="editEnterpriseModalVisible" create-source="myself" business-id="29" :type="1" :is-edit="true" />
    <!-- 编辑项目商机弹窗 -->
    <ModalAddProject v-if="editProjectModalVisible" v-model:visible="editProjectModalVisible" create-source="myself" business-id="22" :type="1" :is-edit="true" />
    <!-- 编辑人才商机弹窗 -->
    <ModalAddTalent v-if="editTalentModalVisible" v-model:visible="editTalentModalVisible" create-source="myself" business-id="18" :type="1" :is-edit="true" />
    <!-- 编辑企业客户弹窗 -->
    <ModalAddEnterprise v-if="editEnterpriseCustomModalVisible" v-model:visible="editEnterpriseCustomModalVisible" create-source="myself" business-id="123456788" :type="2" :is-edit="true" />
    <!-- 编辑个人客户弹窗 -->
    <ModalAddTalent v-if="editPersonalCustomModalVisible" v-model:visible="editPersonalCustomModalVisible" create-source="myself" business-id="123456788" :type="2" :is-edit="true" />

    <!-- 商机签约企业客户弹窗 -->
    <ModalAddEnterprise
      v-if="bizSignEnterpriseCustomModalVisible"
      v-model:visible="bizSignEnterpriseCustomModalVisible"
      create-source="myself"
      :type="3"
      business-id="123456788"
    />
    <!-- 商机签约个人客户弹窗 -->
    <ModalAddTalent
      v-if="bizSignPersonalCustomModalVisible"
      v-model:visible="bizSignPersonalCustomModalVisible"
      create-source="myself"
      :type="3"
      business-id="123456788"
    />
    <!-- 客户签约企业商机弹窗 -->
    <ModalAddEnterprise
      v-if="customSignEnterpriseBizModalVisible"
      v-model:visible="customSignEnterpriseBizModalVisible"
      create-source="myself"
      :type="4"
      business-id="123456788"
    />
    <!-- 客户签约项目商机弹窗 -->
    <ModalAddProject
      v-if="customSignProjectBizModalVisible"
      v-model:visible="customSignProjectBizModalVisible"
      create-source="myself"
      :type="2"
      business-id="123456788"
    />
    <!-- 客户签约人才商机弹窗 -->
    <ModalAddTalent
      v-if="customSignTalentlBizModalVisible"
      v-model:visible="customSignTalentlBizModalVisible"
      create-source="myself"
      :type="4"
      business-id="123456788"
    />
  </div>
</template>
